import { Link, useHistory } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import {
  Img,
  Nav,
  NavContact,
  TextNav,
  NavHeaderIpadIphone,
  ImgNav,
  DivBtn,
  BarContainer,
  Bar1,
  Bar2,
  Bar3,
  DivMainSideBar,
  LinkNav,
  Header,
  TitleList,
  SMlogo,
  CopyrightFooter,
  Logo,
  FooterImg,
  Wrapper,
} from './Navbar.style';
import { Col, Container, Row, Spacer } from './UI';
import { images } from '../public';
import { LINKS } from '../consts';

const Navbar = () => {
  const [show, setShow] = useState(true);
  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  //START SCROLL SETUP
  const navbarElement = useRef(null);
  let lastScroll = 0;
  const scrollEvent = () => {
    const currentScroll = window.pageYOffset;
    if (currentScroll <= 0) {
      navbarElement.current?.classList.remove('scrollUp');
      return;
    }
    if (currentScroll > lastScroll && !navbarElement.current?.classList.contains('scrollDown')) {
      navbarElement.current?.classList.remove('scrollUp');
      navbarElement.current?.classList.add('scrollDown');
    } else if (
      currentScroll < lastScroll &&
      navbarElement.current?.classList.contains('scrollDown')
    ) {
      navbarElement.current?.classList.remove('scrollDown');
      navbarElement.current?.classList.add('scrollUp');
    }
    lastScroll = currentScroll;
  };
  useEffect(() => {
    window.addEventListener('scroll', scrollEvent);
    return () => window.removeEventListener('scroll', scrollEvent);
    // eslint-disable-next-line
  }, []);
  //END SCROLL SETUP

  function handleNavbarOpen() {
    setShow(!show);
  }

  useEffect(() => {
    if (!show) {
      document.querySelector('body').style.overflowY = 'hidden';
      navbarElement.current.classList.add('mobile');
    } else {
      document.querySelector('body').style.overflowY = 'auto';
      navbarElement.current.classList.remove('mobile');
    }
  }, [show]);

  return (
    <Wrapper ref={navbarElement}>
      <Container fluid>
        <Row style={{ justifyContent: 'center' }}>
          <Header>
            <Col lg={{ size: 1 }}>
              <Link to="/">
                <Img src={images.logo} alt="logo" />
              </Link>
            </Col>
            <Col lg={{ size: 8, offset: 3 }} style={{ marginLeft: 'auto', marginRight: '2.4rem' }}>
              <Nav to="/startups">
                <TextNav className={pathname === '/startups' ? 'active' : ''}>Startups</TextNav>
              </Nav>
              <Nav to="/community">
                <TextNav className={pathname === '/community' ? 'active' : ''}>Community</TextNav>
              </Nav>
              <Nav to="/resources">
                <TextNav className={pathname === '/resources' ? 'active' : ''}>Resources</TextNav>
              </Nav>
              <Nav to="/about-us">
                <TextNav className={pathname === '/about-us' ? 'active' : ''}>About us</TextNav>
              </Nav>
              <NavContact to="/get-in-touch">Get in touch!</NavContact>
            </Col>
          </Header>

          <NavHeaderIpadIphone>
            <Col md={{ size: 1 }} sm={{ size: 1 }} align={'left'}>
              <Link to="/">
                <ImgNav src={images.logo} alt="logo" visible={!show} />
              </Link>
            </Col>
            <Col md={{ size: 1, offset: 3 }} sm={{ size: 1 }} align={'right'}>
              <DivBtn onClick={() => handleNavbarOpen()} show={show}>
                <BarContainer show={show}>
                  <Bar1 show={!show}></Bar1>
                  <Bar2 show={!show}></Bar2>
                  <Bar3 show={!show}></Bar3>
                </BarContainer>
              </DivBtn>
            </Col>
          </NavHeaderIpadIphone>
          <DivMainSideBar show={show}>
            <Col md={{ size: 3 }} sm={{ size: 1 }}>
              <Spacer lgheight={'9.6rem'} mdheight={'4.8rem'} />
              <LinkNav to="/startups">Startups</LinkNav>
              <LinkNav to="/community">Community</LinkNav>
              <LinkNav to="/resources">Resources</LinkNav>
              <LinkNav to="/about-us">About us</LinkNav>
              <LinkNav exact to="/get-in-touch">
                Get in touch!
              </LinkNav>
            </Col>
            <Spacer lgheight={'2.4rem'} />
            <Col lg={{ size: 2, offset: 0 }} md={{ size: 4 }} sm>
              <TitleList>Social medias</TitleList>
              <Spacer lgheight={'1.6rem'} />
              <a href={LINKS.LINKEDIN} target="_blank" rel="noreferrer">
                <SMlogo src={images.linkedin} alt="logo"></SMlogo>
              </a>
              <a href={LINKS.FACEBOOK} target="_blank" rel="noreferrer">
                <SMlogo src={images.facebook} alt="logo"></SMlogo>
              </a>
              <a href={LINKS.INSTAGRAM} target="_blank" rel="noreferrer">
                <SMlogo src={images.instagram} alt="logo"></SMlogo>
              </a>
            </Col>
            <Spacer lgheight={'21.6rem'} smheight={'8rem'} />
            <Row>
              <Col md={{ size: 4 }} sm>
                <TitleList align={'left'}>POWERED BY</TitleList>
                <Spacer lgheight={'0.8em'} />
                <Logo align={'left'}>
                  <a href={LINKS.COWORK} target="_blank" rel="noreferrer">
                    <FooterImg src={images.logoCowork} alt="logo" />
                  </a>
                  <a href={LINKS.ENOVATIKA} target="_blank" rel="noreferrer">
                    <FooterImg src={images.logoEnovatika} alt="logo" />
                  </a>
                </Logo>
                <Spacer lgheight={'9.6rem'} mdheight={'2.4rem'} smheight={'7rem'} />
                <CopyrightFooter align={'left'} lgBottom={'9.6rem'} mdBottom={'2.4rem'}>
                  ©Copyright 2021 Timisoara Startups. All rights reserved.
                </CopyrightFooter>
              </Col>
            </Row>
          </DivMainSideBar>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default Navbar;
