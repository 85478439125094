import React, { useState, useEffect } from 'react';
import { Container } from './Loading.style';

const useDelay = (value, delay) => {
  const [fade, setFade] = useState(value, delay);
  useEffect(() => {
    const timer = setTimeout(() => setFade(value), delay);
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return fade;
};

export const Loading = ({ isLoading }) => {
  const isVisible = useDelay(isLoading, 300);
  const isActive = useDelay(isLoading, 700);
  return (
    <Container visible={isVisible} active={isActive}>
      <div className="load_wrap">
        <div className="load">
          <div className="line_1"></div>
          <div className="line_2"></div>
          <div className="line_3"></div>
        </div>
      </div>
    </Container>
  );
};
