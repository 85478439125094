import React, { useState, useEffect } from 'react';
import { Background, BtnConnect, TitleBanner } from './BannerStartups.style';
import { Link } from 'react-router-dom';
import { api } from '../../api/api';
import { Cell, Grid, GridWrapper, Spacer } from '../UI';
import { CardStartup } from '..';

export const BannerStartup = ({ pageData }) => {
  const [startups, setStartups] = useState([]);
  const [loading, setLoading] = useState(false);
  const visibleStartups = 3;

  const fetchData = async () => {
    setLoading(true);
    const data = await api.getAllStartups();
    setStartups(prevState => [...prevState, ...data]);
    setLoading(false);
  };

  //SCROLL FUNCTION
  const handleScroll = () => {
    const element = document.querySelector('#section-bg');
    if (element) {
      const top = element.getBoundingClientRect().top;
      const bottom = element.getBoundingClientRect().bottom;
      const width = window.innerWidth || document.documentElement.cl;
      const height = window.innerHeight || document.documentElement.clientHeight;
      if (bottom > 0 && top <= height) {
        let newPos = 0;
        if (width > 1920) newPos = `0px ${-500 + (top - height) * 0.15}px`;
        else if (width > 1024) newPos = `0px ${-200 + (top - height) * 0.15}px`;
        else if (width > 720) newPos = `0px ${(top - height) * 0.05}px`;
        else newPos = `0px ${-150 - (top - height) * 0.15}px`;
        element.style.backgroundPosition = newPos;
      }
    }
  };

  useEffect(() => {
    fetchData();
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      {!loading &&
        startups &&
        startups.filter(
          startup =>
            startup.publish_status &&
            startup.industry === pageData.industry &&
            startup.url !== pageData.url
        ).length > 0 && (
          <Background id="section-bg">
            <GridWrapper>
              <Grid>
                <Cell md={{ colSpan: 12 }}>
                  <Spacer lgheight={'9.6rem'} />
                  <TitleBanner>Other startups in the same category</TitleBanner>
                  <Spacer lgheight={'4.8rem'} />
                </Cell>
                {startups
                  .filter(
                    startup =>
                      startup.publish_status &&
                      startup.industry === pageData.industry &&
                      startup.url !== pageData.url
                  )
                  .map(
                    (startup, index) =>
                      index < visibleStartups && (
                        <Cell key={index}>
                          <CardStartup
                            title={startup.name}
                            link={`/startups/${startup.url}`}
                            image={startup.logo}
                            industry={startup.industry}
                            funding={startup.funding}
                          />
                        </Cell>
                      )
                  )}
                <Cell
                  lg={{ colSpan: 4, offset: 5, rowSpan: 1, offsetRow: 3 }}
                  md={{ colSpan: 4, offset: 5 }}
                >
                  <Spacer lgheight={'2.4rem'} />
                  <Link to="/startups/">
                    <BtnConnect>VIEW ALL STARTUPS</BtnConnect>
                  </Link>
                  <Spacer lgheight={'9.6rem'} />
                </Cell>
              </Grid>
            </GridWrapper>
          </Background>
        )}
    </>
  );
};
