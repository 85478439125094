import { AdminFormResource, LayoutAdmin } from '../../components';
import { useState, useEffect } from 'react';
import { api } from '../../api/api';
import { Helmet } from 'react-helmet-async';
import { Loading, GridWrapper, Grid, Cell, H1, Spacer } from '../../components/UI';
import { STATUSES } from '../../consts';

export const AdminResourceDetailPage = ({ match }) => {
  const [pageData, setPageData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const { id } = match.params;

  async function fetchData() {
    if (id !== 'new-post') {
      const data = await api.getSingleResource(id);
      if (data) {
        setPageData({ ...data, publishStatus: data.publishStatus ? STATUSES[1] : STATUSES[0] });
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [id]);

  return (
    <LayoutAdmin>
      <Helmet>
        <title>Edit stakeholder | Timisoara Startups</title>
      </Helmet>
      <Loading isLoading={loading} />
      <Spacer lgheight={'9.6rem'} mdheight={'4.8rem'} />
      <GridWrapper>
        <Grid>
          <Cell lg={{ colSpan: 6 }} md={{ colSpan: 12 }}>
            <H1 align={'left'}>{pageData ? 'Edit Article' : 'Add new Article'}</H1>
            <Spacer lgheight={'4.8rem'} />
          </Cell>
        </Grid>
      </GridWrapper>
      {!loading && <AdminFormResource data={pageData} />}
    </LayoutAdmin>
  );
};
