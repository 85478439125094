import React, { useState, useEffect } from 'react';
import { Hint } from '..';
import { Container, Label, Text, Error, Wrapper } from './Textarea.style';

const maxCharacters = 600;

export const Textarea = ({ label, name, placeholder, onChange, error, disabled, value }) => {
  const [count, setCount] = useState(0);
  const [err, setErr] = useState('');

  const handleChange = val => {
    if (maxCharacters >= val.length) {
      onChange(name, val);
      setErr('');
    } else setErr('Maximum characters reached!');
  };

  useEffect(() => {
    setCount(value.length);
  }, [value]);

  return (
    <Container>
      {label && <Label>{label}</Label>}
      <Wrapper>
        <Text
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={e => handleChange(e.target.value)}
          disabled={disabled}
        />
      </Wrapper>
      {err === '' && <Hint>{`${count}/${maxCharacters}`}</Hint>}
      {err !== '' && <Error>{err}</Error>}
      {error !== '' && <Error>{error}</Error>}
    </Container>
  );
};
