import * as yup from 'yup';

export const userSchema = yup.object().shape({
  title: yup.string().min(3).required(),
  description: yup.string().min(3).max(600).required(),
  website: yup.string().min(8).required(),
  email: yup.string().email().required(),
  phone: yup.string().min(9).required(),
  city: yup.string().min(3).required(),
  postal_code: yup.string().min(5).required(),
  address: yup.string().min(3).required(),
  industry: yup.string(),
  funding: yup.string().min(3).required(),
  instagram: yup.string(),
  linkedin: yup.string(),
  facebook: yup.string(),
  logo: yup.string().min(10),
});

export const contactSchema = {
  firstName: yup
    .string()
    .matches(/[a-zA-Z]/g, 'Please enter a valid name')
    .required('First name is required')
    .min(2, 'First name must be at least 2 characters.')
    .max(55, 'First name can not be longer than 55 characters'),
  lastName: yup
    .string()
    .matches(/[a-zA-Z]/g, 'Please enter a valid name')
    .required('Last name is required')
    .min(2, 'Last name must be at least 2 characters.')
    .max(55, 'Last name can not be longer than 55 characters'),
  email: yup.string().required('Email is required').email('Please enter a valid email address.'),
  subject: yup.string().required('Subject is required'),
  message: yup
    .string()
    .required('Message is required')
    .min(2, 'Message must be at least 2 characters.'),
};

export const startupSchema = {
  name: yup
    .string()
    .required('Title is required')
    .min(2, 'Title must be at least 2 characters.')
    .max(55, 'Title can not be longer than 55 characters'),
  description: yup
    .string()
    .required('Description is required')
    .min(2, 'Description must be at least 2 characters.')
    .max(600, 'Description can not be longer than 600 characters'),
  website: yup
    .string()
    .required('Website is required')
    .min(2, 'Website must be at least 2 characters.')
    .max(500, 'Website can not be longer than 500 characters'),
  email: yup.string().required('Email is required').email('Please enter a valid email address.'),
  subject: yup.string().required('Subject is required'),
  city: yup.string().required('City is required'),
  postal_code: yup.string().notRequired(),
  address: yup.string().notRequired(),
  funding: yup.string().required('Funding is required'),
  industry: yup.string().required('Industry is required'),
  logo: yup.string().required('Image is required'),
  linkedin: yup.string().notRequired(),
  facebook: yup.string().notRequired(),
  instagram: yup.string().notRequired(),
};

export const membersSchema = {
  first_name: yup
    .string()
    .required('First name is required')
    .min(2, 'First name must be at least 2 characters.')
    .max(55, 'First name can not be longer than 55 characters'),
  last_name: yup
    .string()
    .required('Last name is required')
    .min(2, 'Last name must be at least 2 characters.')
    .max(55, 'Last name can not be longer than 55 characters'),
  position: yup.string().required('Position name is required'),
  profile_img: yup.string().required('Profile image is required'),
};

export const stakeholderSchema = {
  firstName: yup
    .string()
    .required('First name is required')
    .min(2, 'First name must be at least 2 characters.')
    .max(55, 'First name can not be longer than 55 characters'),
  lastName: yup
    .string()
    .required('Last name is required')
    .min(2, 'Last name must be at least 2 characters.')
    .max(55, 'Last name can not be longer than 55 characters'),
  position: yup
    .string()
    .required('Position is required')
    .min(2, 'Position must be at least 2 characters.')
    .max(55, 'Position can not be longer than 55 characters'),
  description: yup
    .string()
    .required('Description is required')
    .min(2, 'Description must be at least 2 characters.')
    .max(600, 'Description can not be longer than 600 characters'),
  email: yup.string().required('Email is required').email('Please enter a valid email address.'),
  phone: yup
    .string()
    .required('Phone is required')
    .min(10, 'Phone must be at least 10 characters.')
    .max(12, 'Phone can not be longer than 12 characters'),
  linkedin: yup.string().notRequired(),
  facebook: yup.string().notRequired(),
  instagram: yup.string().notRequired(),
  avatar: yup.string().required('Image is required'),
  status: yup.bool().notRequired(),
};

export const loginSchema = {
  email: yup.string().required('Email is required').email('Please enter a valid email address.'),
  password: yup
    .string()
    .required('Password is required')
    .min(2, 'Password must be at least 2 characters.')
    .max(55, 'Password can not be longer than 55 characters'),
};

export const postSchema = {
  title: yup
    .string()
    .required('Title is required')
    .min(2, 'Title must be at least 2 characters.')
    .max(100, 'Title can not be longer than 100 characters'),
  author: yup
    .string()
    .required('Author is required')
    .min(2, 'Author must be at least 2 characters.')
    .max(55, 'Author can not be longer than 55 characters'),
  featuredImage: yup.string().required('Image is required'),
  publishStatus: yup.string().required('Publish status is required'),
  category: yup.string().required('Category is required'),
  content: yup.string().required('Content is required'),
};
