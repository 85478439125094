import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' },
});

export const api = {
  login: async ({ email, password }) => {
    const request = instance({
      method: 'POST',
      url: '/users/login',
      data: {
        email: email,
        password: password,
      },
    });
    const data = await request;
    return data;
  },
  register: async userCredentials => {
    const request = instance({
      method: 'POST',
      url: '/users/register',
      data: userCredentials,
    });
    const data = await request;
    return data;
  },

  //### STARTUPS ###
  getAllStartups: async () => {
    try {
      const request = instance({
        method: 'GET',
        url: '/startups',
      });
      const { data } = await request;
      return data;
    } catch (err) {
      return undefined;
    }
  },
  getSingleStartup: async id => {
    try {
      const request = instance({
        method: 'GET',
        url: `/startups/${id}`,
      });
      const { data } = await request;
      return data;
    } catch (err) {
      return undefined;
    }
  },
  addStartup: async body => {
    const request = instance({
      method: 'POST',
      url: `/startups`,
      data: body,
    });
    try {
      const data = await request;
      return data;
    } catch (err) {
      return undefined;
    }
  },
  updateStartup: async body => {
    const request = instance({
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      url: `/startups/${body.url}`,
      data: body,
    });
    try {
      const data = await request;
      return data;
    } catch (err) {
      localStorage.clear();
      return undefined;
    }
  },
  deleteStartup: async id => {
    const request = instance({
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      url: `/startups/${id}`,
    });
    try {
      const data = await request;
      return data;
    } catch (err) {
      localStorage.clear();
      return undefined;
    }
  },

  //### RESOURCES ###
  getResources: async () => {
    const request = instance({
      method: 'GET',
      url: '/posts',
    });
    const { data } = await request;
    return data;
  },
  getSingleResource: async url => {
    const request = instance({
      method: 'GET',
      url: `/posts/${url}`,
    });
    const { data } = await request;
    return data;
  },
  createPost: async post => {
    const request = instance({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      url: `/posts`,
      data: post,
    });
    try {
      const data = await request;
      return data;
    } catch (err) {
      localStorage.clear();
      return undefined;
    }
  },
  updatePost: async post => {
    const request = instance({
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      url: `/posts/${post.url}`,
      data: post,
    });
    try {
      const data = await request;
      return data;
    } catch (err) {
      localStorage.clear();
      return undefined;
    }
  },
  deletePost: async url => {
    const request = instance({
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      url: `/posts/${url}`,
    });
    try {
      const data = await request;
      return data;
    } catch (err) {
      localStorage.clear();
      return undefined;
    }
  },

  //### STAKEHOLDERS ###
  getAllStakeholders: async () => {
    try {
      const request = instance({
        method: 'GET',
        url: '/stakeholders',
      });
      const { data } = await request;
      return data;
    } catch (err) {
      return undefined;
    }
  },
  getSingleStakeholder: async url => {
    try {
      const request = instance({
        method: 'GET',
        url: `/stakeholders/${url}`,
        data: { url },
      });
      const { data } = await request;
      return data;
    } catch (err) {
      return undefined;
    }
  },
  addStakeholder: async body => {
    const request = instance({
      method: 'POST',
      url: '/stakeholders',
      data: body,
    });
    try {
      const data = await request;
      return data;
    } catch (err) {
      return undefined;
    }
  },
  updateStakeholder: async body => {
    const request = instance({
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      url: `/stakeholders/${body.url}`,
      data: body,
    });
    try {
      const { data } = await request;
      return data;
    } catch (err) {
      localStorage.clear();
      return undefined;
    }
  },
  deleteStakeholder: async id => {
    const request = instance({
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      url: `/stakeholders/${id}`,
    });
    try {
      const { data } = await request;
      return data;
    } catch (err) {
      localStorage.clear();
      return undefined;
    }
  },
};
