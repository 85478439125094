import React from 'react';
import { images } from '../../public';
import { Container } from './AboutShape.style';

export const AboutShape = () => {
  return (
    <Container>
      <img className="blur" src={images.graphicAboutBlur} alt="Blur Shape" />
      <img className="orange" src={images.graphicAboutOrange} alt="Orange Shape" />
      <img className="dots" src={images.graphicAboutDots} alt="Dots Shape" />
      <img className="red" src={images.graphicAboutRed} alt="Red Shape" />
      <img className="signs" src={images.graphicAboutSigns} alt="Signs Shape" />
    </Container>
  );
};
