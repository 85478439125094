import React from 'react';
import { Cell, Grid, Spacer } from '../UI';
import {
  CardCategory,
  CardContainer,
  CardImg,
  CardLink,
  CardSubtitle,
  CardText,
  CardTitle,
  ImgContainer,
} from './CardBlogPost.style';

export const LatestCardBlogPost = ({ img, title, subtitle, text, link }) => {
  return (
    <Grid>
      <Cell lg={{ colSpan: 7 }} md={{ colSpan: 12 }}>
        <ImgContainer as="a" href={link} style={{ height: '36.4rem' }}>
          <CardImg src={img} />
        </ImgContainer>
      </Cell>
      <Cell lg={{ colSpan: 5 }} md={{ colSpan: 12 }}>
        <CardContainer>
          <Spacer lgheight="1.6rem" />
          <CardSubtitle>{subtitle}</CardSubtitle>
          <Spacer lgheight="0.8rem" />
          <CardTitle>{title}</CardTitle>
          <Spacer lgheight="1.6rem" />
          <CardCategory>Category one</CardCategory>
          <Spacer lgheight="2.4rem" />
          <CardText>{text}</CardText>
          <Spacer lgheight="1.6rem" />
          <CardLink href={link}>Read more</CardLink>
        </CardContainer>
      </Cell>
    </Grid>
  );
};
