export const colors = {
  jetblack: "#292929",
  silver: "#757575",
  darkbluegrey: "#1d1a3f",
  purpleypink: "#dc31b3",
  tealish: "#29cdcb",
  verylightpink: "#f3f3f3",
  ///------>Starting from here
  primarypink: "#ED3694",
  primaryyellow: "#FAA31B",
  textmain: " #09233C",
  textcaption: "#647894",
  bgdisabled: "#f0f3f7",
  primarypurple: "#772671",
  placeholdertext: "#AFBCCE",
  white: "#ffffff",
  error: "#ED4337",
  lightGreen: "#D4F3E2",
  success: "#2cc470",
};
