import styled from 'styled-components';
import { colors } from '../../consts/colors';
import { ParagraphBody, ParagraphCaption, Hint } from '../common/common.style';

export const DivForm = styled.form`
  @media (max-width: 1199px) {
  }
  @media (max-width: 767px) {
  }
`;

export const ColumnDisplay = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
`;

export const FlexDisplay = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
`;

export const CheckmarkBtn = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 2.5rem;
  width: 2.5rem;
  background-color: ${colors.white};
  border-radius: 0.3rem;
  border: 0.1rem solid ${colors.primaryyellow};
  :after {
    content: '';
    position: absolute;
    display: none;
  }
`;

export const BtnContainer = styled.div`
  display: block;
  position: relative;
  padding-left: 1rem;
  margin-bottom: 1.2rem;
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 2.4rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &CheckmarkBtn input:checked {
    background-color: ${colors.primaryyellow};
    border: 0.1rem solid ${colors.primaryyellow};
    border-radius: 0.2rem;
  }
  :after {
    left: 0.9rem;
    top: 0.5rem;
    width: 0.5rem;
    height: 1rem;
    border: solid white;
    border-radius: 0.2rem;
    border-width: 0 0.2rem 0.2rem 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const SendBtn = styled.button`
  cursor: pointer;
  display: inline-block;
  border: none;
  text-decoration: none;
  width: 100%;
  font-weight: 600;
  background-color: ${colors.primaryyellow};
  padding: 1.6rem 5.75rem;
  height: auto;
  font-size: 2rem;
  font-family: 'Montserrat', sans-serif;
  line-height: 3.2rem;
  text-align: center;
  border-radius: 0.8rem;
  ${({ selectBtn }) => `${selectBtn ? `opacity: 0.8rem` : `opacity: 1rem`};`};

  & a {
    color: ${colors.textmain};
  }
  &:focus {
    outline: none;
    box-shadow: unset;
  }
`;

export const DisplayFlex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
`;

export const FormInp = styled.input`
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${colors.textmain};
  display: block;
  width: 100%;
  justify-content: flex-start;
  margin-right: 3rem;
  padding: 0.7rem;
  border: 0.1rem solid ${colors.placeholdertext};
  border-radius: 0.8rem;
  transition: border 0.3s;
  &:hover {
    border: 1px solid ${colors.primaryyellow};
  }

  &::placeholder {
    color: ${colors.placeholdertext};
  }
  &:not(:placeholder-shown) {
    border: 1px solid ${colors.primaryyellow};
  }
  @media (max-width: 1199px) {
  }
  @media (max-width: 767px) {
  }
`;

export const InputText = styled(ParagraphBody)`
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  display: inline-block;
  margin: auto;
  width: 16rem;
`;

export const TextUploadFile = styled(ParagraphBody)`
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  display: inline-block;
  margin: auto;
`;

export const CustomUpload = styled.label`
  border: 3px dotted ${colors.placeholdertext};
  border-radius: 0.8rem;
  padding: 2.4rem 2.4rem;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  color: ${colors.textmain};
  font-size: 1.6rem;
  line-height: 2.4rem;
  display: inline-block;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  transition: all 0.3s;
  &:hover {
    background-color: rgba(44, 196, 112, 0.1);
  }
  &::placeholder {
    color: ${colors.placeholdertext};
  }
`;
export const UploadFile = styled.label`
  position: absolute;
  width: 100%;
  border: 3px dotted ${colors.placeholdertext};
  border-radius: 0.8rem;
  padding: 2.4rem 2.4rem;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  color: ${colors.textmain};
  font-size: 1.6rem;
  line-height: 2.4rem;
  display: inline-block;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: space-between;
  transition: all 0.3s;
  :hover {
    /* background-color: ${colors.lightGreen}; */
    background-color: rgba(44, 196, 112, 0.1);
  }

  @media (max-width: 1199px) {
    margin-bottom: 5rem;
  }
`;
export const ContainerBtn = styled.div`
  align-items: center;
  margin-top: 3.2rem;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
`;
export const ContainerDragFile = styled.div`
  align-items: center;
  margin-top: 0.8rem;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  margin: auto;
  width: 16rem;
  line-height: 2.4rem;
  display: block;
  margin-top: 0.2rem;
`;
export const BrowseBtn = styled.span`
  padding: 0.7rem 4rem;
  color: ${colors.textmain};
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 2.4rem;
  text-align: center;
  border: 1px solid #09233c;
  border-radius: 0.8rem;
  display: block;
`;

export const UploadPhoto = styled.input`
  display: none;
`;

export const TextArea = styled.textarea`
  font-family: 'Raleway', sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  display: block;
  width: 100%;
  padding: 0.7rem;
  border: 1px solid ${colors.placeholdertext};
  border-radius: 0.8rem;
  resize: none;
  transition: border 0.3s;
  :hover {
    border: 1px solid ${colors.primaryyellow};
  }
  &::placeholder {
    color: ${colors.placeholdertext};
  }
  &:not(:placeholder-shown) {
    border: 1px solid ${colors.primaryyellow};
  }
`;

export const SelectFounding = styled.select`
  width: 100%;
  padding: 0.7rem 4rem 0.7rem 0.7rem;
  border: 0.1rem solid ${colors.placeholdertext};
  border-radius: 0.8rem;
  font-family: 'Raleway', sans-serif;
  color: ${colors.placeholdertext};
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: normal;
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
    no-repeat right ${colors.white};
  -webkit-appearance: none;
  background-position-x: 24.4rem;
  transition: border 0.3s;

  :hover {
    border: 1px solid ${colors.primaryyellow};
  }
`;

export const SelectIndustry = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  background: transparent;
  width: 150px;
  font-size: 16px;
  border: 1px solid #ccc;
  height: 34px;
`;

export const FormInputLabel = styled.label`
  font-family: 'Raleway', sans-serif;
  color: ${colors.textmain};
  display: block;
  font-size: 1.2rem;
  font-weight: 600;
  pointer-events: none;
  margin-bottom: 0.4rem;
  line-height: 2rem;
`;

export const TextForm = styled.textarea`
  width: 100%;
  height: 7rem;
  color: grey;
  font-size: 1.8rem;
  line-height: 1.56rem;
  text-align: left;
  font-weight: normal;
  border: none;
  border-bottom: 1px solid grey;
  background-color: transparent;
  margin-top: 1.5rem;
  transition: border 0.3s;
  &:hover {
    border: 1px solid ${colors.primaryyellow};
  }
`;

export const FormBtn = styled.button`
  background-color: grey;
  border: none;
  color: white;
  border-radius: 2.5rem;
  padding: 1.6rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 0.4rem 0.2rem;
  cursor: pointer;
  width: 100%;
  margin-top: 4rem;
`;

export const SelectDiv = styled.select`
  font-family: 'Raleway', sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${colors.placeholdertext};
  display: block;
  width: 100%;
  letter-spacing: 0;
  justify-content: flex-start;
  margin-right: 3rem;
  padding: 0.7rem;
  border: 0.1rem solid grey;
  border-radius: 0.8rem;
`;

export const Height = styled.div`
  height: 3rem;
`;

export const AddMember = styled(ParagraphBody)`
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.8rem;
  color: ${colors.primaryyellow};
  width: 100%;
`;
export const RemoveMember = styled(ParagraphBody)`
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.8rem;
  color: ${colors.error};
  width: 100%;
`;

export const CheckboxContainer = styled.div`
  cursor: pointer;
  display: inline-block;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  padding: 0.4rem;
  @media (max-width: 1199px) {
  }
  @media (max-width: 767px) {
    vertical-align: center;
  }
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 0.2rem;
`;

export const InputCheckbox = styled.input`
  border: 1px solid ${colors.primaryyellow};
  height: 0px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  width: 0px;
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  border: 1px solid ${colors.textcaption};
  border-radius: 0.3rem;
  background-color: white;
  width: 1.6rem;
  height: 1.6rem;
  transition: border 0.3s;
  :hover {
    border: 1px solid ${colors.primaryyellow};
  }
  ${({ defaultChecked }) =>
    defaultChecked &&
    ` background-color: orange;
      border: 1px solid ${colors.primaryyellow};
      border-radius: 0.3rem;
      transition: all 150ms;
  `};

  ${Icon} {
    visibility: none;
    ${({ defaultChecked }) => defaultChecked && `visibility: visible`};
  }
`;

export const TextCheckmark = styled.label`
  cursor: pointer;
  display: inline-block;
  font-family: 'Raleway', sans-serif;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-left: 0.7rem;
  font-weight: 200;
  @media (max-width: 1199px) {
  }
  @media (max-width: 767px) {
    display: inline-block;
    width: 30rem;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
`;

export const DivFlexCheck = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: baseline;
  align-content: center;
`;

export const TextLabel = styled.label`
  cursor: pointer;
  display: inline-block;
  font-family: 'Raleway', sans-serif;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-left: 0.8rem;
  margin-top: 0.8rem;
  font-weight: 200;
`;

export const TitleCheckBox = styled(ParagraphCaption)`
  color: ${({ color }) => (color ? color : colors.textcaption)};
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2rem;
  font-family: 'Raleway', sans-serif;
  padding-left: 0.4rem;
`;

export const NoteCaption = styled(ParagraphCaption)`
  color: ${colors.textcaption};
  font-family: 'Raleway', sans-serif;
  font-size: 1.2rem;
  line-height: 2rem;
`;
export const NoteDragfile = styled(ParagraphCaption)`
  position: absolute;
  color: ${colors.textcaption};
  font-family: 'Raleway', sans-serif;
  top: 25.6rem;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2rem;
  text-align: center;
`;

export const NotePhone = styled(Hint)`
  color: ${colors.textcaption};
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.6rem;
`;

export const Img = styled.img`
  position: contain;
  height: 3.4rem;
  width: 4rem;
`;
export const ImgTop = styled.img`
  position: contain;
  margin: auto;
  height: 6.4rem;
  width: 6.4rem;
  padding: 0.9rem;
`;

export const ImgLeft = styled.img`
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  height: 2.4rem;
  width: 2.4rem;
`;
export const DropDownContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

export const DropDownHeader = styled.div`
  position: relative;
  width: 100%;
  padding: 0.7rem 4rem 0.7rem 0.7rem;
  border: 0.1rem solid ${colors.placeholdertext};
  background-color: ${colors.white};
  border-radius: 0.8rem;
  font-family: 'Raleway', sans-serif;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  color: ${colors.textmain};
  :hover {
    border: 1px solid ${colors.primaryyellow};
  }
`;

export const DropDownListContainer = styled.div`
  position: absolute;
  z-index: 9999;
  min-width: 100%;
`;

export const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  background: #ffffff;
  width: 100%;
  border-radius: 0.8rem;
  font-family: 'Raleway', sans-serif;
  color: ${colors.textmain};
  font-size: 1.6rem;
  line-height: 2.4rem;
  box-shadow: 0 16px 24px 8px rgba(9, 35, 60, 0.07);
  &:first-child {
    padding-top: 0.8em;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  line-height: 2.4rem;
  font-size: 1.6rem;
  font-weight: 500;
  font-family: 'Raleway' sans-serif;
  padding: 0.4rem 1.6rem;
  text-decoration: none;
  display: block;
  text-align: left;
  color: ${colors.textmain};
  white-space: nowrap;
  &:hover {
    background-color: ${colors.primaryyellow};
  }
`;

export const ListValue = styled.li`
  list-style: none;
  line-height: 2.4rem;
  font-size: 1.6rem;
  font-weight: 500;
  font-family: 'Raleway' sans-serif;
  padding: 0.4rem 1.6rem;
  text-decoration: none;
  display: block;
  text-align: left;
  color: ${colors.textmain};
  :hover {
    background-color: ${colors.primaryyellow};
  }
`;

export const Placeholder = styled.p`
  color: ${colors.placeholdertext};
  font-family: 'Raleway';
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const PlaceholderSearch = styled.p`
  color: ${colors.placeholdertext};
  font-family: 'Raleway';
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding-left: 2.4rem;
`;
export const StyledDiv = styled.div`
  background: white;
  width: 150px;
  font-size: 16px;
  border: 1px solid #ccc;
  height: 34px;
`;

export const Option = styled.option`
  position: absolute;
  background-color: black;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  font-family: 'Raleway' sans-serif;
  color: ${colors.textmain};
  width: 150px;
  font-size: 16px;
  border: 1px solid #ccc;
  height: 34px;
`;

export const CustomOption = styled.div`
  background: white;
  width: 150px;
  font-size: 16px;
  border: 1px solid #ccc;
  height: 34px;
`;

export const ErrorMsg = styled.div`
  color: ${colors.error};
  font-family: 'Raleway' sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.6rem;
  padding-left: 1.2rem;
`;
