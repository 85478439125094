import React, { useState } from 'react';
import { images } from '../../../public';
import { Spacer, Spinner } from '../../UI';
import {
  Container,
  UploadFile,
  Wrapper,
  ImgTop,
  ContainerDragFile,
  BrowseBtn,
  NoteDragfile,
} from './ImageUpload.style';

export const ImageUpload = ({ image, onSetImage, compact, name }) => {
  const [uploading, setUploading] = useState(false);

  const onChangeFile = async event => {
    if (event.target.files[0]) {
      setUploading(true);
      const url = await uploadImage(event.target.files[0]);
      if (url) onSetImage(url);
      setUploading(false);
    }
  };

  const uploadImage = async imageFile => {
    const data = new FormData();
    data.append('file', imageFile);
    data.append('upload_preset', 'td9nyjtt');
    data.append('tags', 'browser_upload');

    const request = await fetch(`https://api.cloudinary.com/v1_1/dvlftmqkj/upload`, {
      method: 'POST',
      body: data,
    });
    const jsonData = await request.json();
    const imageUrl = await jsonData.url;
    return imageUrl;
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async e => {
    e.preventDefault();
    e.stopPropagation();
    setUploading(true);
    const url = await uploadImage(e.dataTransfer.files[0]);
    if (url) onSetImage(url);
    setUploading(false);
  };

  return (
    <Container name={name} compact={compact} onDrop={handleDrop} onDragOver={handleDragOver}>
      <UploadFile url={image} compact={compact}>
        <Wrapper compact={compact}>
          <input type="file" onChange={onChangeFile} style={{ display: 'none' }} />
          {uploading && <Spinner />}
          {!uploading && image === '' && (
            <>
              <ImgTop src={images.upload} />
              <ContainerDragFile compact={compact}>Drag & Drop file here or</ContainerDragFile>
              <Spacer lgheight={'3.2rem'} />
              <BrowseBtn>Browse</BrowseBtn>
            </>
          )}
        </Wrapper>
      </UploadFile>
      <Spacer lgheight={'1.6rem'} />
      <NoteDragfile compact={compact}>
        Please provide a JPG file of your logo, minimum size 760px X 760px, 72dpi.
      </NoteDragfile>
    </Container>
  );
};
