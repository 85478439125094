import React, { useEffect, useState } from "react";
import {
  CardImg,
  TextCard,
  TitleSmall,
  SmLogo,
  SmLogoDisplay,
  Website,
  Subtitle,
  Funding,
  Address,
  Img,
} from "./StartupDetail.style";
import { images } from "../../public";
import { H3, Spacer, Cell, Grid, GridWrapper, H1 } from "../UI";
import { CardFounder } from "..";

export const StartupDetail2 = ({ pageData }) => {
  const [pageContent, setPageContent] = useState(pageData);

  useEffect(() => {
    setPageContent(pageData);
  }, [pageData]);

  return (
    <div>
      {pageContent === undefined ? (
        <div>Getting startup information...</div>
      ) : (
        <>
          <Spacer lgheight={"9.6rem"} />
          <GridWrapper>
            <Grid>
              <Cell lg={{ colSpan: 4, rowSpan: 2 }}>
                <CardImg>
                  <Img
                    src={
                      pageContent.logo !== "null" &&
                      pageContent.logo !== undefined
                        ? pageContent.logo
                        : ""
                    }
                    alt="avatar"
                  ></Img>
                </CardImg>
                <Spacer lgheight={"2.4rem"} />
              </Cell>
              <Cell lg={{ colSpan: 7, offset: 6, offsetRow: 1 }}>
                <H1>{pageContent.name !== "" ? pageContent.name : "-"}</H1>
                <Spacer lgheight={"0.8rem"} />
                <Funding>
                  {pageContent.funding !== "" ? pageContent.funding : "-"}
                </Funding>{" "}
                <Spacer lgheight={"2.4rem"} />
                <Subtitle>
                  {pageContent.industry !== "" ? pageContent.industry : "-"}
                </Subtitle>
                <Spacer lgheight={"4.8rem"} />
                <TitleSmall>Short description</TitleSmall>
                <Spacer lgheight={"1.6rem"} />
                <TextCard>{pageContent.description}</TextCard>
                <Spacer lgheight={"2.4rem"} />
                <TitleSmall>Phisical Address</TitleSmall>
                <Spacer lgheight={"1.6rem"} />
                <Address>
                  {pageContent.address !== "" ? pageContent.address : "-"}
                </Address>
                <Spacer lgheight={"2.4rem"} />
                <TitleSmall>Website</TitleSmall>
                <Spacer lgheight={"1.6rem"} />
                <Website
                  href={pageContent.website}
                  target="_blank"
                  rel="noreferrer"
                >
                  {pageContent.website}
                </Website>
                <Spacer lgheight={"2.4rem"} />
                {(pageContent.linkedin.length !== 0 ||
                  pageContent.facebook.length !== 0 ||
                  pageContent.instagram.length !== 0) && (
                  <>
                    <TitleSmall>Social media</TitleSmall>
                    <Spacer lgheight={"1.6rem"} />
                    <SmLogoDisplay>
                      {pageContent.linkedin !== "" && (
                        <a
                          href={pageContent.linkedin}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <SmLogo src={images.linkedin} alt="logo"></SmLogo>
                        </a>
                      )}
                      {pageContent.facebook !== "" && (
                        <a
                          href={pageContent.facebook}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <SmLogo src={images.facebook} alt="logo"></SmLogo>
                        </a>
                      )}
                      {pageContent.instagram !== "" && (
                        <a
                          href={pageContent.instagram}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <SmLogo src={images.instagram} alt="logo"></SmLogo>
                        </a>
                      )}
                    </SmLogoDisplay>
                  </>
                )}
              </Cell>
              {pageContent.founders.length !== 0 && (
                <>
                  <Cell md={{ colSpan: 12 }}>
                    <Spacer lgheight={"9.6rem"} />
                    <H3 align={"left"}>Team members</H3>
                    <Spacer lgheight={"4.8rem"} />
                  </Cell>
                  {pageContent.founders.map((founder, index) => (
                    <Cell key={index} lg={{ colSpan: 3 }} md={{ colSpan: 4 }}>
                      <CardFounder
                        link={"/community/" + founder._id}
                        image={
                          founder.avatar && founder.avatar !== ""
                            ? founder.avatar
                            : images.userPlaceholder
                        }
                        name={founder.first_name + " " + founder.last_name}
                        position={founder.position}
                      />
                    </Cell>
                  ))}
                </>
              )}
            </Grid>
          </GridWrapper>
          <Spacer lgheight={"9.6rem"} />
        </>
      )}
    </div>
  );
};
