import styled from 'styled-components';
import { colors, ParagraphCaption } from '../../UI';

export const Container = styled.div`
  @media (min-width: 1024px) {
    margin-bottom: 25.6rem;
    ${({ compact }) =>
      compact
        ? `
  margin-bottom: 10rem;
  `
        : `
  margin-bottom: 0;
  `}
  }
`;

export const UploadFile = styled.label`
  position: absolute;
  width: 100%;
  height: 24rem;
  border: 3px dotted ${colors.placeholdertext};
  border-radius: 0.8rem;
  padding: 2.4rem 2.4rem;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  color: ${colors.textmain};
  font-size: 1.6rem;
  line-height: 2.4rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  transition: all 0.3s;
  ${({ url }) =>
    url !== '' &&
    `background-image: url(${url});
  background-clip: content-box;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  `}
  ${({ compact }) =>
    compact &&
    `
  height: 9.2rem;
  padding: 1.2rem 2.4rem;
  `}
  :hover {
    background-color: rgba(44, 196, 112, 0.1);
  }

  @media (max-width: 1024px) {
    position: relative;
    margin-bottom: 2.4rem;
  }
  @media (max-width: 720px) {
    padding: 0.6rem;
    height: 9rem;
    margin-bottom: auto;
  }
`;

export const Wrapper = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  ${({ compact }) =>
    compact &&
    `
  flex-direction: row;
  `}
`;

export const ImgTop = styled.img`
  position: contain;
  margin: auto;
  height: 6.4rem;
  width: 6.4rem;
  padding: 0.9rem;
`;

export const ContainerDragFile = styled.div`
  align-items: center;
  margin-top: 0.8rem;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  margin: auto;
  width: 16rem;
  line-height: 2.4rem;
  display: block;
  margin-top: 0.2rem;
  ${({ compact }) =>
    compact &&
    `width: 22rem;
    margin-top:auto;
  `}
  @media(max-width: 1024px) {
    display: none;
  }
`;

export const BrowseBtn = styled.span`
  margin: auto;
  max-width: 17rem;
  padding: 0.7rem 4rem;
  color: ${colors.textmain};
  font-family: 'Raleway', sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
  text-align: center;
  border: 1px solid #09233c;
  border-radius: 0.8rem;
  display: block;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const NoteDragfile = styled(ParagraphCaption)`
  position: absolute;
  color: ${colors.textcaption};
  font-family: 'Raleway', sans-serif;
  top: 25.6rem;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2rem;
  text-align: center;
  ${({ compact }) => compact && `top: 12rem;`}
  @media (max-width: 1024px) {
    top: 25.6rem;
    ${({ compact }) =>
      compact &&
      `
  top: 12rem;
  `}
  }
  @media (max-width: 720px) {
    top: 10rem;
    ${({ compact }) =>
      compact &&
      `
  top: 12rem;
  `}
  }
`;
