import styled from 'styled-components';
import { colors } from '../Typography';

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  ${({ active }) =>
    active ? `color: ${colors.primaryyellow};` : `color: ${colors.placeholdertext};`};

  @media (max-width: 767px) {
    align-items: flex-start;
  }
`;

export const CheckboxContainer = styled.div`
  cursor: pointer;
  display: inline-block;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  padding: 0.4rem;
  @media (max-width: 1199px) {
  }
  @media (max-width: 767px) {
    vertical-align: center;
  }
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 0.2rem;
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  border: 1px solid ${colors.textcaption};
  border-radius: 0.3rem;
  background-color: white;
  width: 1.6rem;
  height: 1.6rem;
  transition: border 0.3s;
  :hover {
    border: 1px solid ${colors.primaryyellow};
  }
  ${({ active }) =>
    active &&
    ` background-color: orange;
      border: 1px solid ${colors.primaryyellow};
      border-radius: 0.3rem;
      transition: all 150ms;
  `};
  ${Icon} {
    visibility: none;
    ${({ active }) => active && `visibility: visible`};
  }
`;

export const TextCheckmark = styled.label`
  cursor: pointer;
  display: inline-block;
  font-family: 'Raleway', sans-serif;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 1.6rem;
  line-height: 2.4rem;

  font-weight: 200;
  @media (max-width: 1199px) {
  }
  @media (max-width: 767px) {
    display: inline-block;
    width: 30rem;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
`;
