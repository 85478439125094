import React from 'react';
import { images } from '../../public';
import { Container } from './DiscoverShape.style';

export const DiscoverShape = () => {
  return (
    <Container>
      <img className="blur" src={images.graphicBlur} alt="Discover Graphic" />
      <img className="pink" src={images.graphicShapePink} alt="Discover Graphic" />
      <img className="orange" src={images.graphicShapeOrange} alt="Discover Graphic" />
      <img src={images.graphicShapeSigns} alt="Discover Graphic" />
    </Container>
  );
};
