import styled from 'styled-components';
import { colors } from '../../consts/colors';

/*
BEAKPOINTS
mobile - < 768
tablet - 768 >= 1179
small desktop - <= 1920
large desktop - >= 1921
*/
export const Container = styled.div`
  width: 100%;
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  ${({ fluid }) =>
    `${fluid ? `max-width: 100%` : `max-width: 120rem`};
        padding-left: 0rem;
        padding-right: 0rem;
        @media (max-width: 1199px) {
          ${fluid ? `max-width: 100%` : `max-width: 64rem`};
        }
        @media (max-width: 767px) {
          ${fluid ? `max-width: 100%` : `max-width: 33.5rem`};
        }
        @media (min-width: 1200) {
          max-width: 100%;
        }
    `};
`;

export const Row = styled.div`
  width: calc(100% + 2rem);
  margin-left: -1rem;
  margin-right: -1rem;
  display: flex;
  justify-content: flex-start;
  align-content: stretch;
  position: relative;
  flex-wrap: wrap;
  @media (max-width: 1199px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    ${({ reverse }) => reverse && `flex-dircetion: column-reverse`};
  }
  ${({ spaceBetwen }) => spaceBetwen && `justify-content: space-between`};
  ${({ header }) =>
    header &&
    `
    flex-direction: row;
    @media (max-width: 1199px) {
      flex-direction: row;
    }
    @media (max-width: 767px) {
      flex-direction: row;
    }
  `};
  ${({ tablet }) =>
    tablet && `@media (max-width: 1199px) {flex-wrap: nowrap; flex-direction: column}`};
`;

export const Col = styled.div`
  max-width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  ${({ lg }) =>
    lg &&
    `
        position: relative;
        @media (min-width: 1201px) {
            width: ${lg.size * 8 + lg.size * 2}rem;
            ${lg.offset ? `margin-left: ${lg.offset * 8 + lg.offset * 2}rem` : 'margin-left: 0'};
        }
      `}
  ${({ md }) =>
    md &&
    `
        position: relative;
        @media (max-width: 1200px) {
            width: ${md.size * 14 + md.size * 2}rem;
            ${md.offset ? `margin-left: ${md.offset * 14 + md.offset * 2}rem` : 'margin-left: 0'};
        }
      `}
  ${({ sm }) =>
    sm &&
    `
        position: relative;
        @media (max-width: 767px) {
            width: 100%;
            margin: 0;
        }
      `}
  ${({ half }) => half && `width: 50%`};
  ${({ align }) => align && `text-align: ${align}`};
  ${({ thidden }) => thidden && `@media(max-width: 1199px){display: none;}`};
  ${({ mhidden }) => mhidden && `@media(max-width: 767px){display: none;}`};
`;

// typography elements start
//------------------------------>>>>H1
export const H1 = styled.h1`
  font-family: 'Montserrat', sans-serif;
  font-size: 4.2rem;
  font-weight: 700;
  color: ${({ color }) => (color ? color : colors.textmain)};
  line-height: 5rem;

  .breakLine {
    display: inline-block;
    @media (max-width: 767px) {
      display: inline;
    }
  }
  ${({ align }) => align && `text-align: ${align}`};
  @media (max-width: 1199px) {
    font-size: 4.2rem;
    ${({ talign }) => talign && `text-align: ${talign}`};
    ${({ mdTopH1 }) => mdTopH1 && `margin-top: ${mdTopH1}`};
  }
  @media (max-width: 767px) {
    font-size: 4rem;
    margin: auto;
    ${({ malign }) => malign && `text-align: ${malign}`};
    ${({ msTopH1 }) => msTopH1 && `margin-top: ${msTopH1}`};
  }
`;

export const H2 = styled.h2`
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 3.6rem;
  line-height: 4.4rem;
  ${({ lgMarginTop }) => lgMarginTop && `margin-top: ${lgMarginTop}`};
  ${({ lgmarginbottom }) => lgmarginbottom && `margin-bottom: ${lgmarginbottom}`};
  ${({ align }) => align && `text-align: ${align}`};

  @media (max-width: 1199px) {
    font-size: 3.6rem;
    line-height: 1.19;
    ${({ talign }) => talign && `text-align: ${talign}`};
    ${({ mdMarginTop }) => mdMarginTop && `margin-top: ${mdMarginTop}`};
    ${({ mdMarginBottom }) => mdMarginBottom && `margin-bottom: ${mdMarginBottom}`};
  }
  @media (max-width: 767px) {
    text-align: center;
    font-size: 3.6rem;
    line-height: 1.19;

    ${({ malign }) => malign && `text-align: ${malign}`};
    ${({ smMarginTop }) => smMarginTop && `margin-top: ${smMarginTop}`};
    ${({ smMarginBottom }) => smMarginBottom && `margin-bottom: ${smMarginBottom}`};
  }
`;

export const H3 = styled.h3`
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 2.8rem;
  line-height: 4rem;
  ${({ align }) => align && `text-align: ${align}`};
  @media (max-width: 1199px) {
    font-size: 2.8rem;
    ${({ talign }) => talign && `text-align: ${talign}`};
  }
  @media (max-width: 767px) {
    font-size: 2.8rem;
    ${({ malign }) => malign && `text-align: ${malign}`};
  }
`;

export const H4 = styled.h4`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 2rem;
  line-height: 3.2rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
  ${({ lgMarginTop }) => lgMarginTop && `margin-top: ${lgMarginTop}`}
  ${({ align }) => align && `text-align: ${align}`};

  @media (max-width: 1199px) {
    font-size: 2rem;
    line-height: 3.2rem;
    ${({ mdMarginTop }) => mdMarginTop && `margin-top: ${mdMarginTop}`}
    ${({ talign }) => talign && `text-align: ${talign}`};
  }

  @media (max-width: 767px) {
    font-size: 2rem;
    line-height: 3.2rem;
    ${({ smMarginTop }) => smMarginTop && `margin-top: ${smMarginTop}`}
    ${({ malign }) => malign && `text-align: ${malign}`};
  }
`;

export const H5 = styled.h5`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 1.6rem;
  line-height: 2.4rem;

  ${({ align }) => align && `text-align: ${align}`};
  @media (max-width: 1199px) {
    ${({ talign }) => talign && `text-align: ${talign}`};
  }
  @media (max-width: 767px) {
    ${({ malign }) => malign && `text-align: ${malign}`};
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
`;

export const ParagraphBody = styled.p`
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: 'Raleway', sans-serif;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 1.6rem;
  line-height: 2.4rem;
  ${({ lgTop }) => lgTop && `margin-top: ${lgTop}`};
  ${({ lgBottom }) => lgBottom && `margin-bottom: ${lgBottom}`};
  ${({ align }) => align && `text-align: ${align}`};

  @media (max-width: 1199px) {
    ${({ talign }) => talign && `text-align: ${talign}`};
    ${({ mdBottom }) => mdBottom && `margin-bottom: ${mdBottom}`};
    ${({ mdTop }) => mdTop && `margin-top: ${mdTop}`};
  }

  @media (max-width: 767px) {
    ${({ malign }) => malign && `text-align: ${malign}`};
    ${({ msTop }) => msTop && `margin-top: ${msTop}`};
    ${({ msBottom }) => msBottom && `margin-bottom: ${msBottom}`};
  }
`;
export const ParagraphCaption = styled.p`
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: 'Montserrat', sans-serif;
  color: ${({ color }) => (color ? color : colors.textcaption)};
  font-size: 1.6rem;
  line-height: 2.4rem;
  ${({ lgTop }) => lgTop && `margin-top: ${lgTop}`};
  ${({ lgBottom }) => lgBottom && `margin-bottom: ${lgBottom}`};
  ${({ align }) => align && `text-align: ${align}`};

  @media (max-width: 1199px) {
    ${({ talign }) => talign && `text-align: ${talign}`};
    ${({ mdBottom }) => mdBottom && `margin-bottom: ${mdBottom}`};
    ${({ mdTop }) => mdTop && `margin-top: ${mdTop}`};
  }

  @media (max-width: 767px) {
    ${({ malign }) => malign && `text-align: ${malign}`};
    ${({ msTop }) => msTop && `margin-top: ${msTop}`};
    ${({ msBottom }) => msBottom && `margin-bottom: ${msBottom}`};
  }
`;
export const Caption = styled.p`
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 1.2rem;
  line-height: 2rem;
  ${({ lgTop }) => lgTop && `margin-top: ${lgTop}`};
  ${({ lgBottom }) => lgBottom && `margin-bottom: ${lgBottom}`};
  ${({ align }) => align && `text-align: ${align}`};

  @media (max-width: 1199px) {
    ${({ talign }) => talign && `text-align: ${talign}`};
    ${({ mdBottom }) => mdBottom && `margin-bottom: ${mdBottom}`};
    ${({ mdTop }) => mdTop && `margin-top: ${mdTop}`};
  }

  @media (max-width: 767px) {
    ${({ malign }) => malign && `text-align: ${malign}`};
    ${({ msTop }) => msTop && `margin-top: ${msTop}`};
    ${({ msBottom }) => msBottom && `margin-bottom: ${msBottom}`};
  }
`;

export const Hint = styled.p`
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  color: ${({ color }) => (color ? color : colors.textcaption)};
  font-size: 1rem;
  line-height: 1.6rem;
  margin-top: 0.2rem;
  margin-bottom: 0;
  ${({ lgTop }) => lgTop && `margin-top: ${lgTop}`};
  ${({ lgBottom }) => lgBottom && `margin-bottom: ${lgBottom}`};
  ${({ align }) => align && `text-align: ${align}`};

  @media (max-width: 1199px) {
    ${({ talign }) => talign && `text-align: ${talign}`};
    ${({ mdBottom }) => mdBottom && `margin-bottom: ${mdBottom}`};
    ${({ mdTop }) => mdTop && `margin-top: ${mdTop}`};
  }

  @media (max-width: 767px) {
    ${({ malign }) => malign && `text-align: ${malign}`};
    ${({ msTop }) => msTop && `margin-top: ${msTop}`};
    ${({ msBottom }) => msBottom && `margin-bottom: ${msBottom}`};
  }
`;
export const A = styled.a`
  display: inline;
  font-size: 1.8rem;
  line-height: 2.8rem;
`;

export const DisplayFlexDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
`;

export const Spacer = styled.div`
  ${({ lgheight }) => lgheight && `height: ${lgheight}`};
  @media (max-width: 1199px) {
    ${({ mdheight }) => mdheight && `height: ${mdheight}`};
  }

  @media (max-width: 767px) {
    ${({ smheight }) => smheight && `height: ${smheight}`};
  }
`;
