import React, { useState, useEffect } from 'react';
import { AdminFormStartup, NewLayout } from '../components';
import { H1, H3, ParagraphBody, Cell, Spacer, Loading, GridWrapper, Grid } from '../components/UI';
import { Helmet } from 'react-helmet-async';

export const SugestStartUp = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <NewLayout bg="new-startup">
      <Helmet>
        <title>Recommend a startup | Timisoara Startups</title>
      </Helmet>
      <Loading isLoading={loading} />
      <Spacer lgheight={'9.6rem'} mdheight={'4.8rem'} />
      <GridWrapper>
        <Grid>
          <Cell lg={{ colSpan: 6 }} md={{ colSpan: 12 }}>
            <H1 align={'left'}>Recommend a new startup</H1>
            <Spacer lgheight={'2.4rem'} />
            <ParagraphBody>
              This is the place where you can add your own startup or recommend a startup that is
              not already on the platform. We then verify the data you share with us and list the
              startup on the platform.
            </ParagraphBody>
            <Spacer lgheight={'4.8rem'} />
            <H3>Startup information</H3>
          </Cell>
        </Grid>
      </GridWrapper>
      <Spacer lgheight={'2.4rem'} />
      <AdminFormStartup />
    </NewLayout>
  );
};
