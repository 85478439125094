import styled from 'styled-components';
import { SIZE } from '../../../consts';
import { colors, H3, ParagraphBody } from '../../UI';

export const BtnStartups = styled.div`
  cursor: pointer;
  display: inline-block;
  width: 100%;
  min-width: 3.8rem;
  background-color: transparent;
  border: 2px solid ${colors.textmain};
  padding: 1.4rem 0rem;
  height: auto;
  font-size: 2rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  line-height: 3.2rem;
  text-align: center;
  border-radius: 0.8rem;
  & a {
    color: ${colors.textmain};
  }
  @media (max-width: 1199px) {
    padding: 1rem 4.95rem;
    width: 100%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const SubtitleParagraph = styled(ParagraphBody)`
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

//-------

export const CardContainer = styled.div`
  padding: 0rem 1.6rem 2.4rem 1.6rem;
  @media (max-width: 1199px) {
  }
  @media (max-width: 767px) {
  }
`;

export const CardImg = styled.img`
  width: 100%;
  border-radius: 50%;
  display: block;
`;

export const CardStakeholder = styled.div`
  cursor: pointer;
  width: 100%;
  border-radius: 1.6rem;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 2px rgba(9, 35, 60, 0.05);
  position: relative;
  transition: all 0.3s;
  :hover {
    box-shadow: 0 16px 24px 8px rgba(9, 35, 60, 0.07);
  }
  @media (max-width: 1199px) {
    display: inline-block;
    width: 100%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const CardTitle = styled(H3)`
  color: ${colors.textmain};
  font-family: 'Montserrat', sans-serif;
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 4rem;
  text-align: center;
`;
export const ImgContainer = styled.div`
  align-items: center;
  margin: 2.4rem 9.6rem 2.4rem 9.6rem;
  display: inline-block;
  height: 18.8rem;
  width: 18.8rem;
  @media (max-width: 1199px) {
    display: block;
    align-items: center;
    margin-top: 2.4rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2.4rem;
  }
`;

export const SmLogo = styled.img`
  object-fit: contain;
  margin: auto;
  display: inline-block;
`;

export const SmLogoDisplay = styled.div`
  display: flex;
  width: 12rem;
  margin: auto;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const Status = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ status }) => (status ? colors.success : colors.error)};
  padding: 4px;
  border-radius: 4px;
  z-index: 9;
`;

export const Subtitle = styled(ParagraphBody)`
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  color: ${colors.textcaption};
  font-size: 1.6rem;
  line-height: 2.4rem;
  min-height: 5em;
  ${({ align }) => align && `text-align: ${align}`};
  & span {
    &::after {
      content: ' & ';
    }
    &:last-child::after {
      content: '';
    }
  }
  @media (max-width: 1199px) {
    min-height: 5em;
    font-size: 1.6rem;
    ${({ talign }) => talign && `text-align: ${talign}`};
    ${({ mdTopH1 }) => mdTopH1 && `margin-top: ${mdTopH1}`};
  }
  @media (max-width: 767px) {
    margin: auto;
    max-width: 250px;
    ${({ malign }) => malign && `text-align: ${malign}`};
    ${({ msTopH1 }) => msTopH1 && `margin-top: ${msTopH1}`};
  }
`;
export const DisplayFlex = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 2.4rem;
`;

export const Icon = styled.img`
  width: 16rem;
  @media (max-width: ${SIZE.SMALL}) {
    width: 8rem;
  }
`;
