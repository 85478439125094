import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { NewLayout, AboutShape } from "../components";
import { Helmet } from "react-helmet-async";
import {
  Cell,
  Grid,
  GridWrapper,
  H1,
  Loading,
  ParagraphBody,
  Spacer,
  BtnSecondary as Button,
} from "../components/UI";

export const AboutUs = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <NewLayout bg="about">
      <Helmet>
        <title>About Us | Timisoara Startups</title>
      </Helmet>
      <Loading isLoading={loading} />
      <GridWrapper>
        <Grid>
          <Cell md={{ colSpan: 12 }}>
            <Spacer lgheight={"9.6rem"} mdheight={"4.8rem"} />
            <H1>The community aggregator</H1>
          </Cell>
          {/* GRAPHIC */}
          <Cell
            lg={{ colSpan: 7, rowSpan: 2 }}
            md={{ colSpan: 12, rowSpan: 1 }}
          >
            <Spacer lgheight={"9.6rem"} />
            <AboutShape />
            <Spacer lgheight={"9.6rem"} />
          </Cell>
          {/* INFO */}
          <Cell lg={{ colSpan: 5 }} md={{ colSpan: 12 }}>
            <ParagraphBody>
              Timisoara Startups is a community-driven initiative that brings
              together local entrepreneurs and makers that strive to make a
              difference. We welcome startups of all shapes and sizes to join
              our platform, as well as people who would like to help grow the
              local entrepreneurial ecosystem through collaboration and support.
            </ParagraphBody>
            <Spacer lgheight={"2.4rem"} />
            <ParagraphBody>
              This project was founded and made visible to the world by{" "}
              <strong>Cowork Timisoara - The Garden</strong>, in partnership
              with <strong>enovatika</strong> and <strong>DigiTales</strong>.
            </ParagraphBody>
            <Spacer lgheight={"2.4rem"} />
            <ParagraphBody>
              If we help people learn more about our Timisoara born and raised
              makers, we will have reached our goals. We aim to share with you
              their stories, help them connect with other organizations and
              professionals, learn and thrive through collaboration, mentorship
              and guidance.
            </ParagraphBody>
            <Spacer lgheight={"4.8rem"} />
          </Cell>
          <Cell lg={{ colSpan: 3, offset: 8 }} md={{ colSpan: 6 }}>
            <Link to="/get-in-touch">
              <Button>Let's get in touch</Button>
            </Link>
            <Spacer lgheight={"19.2rem"} mdheight={"4.8rem"} />
          </Cell>
        </Grid>
      </GridWrapper>
    </NewLayout>
  );
};
