import React, { useState, useEffect } from "react";
import { Container, Row, Col, H1, Spacer } from "../../common/common.style";
import { Input, Button } from "../../UI";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import {
  UploadFile,
  UploadPhoto,
  ContainerBtn,
  ContainerDragFile,
  BrowseBtn,
  ColumnDisplay,
  ImgTop,
} from "./CreatePost.style";
import { Dropdown } from "../../UI";
import { api } from "../../../api/api";
import { images } from "../../../public";

const mdParser = new MarkdownIt(/* Markdown-it options */);
const CATEGORIES = ["Category 1", "Category 2", "Category 3"];
const STATUSES = ["Draft", "Published"];

export const CreatePost = ({ pageData, isEditable }) => {
  const [form, setForm] = useState({
    id: "",
    author: "",
    title: "",
    content: "",
    featuredImage: "",
    category: CATEGORIES[0],
    publishStatus: false,
  });
  const [errors, setErrors] = useState({
    author: "",
    title: "",
    content: "",
    featuredImage: "",
  });

  useEffect(() => {
    if (isEditable) {
      console.log("Edit mode...");
      setForm({
        id: pageData.data.id,
        author: pageData.data.author,
        title: pageData.data.title,
        content: pageData.data.content,
        publishStatus: !pageData.data.publishStatus ? STATUSES[0] : STATUSES[1],
        category: pageData.data.category,
        featuredImage: pageData.data.featuredImage,
      });
    } else {
      const account = JSON.parse(localStorage.account);
      const { first_name, last_name } = account;
      account !== undefined &&
      first_name !== undefined &&
      last_name !== undefined
        ? setForm((prevState) => ({
            ...prevState,
            author: `${first_name} ${last_name}`,
          }))
        : setForm((prevState) => ({
            ...prevState,
            author: "Timisoara startups",
          }));
    }
  }, [isEditable, pageData]);

  const uploadImage = async (imageFile) => {
    const data = new FormData();
    data.append("file", imageFile);
    data.append("upload_preset", "td9nyjtt");
    data.append("tags", "browser_upload");

    const request = await fetch(
      `https://api.cloudinary.com/v1_1/dvlftmqkj/upload`,
      {
        method: "POST",
        body: data,
      }
    );
    const jsonData = await request.json();
    const imageUrl = await jsonData.url;
    return imageUrl;
  };

  const handleChangeInptuValue = (key, value) => {
    STATUSES.includes(value)
      ? setForm((prevState) => ({
          ...prevState,
          publishStatus: value === STATUSES[0] ? true : false,
        }))
      : setForm((prevState) => ({
          ...prevState,
          [key]: value,
        }));
  };

  function handleEditorChange({ html, text }) {
    console.log("handleEditorChange", html, text);
    setForm((prevState) => ({
      ...prevState,
      content: text,
    }));
  }

  const handleSubmit = async () => {
    for (let key in form) {
      if (form[key] === "" || form[key] === undefined) {
        setErrors((prevState) => ({
          ...prevState,
          [key]: "This field is required",
        }));
        return;
      }
    }
    const imageURL = await uploadImage(form.featuredImage);
    const url = await imageURL;
    const status = form.publishStatus === STATUSES[0];

    const postData = {
      id: form.id,
      author: form.author,
      title: form.title,
      content: form.content,
      featuredImage: url,
      category: form.category,
      publishStatus: status,
    };

    const requestType = isEditable ? "/update" : "";

    const request = await api.addEditResource(postData, requestType);
    request.status === 201 && alert(request.data.message);
    console.log(request);
  };

  return (
    <Container>
      <Row>
        <Col lg={{ size: 12 }} md={{ size: 4 }}>
          <Spacer lgheight="6rem" />
          <H1>{isEditable ? "Edit resource" : "Add new resource"}</H1>
          <Spacer lgheight="4rem" />
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 6 }} md={{ size: 2 }}>
          <Input
            type="text"
            value={form.title}
            label="Title"
            placeholder="Give this resource a title"
            name="title"
            handleOnChange={handleChangeInptuValue}
            error={errors.title !== "" && errors.title}
          />
          <Input
            type="text"
            value={form.author}
            name="author"
            label="Author"
            handleOnChange={handleChangeInptuValue}
          />
          <Dropdown
            values={STATUSES}
            label="Publish status"
            fieldKey="publishStatus"
            handleOnChange={handleChangeInptuValue}
          />
          <Dropdown
            values={CATEGORIES}
            label="Category"
            fieldKey="category"
            handleOnChange={handleChangeInptuValue}
          />
        </Col>
        <Col lg={{ size: 4, offset: 2 }} md={{ size: 2, offset: 1 }}>
          <UploadFile>
            <UploadPhoto
              type="file"
              value=""
              onChange={(e) => {
                e.preventDefault();
                console.log(e.target.files[0]);
                setForm((prevState) => ({
                  ...prevState,
                  featuredImage: e.target.files[0],
                }));
              }}
            />
            <ColumnDisplay>
              <ImgTop src={images.upload}></ImgTop>
              <ContainerDragFile>
                {form.featuredImage === "" ? (
                  "Drag & Drop file here or"
                ) : (
                  <div>
                    <img
                      src={form.featuredImage}
                      style={{ width: "100%" }}
                      alt="featured"
                    />
                    <span>
                      {form && form.featuredImage && form.featuredImage.name
                        ? form.featuredImage.name
                        : ""}
                    </span>
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        setForm((prevState) => ({
                          ...prevState,
                          featuredImage: "",
                        }));
                      }}
                    >
                      ⓧ
                    </span>
                  </div>
                )}
              </ContainerDragFile>
              <ContainerBtn>
                <BrowseBtn>Browse</BrowseBtn>
              </ContainerBtn>
            </ColumnDisplay>
          </UploadFile>
          <Spacer lgheight="4rem" />
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 12 }} md={{ size: 4 }}>
          <MdEditor
            value={form.content}
            style={{ height: "500px" }}
            renderHTML={(text) => mdParser.render(text)}
            onChange={handleEditorChange}
          />
          <Spacer lgheight="4rem" />
          <Button
            handleButtonClicked={handleSubmit}
            label="Post new resource"
          />
        </Col>
      </Row>
    </Container>
  );
};
