import styled from 'styled-components';
import { colors } from '../consts/colors';
import { ParagraphBody } from './common/common.style';
import { NavLink } from 'react-router-dom';
import { SIZE } from '../consts';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 8rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  z-index: 99;
  transition: all 300ms ease-in-out;
  &.scrollDown {
    background-color: ${colors.bgdisabled};
    transform: translate(0, -100%);
  }
  &.scrollUp {
    background-color: ${colors.bgdisabled};
    filter: drop-shadow(0 -10px 20px rgb(9, 35, 60, 0.3));
  }
  &.mobile {
    background-color: transparent;
    height: 100%;
  }
`;

export const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: auto;
  height: 8rem;
  @media (max-width: ${SIZE.MEDIUM}) {
    display: none;
  }

  @media (max-width: ${SIZE.SMALL}) {
    display: none;
  }
`;

export const Header = styled.div`
  max-width: 1200px;
  width: 100%;
  height: 8rem;
  display: flex;
  z-index: 9999;
  @media (max-width: ${SIZE.MEDIUM}) {
    display: none;
  }
  @media (max-width: ${SIZE.SMALL}) {
    display: none;
  }
`;

export const ImgNav = styled.img`
  display: none;
  @media (max-width: ${SIZE.MEDIUM}) {
    display: inline-block;
    object-fit: contain;
    transition: opacity 500ms;
    z-index: 99999;
    padding: 2rem 0rem;
    display: inline;
    object-fit: contain;
    ${({ visible }) => (!visible ? `opacity: 1` : `opacity: 1;`)};
  }

  @media (max-width: ${SIZE.SMALL}) {
    display: inline;
    object-fit: contain;
    ${({ visible }) => (!visible ? `opacity: 1` : `opacity: 1;`)};
  }
`;

export const NavContact = styled(NavLink)`
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  display: inline-block;
  text-align: center;
  padding: 2.8rem 0rem;
  width: 15.6rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${colors.primaryyellow};
`;

export const Nav = styled(NavLink)`
  display: inline-block;
  text-align: center;
  padding: 2.8rem 0rem;
  min-width: 15.6rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  transition: color 0.3s;
  &:hover {
    color: ${colors.primaryyellow};
  }
`;

export const TextNav = styled.p`
  color: ${colors.textmain};
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  position: relative;
  display: inline;
  padding: 2.8rem 0rem;
  &.active {
    font-weight: 600;
  }
  &.active::after {
    content: '';
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    padding: 2.8rem 0rem;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    border-radius: 0.2rem;
    border-bottom: 0.4rem solid ${colors.primaryyellow};
  }

  &:hover::after {
    content: '';
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    padding: 2.8rem 0rem;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    border-radius: 0.2rem;
    border-bottom: 0.4rem solid ${colors.primaryyellow};
  }
`;

export const NavHeaderIpadIphone = styled.div`
  display: none;
  @media (max-width: ${SIZE.MEDIUM}) {
    display: flex;
    justify-content: space-between;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    align-items: center;
    max-width: 1199px;
    width: 100%;
    z-index: 9999;
    height: 100%;
  }
  @media (max-width: ${SIZE.SMALL}) {
    display: flex;
    z-index: 9999;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: auto;
  }
`;

export const Img = styled.img`
  cursor: pointer;
  padding: 2rem 0rem;
  object-fit: contain;
  transition: opacity 500ms;
  ${({ visible }) => (!visible ? `opacity: 1` : `opacity: 0;`)};

  @media (max-width: ${SIZE.MEDIUM}) {
    display: none;
  }

  @media (max-width: ${SIZE.SMALL}) {
    display: none;
  }
`;

export const DivMainSideBar = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 8rem 6.4rem;
  background-color: ${colors.textmain};
  transform: translateX(100%);
  transition: transform 500ms;

  ${({ show }) => (!show ? `transform: translateX(0);` : `transform: translateX(100);`)}
  @media (max-width: ${SIZE.SMALL}) {
    padding: 8rem 1rem;
  }
`;

export const DivBtn = styled.div`
  @media (max-width: ${SIZE.MEDIUM}) {
    display: inline-block;
    object-fit: contain;
    cursor: pointer;
    background-color: transparent;
    color: white;
    border: none;
    /* ${({ show }) => (!show ? `opacity: 1` : `opacity: 0  ;`)} */
  }
  @media (max-width: ${SIZE.SMALL}) {
    display: inline;
    object-fit: contain;
    font-size: 20px;
    padding-left: 12rem;
    background-color: transparent;
    color: white;
    border: none;
  }
`;

export const LinkNav = styled(NavLink)`
  font-family: 'Montserrat', sans-serif;
  padding-bottom: 2.4rem;
  line-height: 3.2rem;
  text-decoration: none;
  display: block;
  font-size: 2rem;
  font-weight: 600;
  text-decoration: none;
  color: ${colors.bgdisabled};
  transition: color 300ms;
  &:hover,
  &.active {
    color: ${colors.primaryyellow};
  }
  @media (max-width: ${SIZE.SMALL}) {
    padding: 0rem 0rem 2.4rem;
  }
`;

export const BarContainer = styled.div`
  display: inline-block;
  cursor: pointer;
  @media (max-width: ${SIZE.MEDIUM}) {
  }
  @media (max-width: ${SIZE.SMALL}) {
  }
`;

export const Bar1 = styled.div`
  transition: 0.4s;
  ${({ show }) =>
    !show
      ? `width: 24px;
        height: 2px;
        background-color: #09233C;`
      : `width: 24px;
        height: 2px;
        background-color: white;
        transform: rotate(-45deg) translate(-7px, 5px);`};
`;

export const Bar2 = styled.div`
  width: 24px;
  height: 2px;
  background-color: ${colors.textmain};
  margin: 6px 0;
  transition: 0.4s;
  ${({ show }) => (!show ? `opacity: 1` : `opacity: 0  ;`)}
`;

export const Bar3 = styled.div`
  transition: 0.4s;
  ${({ show }) =>
    !show
      ? ` width: 24px;
          height: 2px;
          background-color: #09233C;`
      : `width: 24px; 
      height: 2px;
      background-color: white;
      transform: rotate(45deg) translate(-6px, -5px);`}
`;

export const LinkContact = styled.div`
  font-family: 'Montserrat', sans-serif;
  padding-bottom: 2.4rem;
  line-height: 3.2rem;
  text-decoration: none;
  display: block;
  font-size: 2rem;
  font-weight: 600;
  text-decoration: none;
  color: ${colors.primaryyellow};
`;

export const SMlogo = styled.img`
  object-fit: contain;
  display: inline-block;
  cursor: pointer;
  margin-right: 1.8rem;
`;

export const TitleList = styled.p`
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  margin-top: 0rem;
  color: ${({ color }) => (color ? color : colors.textcaption)};
  font-size: 1.2rem;
  line-height: 2rem;
  display: block;
  ${({ align }) => align && `text-align: ${align}`};
`;
export const CopyrightFooter = styled(ParagraphBody)`
  @media (max-width: ${SIZE.MEDIUM}) {
    color: ${colors.bgdisabled};
    font-family: 'Raleway', sans-serif;
    font-weight: normal;
    color: ${({ color }) => (color ? color : colors.bgdisabled)};
    font-size: 1.6rem;
    line-height: 2.4rem;
    ${({ lgTop }) => lgTop && `margin-top: ${lgTop}`};
    ${({ lgBottom }) => lgBottom && `margin-bottom: ${lgBottom}`};
    ${({ align }) => align && `text-align: ${align}`};
  }
  @media (max-width: ${SIZE.SMALL}) {
    color: ${colors.bgdisabled};
    font-family: 'Raleway', sans-serif;
    font-weight: normal;
    color: ${({ color }) => (color ? color : colors.bgdisabled)};
    font-size: 1.2rem;
    line-height: 2rem;
    ${({ lgTop }) => lgTop && `margin-top: ${lgTop}`};
    ${({ lgBottom }) => lgBottom && `margin-bottom: ${lgBottom}`};
    ${({ align }) => align && `text-align: ${align}`};
  }
`;
export const Logo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: self-start;
  align-items: self-start;
  align-content: left;
`;
export const FooterImg = styled.img`
  object-fit: contain;
  display: inline-block;
  cursor: pointer;
`;
