import { AboutShape, NewLayout, Spacer } from '../components';
import { Cell, Grid, GridWrapper } from '../components/UI';

export const Test = () => {
  return (
    <NewLayout>
      <Spacer lgheight={'17.8rem'} mdheight={'14.8rem'} smheight={'9.6rem'} />
      <GridWrapper>
        <Grid>
          <Cell lg={{ colSpan: 4 }} />
          <Cell lg={{ colSpan: 4 }} md={{ colSpan: 12 }}>
            <AboutShape />
          </Cell>
          <Cell lg={{ colSpan: 4 }} />
        </Grid>
      </GridWrapper>
      <Spacer lgheight={'9.6rem'} />
    </NewLayout>
  );
};
