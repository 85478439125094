import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { api } from '../../../api';
import { GridWrapper, Grid, Cell, Spacer } from '../../UI';
import { BtnStartups } from './Stakeholders.style';
import { AdminCardStakeholder } from '../..';

export const AdminStakeholders = ({ stakeholdersList, refresh }) => {
  const history = useHistory();
  const [visibleStartups, setVisibleStartups] = useState(9);

  const handleShowMoreStakeholders = () => {
    setVisibleStartups(visibleStartups + 9);
  };

  const handlePublishStakeholder = async stakeholder => {
    const data = await api.updateStakeholder({
      ...stakeholder,
      publish_status: !stakeholder.publish_status,
    });
    if (data) refresh();
    else history.replace('/login');
  };

  const handleEditStakeholder = id => {
    history.push(`/admin/community/${id}`);
  };

  const handleDeleteStakeholder = async id => {
    const data = await api.deleteStakeholder(id);
    if (data) setTimeout(() => refresh(), 500);
    else history.replace('/login');
  };

  return (
    <GridWrapper>
      <Grid>
        {stakeholdersList.slice(0, visibleStartups).map((user, index) => (
          <Cell key={index}>
            <AdminCardStakeholder
              name={user.firstName + ' ' + user.lastName}
              image={user.avatar}
              role={user.role}
              linkedin={user.linkedin}
              facebook={user.facebook}
              instagram={user.instagram}
              published={user.publish_status}
              onPublish={() => handlePublishStakeholder(user)}
              onEdit={() => handleEditStakeholder(user.url)}
              onDelete={() => handleDeleteStakeholder(user.url)}
            />
          </Cell>
        ))}
        {stakeholdersList.length > visibleStartups && (
          <Cell lg={{ colSpan: 4, offset: 5 }} md={{ colSpan: 6, offset: 4 }}>
            <Spacer lgheight={'2.8rem'} />
            <BtnStartups onClick={handleShowMoreStakeholders}>
              <span>View more</span>
            </BtnStartups>
            <Spacer lgheight={'9.6rem'} />
          </Cell>
        )}
      </Grid>
    </GridWrapper>
  );
};
