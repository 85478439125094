import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GridWrapper, Grid, Cell, Spacer } from '../../UI';
import { BtnStartups } from './StartUps.style';
import { api } from '../../../api';
import { AdminCardStartup } from '../..';

export const AdminStartups = ({ startupsList, refresh }) => {
  const history = useHistory();
  const [visibleStartups, setVisibleStartups] = useState(9);

  const handleShowMoreStartups = () => {
    setVisibleStartups(visibleStartups + 9);
  };

  const handlePublishStartup = async startup => {
    const data = await api.updateStartup({
      ...startup,
      publish_status: !startup.publish_status,
    });
    if (data) refresh();
    else history.replace('/login');
  };

  const handleEditStartup = id => {
    history.push(`/admin/startups/${id}`);
  };

  const handleDeleteStartup = async id => {
    const data = await api.deleteStartup(id);
    if (data) refresh();
    else history.replace('/login');
  };

  return (
    <GridWrapper>
      <Grid>
        {startupsList.slice(0, visibleStartups).map((startup, index) => (
          <Cell key={index}>
            <AdminCardStartup
              title={startup.name}
              image={startup.logo}
              industry={startup.industry}
              funding={startup.funding}
              published={startup.publish_status}
              onPublish={() => handlePublishStartup(startup)}
              onEdit={() => handleEditStartup(startup.url)}
              onDelete={() => handleDeleteStartup(startup.url)}
            />
          </Cell>
        ))}
        <Cell />
        {startupsList.length > visibleStartups && (
          <Cell lg={{ colSpan: 4, offset: 5 }} md={{ colSpan: 6, offset: 4 }}>
            <Spacer lgheight={'0.8rem'} />
            <BtnStartups onClick={handleShowMoreStartups}>
              <span>View more</span>
            </BtnStartups>
            <Spacer lgheight={'9.6rem'} />
          </Cell>
        )}
      </Grid>
    </GridWrapper>
  );
};
