import styled from 'styled-components';
import { SIZE } from '../../consts';
import { colors } from '../../consts/colors';
import { ParagraphBody, ParagraphCaption } from '../common/common.style';

export const BtnStakeholders = styled.div`
  display: inline-block;
  min-width: 3.8rem;
  background-color: transparent;
  border: 2px solid ${colors.textmain};
  padding: 1rem 7.95rem;
  height: auto;
  font-size: 2rem;
  font-family: 'Montserrat', sans-serif;
  line-height: 3.2rem;
  text-align: center;
  border-radius: 0.8rem;
  & a {
    color: ${colors.textmain};
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 16px 24px 8px rgba(9, 35, 60, 0.05);
  @media (max-width: 1199px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    height: 24rem;
    width: 24rem;
    margin: auto;
  }
`;

export const ImgContainer = styled.div`
  display: inline-block;
  max-width: 38rem;
  width: 38rem;
  height: 38rem;
  border-radius: 50%;
  box-shadow: 0 16px 24px 8px rgba(9, 35, 60, 0.07);
  @media (max-width: ${SIZE.SMALL}) {
    width: 28rem;
    height: 28rem;
    margin: auto;
    display: block;
  }
`;

export const CardImg = styled.img`
  display: block;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  max-width: 38rem;
  max-height: 38rem;
  border-radius: 50%;
`;

export const SubtitleParagraph = styled(ParagraphBody)`
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const TextCard = styled(ParagraphCaption)`
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: 'Raleway', sans-serif;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const TitleSmall = styled.p`
  font-family: 'Raleway' sans-serif;
  margin-top: 0rem;
  color: ${({ color }) => (color ? color : colors.textcaption)};
  font-size: 1.2rem;
  line-height: 2rem;
  display: block;
  ${({ align }) => align && `text-align: ${align}`};
`;

export const SmLogo = styled.img`
  object-fit: contain;
  margin: auto;
  display: inline-block;
  cursor: pointer;
`;

export const SmLogoDisplay = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 2.8rem;
`;

export const Email = styled.a`
  font-family: 'Raleway';
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.8rem;
  color: ${colors.primaryyellow};
`;

export const CardTeam = styled.div`
  cursor: pointer;
  width: 100%;
`;

export const TeamImg = styled.img`
  align-items: center;
  border-radius: 50%;
  background-color: #ffffff;
  @media (max-width: 1199px) {
    widht: 50%;
    border-radius: 50%;
  }
`;

export const CardTeamContainer = styled.div`
  padding: 0.2rem 1.6rem;
`;

export const TextCardMember = styled(ParagraphCaption)`
  text-align: center;
  font-family: 'Raleway', sans-serif;
  color: ${({ color }) => (color ? color : colors.textcaption)};
  font-size: 1.2rem;
  line-height: 2rem;
`;
export const Number = styled(ParagraphBody)`
  color: ${colors.textmain};
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: 600;
  line-height: 3.2rem;
`;
export const Role = styled(ParagraphBody)`
  font-family: 'Raleway', sans-serif;
  color: ${({ color }) => (color ? color : colors.textcaption)};
  line-height: 2.4rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
  span {
    &::after {
      content: ', ';
    }
    &:last-child {
      &::after {
        content: '';
      }
    }
  }
`;
