import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import {
  AdminLogin,
  AdminResource,
  AdminResources,
  AdminStakeholder,
  AdminStakeholders,
  AdminStartUp,
  AdminStartups,
  AboutUs,
  GetInTouch,
  Home,
  Resource,
  Resources,
  SuggestStartup,
  SuggestStakeholder,
  StakeholdersPage,
  Stakeholder,
  Startup,
  Startups,
  Page404,
  Test,
} from './pages';
import { ProtectedRoute } from './components';
import { HelmetProvider } from 'react-helmet-async';

const PUBLIC_ROUTES = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/login',
    component: AdminLogin,
  },
  {
    path: '/startups',
    component: Startups,
  },
  {
    path: '/startups/:id',
    component: Startup,
  },
  {
    path: '/community',
    component: StakeholdersPage,
  },
  {
    path: '/community/:id',
    component: Stakeholder,
  },
  {
    path: '/resources',
    component: Resources,
  },
  {
    path: '/resources/:id',
    component: Resource,
  },
  {
    path: '/about-us',
    component: AboutUs,
  },
  {
    path: '/get-in-touch',
    component: GetInTouch,
  },
  {
    path: '/recommend-startup',
    component: SuggestStartup,
  },
  {
    path: '/recommend-stakeholder',
    component: SuggestStakeholder,
  },
  {
    path: '/test',
    component: Test,
  },
];

const ADMIN_ROUTES = [
  {
    path: '/admin',
    component: AdminLogin,
  },
  {
    path: '/admin/startups',
    component: AdminStartups,
  },
  {
    path: '/admin/startups/:id',
    component: AdminStartUp,
  },
  {
    path: '/admin/community',
    component: AdminStakeholders,
  },
  {
    path: '/admin/community/:id',
    component: AdminStakeholder,
  },
  {
    path: '/admin/resources',
    component: AdminResources,
  },
  {
    path: '/admin/resources/:id',
    component: AdminResource,
  },
];

const helmetContext = {};

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <HelmetProvider context={helmetContext}>
        <ScrollToTop />
        <Switch>
          {PUBLIC_ROUTES.map(route => (
            <Route
              path={route.path}
              exact
              component={route.component}
              key={`route_${route.path}`}
            />
          ))}
          {ADMIN_ROUTES.map(route => (
            <ProtectedRoute
              path={route.path}
              exact
              component={route.component}
              key={`protected_route_${route.path}`}
            />
          ))}
          <Route>
            <Page404 />
          </Route>
        </Switch>
      </HelmetProvider>
    </Router>
  );
}

export default App;
