import {
  CardImg,
  FlexDiv,
  FooterLinks,
  FooterTitleList,
  TextFooter,
  FooterLogoDisplay,
  CopyrightFooter,
  FooterImg,
  FooterSMlogo,
  FooterContact,
  Div,
  Display,
  DisplayIpadIphone,
  BackgroundShape,
  Container,
} from './Footer.style';
import { Spacer, Row, Col } from './common/common.style';
import { images } from '../public';
import { LINKS } from '../consts';

const Footer = () => {
  return (
    <div style={{ position: 'relative' }}>
      <BackgroundShape />

      <Spacer lgheight={'9.6rem'} />
      <Container>
        <Display>
          <Row>
            <Col lg={{ size: 5 }} md={{ size: 4 }} sm>
              <FlexDiv>
                <CardImg src={images.logoFooter} alt="logo" />
                <TextFooter align={'left'}>
                  {
                    ' Discover Timisoara’s Startup & Innovation Scene. Getting together founders, startups, and local stakeholders.'
                  }
                </TextFooter>
              </FlexDiv>
              <Spacer lgheight={'4.8rem'} />
            </Col>
            <Col lg={{ size: 2, offset: 1 }} md={{ size: 1 }} sm>
              <FooterTitleList>Discover</FooterTitleList>
              <FooterLinks lgmarginbottom={'1.6rem'} to="/startups">
                Startups
              </FooterLinks>
              <FooterLinks lgmarginbottom={'1.6rem'} to="/community">
                Community
              </FooterLinks>
              <FooterLinks to="/resources">Resources</FooterLinks>
            </Col>
            <Col lg={{ size: 2 }} md={{ size: 1 }} sm>
              <FooterTitleList>Contact</FooterTitleList>
              <FooterLinks lgmarginbottom={'1.6rem'} to="/about-us">
                About us
              </FooterLinks>
              <FooterContact to="/get-in-touch">Get in touch!</FooterContact>
            </Col>
            <Col lg={{ size: 2 }} md={{ size: 2 }} sm>
              <FooterTitleList>Social media</FooterTitleList>
              <Div>
                <a href={LINKS.LINKEDIN} target="_blank" rel="noreferrer">
                  <FooterSMlogo src={images.inFooter} alt="Timsiara startups linkedin" />
                </a>
                <a href={LINKS.FACEBOOK} target="_blank" rel="noreferrer">
                  <FooterSMlogo src={images.fbFooter} alt="Timisoara startups facebook" />
                </a>
                <a href={LINKS.INSTAGRAM} target="_blank" rel="noreferrer">
                  <FooterSMlogo src={images.instagramFooter} alt="Timisoara startups instagram" />
                </a>
              </Div>
            </Col>
          </Row>
        </Display>

        <Row>
          <Col md={{ size: 4 }} sm>
            <DisplayIpadIphone>
              <div style={{ display: 'inline-block' }}>
                <FooterTitleList>Discover</FooterTitleList>
                <FooterLinks lgmarginbottom={'1.6rem'} to="/startups">
                  Startups
                </FooterLinks>
                <FooterLinks lgmarginbottom={'1.6rem'} to="/community">
                  Community
                </FooterLinks>
                <FooterLinks to="/resources">Resources</FooterLinks>
              </div>
              <div style={{ display: 'inline-block' }}>
                <FooterTitleList>Contact</FooterTitleList>
                <FooterLinks lgmarginbottom={'1.6rem'} to="/about-us">
                  About us
                </FooterLinks>
                <FooterContact to="/get-in-touch">Get in touch!</FooterContact>
              </div>
              <div style={{ display: 'inline-block' }}>
                <FooterTitleList>Social media</FooterTitleList>
                <Div>
                  <a href={LINKS.LINKEDIN} target="_blank" rel="noreferrer">
                    <FooterSMlogo src={images.inFooter} alt="Timsiara startups linkedin" />
                  </a>
                  <a href={LINKS.FACEBOOK} target="_blank" rel="noreferrer">
                    <FooterSMlogo src={images.fbFooter} alt="Timisoara startups facebook" />
                  </a>
                  <a href={LINKS.INSTAGRAM} target="_blank" rel="noreferrer">
                    <FooterSMlogo src={images.instagramFooter} alt="Timisoara startups instagram" />
                  </a>
                </Div>
              </div>
            </DisplayIpadIphone>
          </Col>
        </Row>

        <Row>
          <Col lg={{ size: 12 }} md={{ size: 4 }} sm>
            <Spacer lgheight={'4.8rem'} smheight={'2.4rem'} />
            <FooterTitleList align={'center'}>POWERED BY</FooterTitleList>
            <FooterLogoDisplay>
              <a href={LINKS.COWORK} target="_blank" rel="noreferrer">
                <FooterImg src={images.logoCowork} alt="logo" />
              </a>
              <a href={LINKS.ENOVATIKA} target="_blank" rel="noreferrer">
                <FooterImg src={images.logoEnovatika} target="blank" rel="noreferer" alt="logo" />
              </a>
            </FooterLogoDisplay>
          </Col>
        </Row>

        <Spacer lgheight={'4.8rem'} mdheight={'4.8em'} />

        <Row>
          <Col lg={{ size: 12 }} md={{ size: 4 }} sm>
            <CopyrightFooter align={'center'}>
              ©Copyright 2021 Timisoara Startups. All rights reserved.
            </CopyrightFooter>
          </Col>
        </Row>
        <Spacer lgheight={'9.6rem'} mdheight={'6.4rem'} />
      </Container>
    </div>
  );
};

export default Footer;
