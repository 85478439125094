import React, { useState, useEffect } from 'react';
import { Spacer, Spinner, colors } from '../UI';
import {
  Card,
  CardContainer,
  Menu,
  MenuItem,
  Tag,
  CardImg,
  CardSubtitle,
  CardTitle,
  ImgContainer,
} from './CardBlogPost.style';

export const AdminCardBlogPost = ({
  img,
  title,
  subtitle,
  published,
  onPublish,
  onEdit,
  onDelete,
}) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(false);
  }, [img, title, subtitle, published, onPublish, onEdit, onDelete]);
  return (
    <Card>
      <ImgContainer>
        <Menu>
          {loading ? (
            <Spinner />
          ) : (
            <>
              {published ? (
                <MenuItem
                  color={colors.white}
                  onClick={() => {
                    setLoading(true);
                    onPublish();
                  }}
                >
                  UNPUBLISH
                </MenuItem>
              ) : (
                <MenuItem
                  color={colors.success}
                  onClick={() => {
                    setLoading(true);
                    onPublish();
                  }}
                >
                  PUBLISH
                </MenuItem>
              )}
              <MenuItem color={colors.error} onClick={onEdit}>
                EDIT
              </MenuItem>
              <MenuItem
                color={colors.white}
                onClick={() => {
                  setLoading(true);
                  onDelete();
                }}
              >
                DELETE
              </MenuItem>
            </>
          )}
        </Menu>
        <Tag color={published ? colors.success : colors.error}>
          {published ? 'Published' : 'Unpublished'}
        </Tag>
        <CardImg src={img} />
      </ImgContainer>
      <CardContainer>
        <Spacer lgheight="1.6rem" />
        <CardSubtitle>{subtitle}</CardSubtitle>
        <Spacer lgheight="0.8rem" />
        <CardTitle>{title}</CardTitle>
        <Spacer lgheight="1.6rem" />
      </CardContainer>
    </Card>
  );
};
