import styled from "styled-components";
import { colors } from "../../../consts/colors";
import { ParagraphBody, ParagraphCaption } from "../../common/common.style";

export const CardStartup = styled.div`
  cursor: pointer;
  box-shadow: 0 2px 4px 2px rgba(9, 35, 60, 0.05);
  width: 100%;
  border-radius: 1.6rem;
  background-color: #ffffff;
  position: relative;
  transition: all 0.3s;
  &:hover {
    box-shadow: 0 16px 24px 8px rgba(9, 35, 60, 0.07);
  }
  @media (max-width: 1199px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const ImgContainer = styled.div`
  display: inline-block;
  margin: 4.8rem 0rem 2.4rem 1.6rem;
  width: 14.8rem;
  height: 14.8rem;
  border-radius: 0.8rem;
  position: relative;
  overflow: hidden;
`;

export const CardImg = styled.img`
  height: auto;
  width: 100%;
  border-radius: 0.8rem;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;

export const CardContainer = styled.div`
  padding: 0rem 1.6rem;
`;

export const SubtitleParagraph = styled(ParagraphBody)`
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const TextCard = styled(ParagraphCaption)`
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: "Raleway", sans-serif;
  color: ${({ color }) => (color ? color : colors.textcaption)};
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const PublishStatus = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  font-size: 14px;
  line-height: 22px;
  border-radius: 4px;
  background-color: ${({ status, rejected }) =>
    rejected ? "#DA1919" : status ? "#2DDA19" : "#FAA31B"};
`;
