// UI
export const SIZE = {
  SMALL: "720px",
  MEDIUM: "1024px",
  LARGE: "1200px",
  MARGIN: "2.4rem",
};

// STARTUPS && STAKEHOLDERS
export const ROLES = [
  "Community Leader",
  "Coworking space manager",
  "Founder/Co-Founder",
  "Investor",
  "Legal and Accounting",
  "Public sector representative",
  "Universities representative",
];

export const FUNDING = [
  "Bootstrapped / Pre-seed (<50kEur raised)",
  "Seed (50kEur -> 3milEur raised)",
  "Series A (3mil+ raised)",
  "Series B",
  "Series C",
  "Exit",
];
export const INDUSTRIES = [
  "Agritech",
  "Artificial Intelligence",
  "AR / VR",
  "Big Data",
  "Blockchain",
  "CleanTech",
  "Dating",
  "DeepTech",
  "EdTech",
  "Enterprise Software",
  "Fashion",
  "Fintech",
  "Foodtech",
  "Gaming",
  "IoT",
  "Life Sciences",
  "MedTech",
  "Proptech",
  "SaaS",
  "Smart City",
];

export const CITIES = ["Timișoara", "Arad", "Caransebeș", "Lugoj", "Reșița"];

export const STARTUP = {
  name: "",
  description: "",
  website: "",
  email: "",
  phone: "",
  city: "",
  postal_code: "",
  address: "",
  funding: "",
  industry: "",
  instagram: "",
  linkedin: "",
  facebook: "",
  logo: "",
  founders: [],
};

export const CATEGORIES = ["Events", "News", "Interviews"];
export const STATUSES = ["Draft", "Published"];

export const RESOURCE = {
  title: "",
  author: "",
  content: "",
  featuredImage: "",
  category: CATEGORIES[0],
  publishStatus: STATUSES[0],
};

export const POSTERRORS = {
  title: "",
  author: "",
  content: "",
  featuredImage: "",
  category: "",
  publishStatus: "",
};

export const STAKEHOLDER = {
  firstName: "",
  lastName: "",
  position: "",
  description: "",
  linkedin: "",
  facebook: "",
  instagram: "",
  avatar: "",
  role: "",
  email: {
    value: "",
    visible: true,
  },
  phone: {
    value: "",
    visible: true,
  },
  status: false,
};

export const ERRORS = {
  firstName: "",
  lastName: "",
  position: "",
  description: "",
  linkedin: "",
  facebook: "",
  instagram: "",
  avatar: "",
  role: "",
  email: "",
  phone: "",
  status: false,
};

export const MEMBER = {
  first_name: "",
  last_name: "",
  position: "",
  avatar: "",
};

export const LINKS = {
  LINKEDIN: "https://www.linkedin.com/company/coworktimisoara/",
  INSTAGRAM: "https://www.instagram.com/cowork_tm/",
  FACEBOOK: "https://www.facebook.com/TimisoaraStartups",
  ENOVATIKA: "https://enovatika.com/",
  COWORK: "https://coworktimisoara.com/",
};
