import styled, { keyframes } from 'styled-components';

const bounce = keyframes`
    0% {
      height: 10px;
    }
    50% {
      height: 80px;
    }
    100% {
      height: 10px;
    }
`;

const bounceDelayed = keyframes`
    0% {
      height: 80px;
    }
    50% {
      height: 10px;
    }
    100% {
      height: 80px;
    }
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 9999;
  transition: opacity 0.6s ease-in;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  display: ${({ active }) => (active ? 'block' : 'none')};
  & .load_wrap {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .load {
    width: 100px;
    height: 100px;
    position: relative;
  }
  & .load > .line_1 {
    width: 10px;
    height: 80px;
    border-radius: 5px;
    background: linear-gradient(45deg, purple, orange);
    position: absolute;
    left: 23px;
    top: 50%;
    transform: translateY(-50%);
    animation: ${bounceDelayed} 1s infinite ease-in-out;
  }
  & .load > .line_2 {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: linear-gradient(45deg, purple, orange);
    position: absolute;
    left: 43px;
    top: 50%;
    transform: translateY(-50%);
    animation: ${bounce} 1s infinite ease-in-out;
  }
  & .load > .line_3 {
    width: 10px;
    height: 80px;
    border-radius: 5px;
    background: linear-gradient(45deg, purple, orange);
    position: absolute;
    left: 63px;
    top: 50%;
    transform: translateY(-50%);
    animation: ${bounceDelayed} 1s infinite ease-in-out;
  }
`;
