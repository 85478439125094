import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Ring = styled.div`
  transform: translate(-100%, -100%);
  position: relative;
  width: 2rem;
  height: 2rem;
  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 4em;
    height: 4em;
    margin: 0.5em;
    border: 0.5em solid #fff;
    border-radius: 50%;
    animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: black transparent transparent transparent;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;
