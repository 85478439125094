import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { api } from '../../api';
import { Loading, Spacer } from '../../components/UI';
import { FilterStartups, LayoutAdmin, NoResults } from '../../components';
import { AdminStartups } from '../../components/sections/startups';

export const AdminStartupsPage = () => {
  const [startups, setStartups] = useState([]);
  const [filter, setFilter] = useState({
    search: '',
    industry: 'All',
    funding: 'All',
  });
  const [filteredStartups, setFilteredStartups] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const data = await api.getAllStartups();
    if (data) {
      setStartups(data.sort(dateDescending));
      setLoading(false);
    }
  };

  const dateDescending = (a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    if (dateA > dateB) return -1;
    if (dateA > dateB) return 1;
    return 0;
  };

  //APPLY FILTER
  useEffect(() => {
    let filteredList = startups;
    if (filter.search !== '')
      filteredList = filteredList.filter(item =>
        item.name.toLowerCase().includes(filter.search.toLowerCase())
      );
    if (filter.industry !== 'All')
      filteredList = filteredList.filter(item =>
        item.industry.toLowerCase().includes(filter.industry.toLowerCase())
      );
    if (filter.funding !== 'All')
      filteredList = filteredList.filter(item =>
        item.funding.toLowerCase().includes(filter.funding.toLowerCase())
      );
    setFilteredStartups(filteredList);
    // eslint-disable-next-line
  }, [filter, startups]);

  //FETCH DATA
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <LayoutAdmin>
      <Helmet>
        <title>Startups | Timisoara Startups</title>
      </Helmet>
      <Loading isLoading={loading} />
      <Spacer lgheight={'9.6rem'} />
      <FilterStartups onFilterChange={setFilter} />
      <Spacer lgheight={'2.8rem'} />
      {filteredStartups.length === 0 ? (
        <NoResults />
      ) : (
        <AdminStartups startupsList={filteredStartups} refresh={fetchData} />
      )}
    </LayoutAdmin>
  );
};
