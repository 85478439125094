import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const ProtectedRoute = ({ children, path, ...rest }) => {
  return (
    <>
      {!localStorage.getItem('token') && <Redirect to="/login" />}
      {localStorage.getItem('token') && (
        <Route path={path} {...rest}>
          {children}
        </Route>
      )}
    </>
  );
};
