import styled from 'styled-components';
import { colors } from '../UI';
import { H3, ParagraphBody, ParagraphCaption } from '../UI';

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px 2px rgba(9, 35, 60, 0.05);
  padding: 4.8rem 2.4rem;
  width: 100%;
  height: 100%;
  border-radius: 1.6rem;
  background-color: #ffffff;
  transition: all 0.3s;
  &:hover {
    box-shadow: 0 16px 24px 8px rgba(9, 35, 60, 0.07);
  }
`;

export const Menu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.4rem;
  border-radius: 1.6rem;
  inset: 0;
  z-index: 1;
  transition: background-color 0.3s ease-in;
  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
    & > p {
      opacity: 1;
      z-index: 1;
    }
  }
  & > p {
    opacity: 0;
    z-index: -1;
  }
`;

export const MenuItem = styled.p`
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 600;
  text-align: center;
  user-select: none;
  ${({ color }) =>
    color === colors.white
      ? `
    color: ${colors.textmain};
    border: 1px solid ${colors.textmain};
    `
      : `color: ${colors.bgdisabled};`}
  width: 24rem;
  padding: 0.8rem;
  border-radius: 0.8rem;
  ${({ color }) => color && `background-color: ${color};`}
  transition: opacity 0.3s ease-in;
`;

export const Tag = styled.p`
  margin: 0;
  position: absolute;
  top: 2.4rem;
  right: 2.4rem;
  font-family: 'Raleway', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: ${colors.bgdisabled};
  padding: 0.4rem 1.6rem;
  border-radius: 0.4rem;
  ${({ color }) => color && `background-color: ${color};`}
`;

export const ImgContainer = styled.div`
  display: inline-block;
  width: 14.8rem;
  height: 14.8rem;
  border-radius: 0.8rem;
  overflow: hidden;
`;

export const CardImg = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  object-position: center;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const TitleCard = styled(H3)``;

export const Subtitle = styled(ParagraphBody)`
  margin: 0;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const TextCard = styled(ParagraphCaption)`
  margin-top: auto;
  font-family: 'Raleway', sans-serif;
  color: ${({ color }) => (color ? color : colors.textcaption)};
  font-size: 1.6rem;
  line-height: 2.4rem;
`;
