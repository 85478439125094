import React, { useState, useEffect } from 'react';
import { ResourcesList, Layout } from '../components';
import { Helmet } from 'react-helmet-async';
import { Loading } from '../components/UI';
import { api } from '../api';

export const ResourcePage = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const data = await api.getResources();
    if (data) {
      setPosts(data.filter(post => post.publishStatus === true));
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);
  return (
    <Layout>
      <Helmet>
        <title>Resources | Timisoara Startups</title>
      </Helmet>
      <Loading isLoading={loading} />
      <ResourcesList posts={posts} />
    </Layout>
  );
};
