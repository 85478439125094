import styled from "styled-components";
import ReactMarkdown from "react-markdown";

export const Date = styled.p``;

export const FeaturedImage = styled.img``;

export const ReadingTime = styled.span``;

export const Markdown = styled(ReactMarkdown)``;
