import React from 'react';
import {
  Container,
  CheckboxContainer,
  StyledCheckbox,
  Icon,
  TextCheckmark,
} from './Checkbox.style';

export const Checkbox = ({ children, onClick, value }) => {
  const handleClick = () => {
    onClick(!value);
  };

  return (
    <Container onClick={handleClick}>
      <CheckboxContainer>
        <StyledCheckbox type="checkbox" name="terms" active={value}>
          <Icon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </Icon>
        </StyledCheckbox>
      </CheckboxContainer>
      <TextCheckmark>{children}</TextCheckmark>
    </Container>
  );
};
