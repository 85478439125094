import styled from 'styled-components';

export const H1 = styled.h1`
  font-family: 'Montserrat', sans-serif;
  font-size: 4.2rem;
  font-weight: 700;
  color: ${({ color }) => (color ? color : colors.textmain)};
  line-height: 5rem;

  .breakLine {
    display: inline-block;
    @media (max-width: 767px) {
      display: inline;
    }
  }
  ${({ align }) => align && `text-align: ${align}`};
  @media (max-width: 1199px) {
    font-size: 4.2rem;
    ${({ talign }) => talign && `text-align: ${talign}`};
    ${({ mdTopH1 }) => mdTopH1 && `margin-top: ${mdTopH1}`};
  }
  @media (max-width: 767px) {
    font-size: 2.8rem;
    margin: auto;
    ${({ malign }) => malign && `text-align: ${malign}`};
    ${({ msTopH1 }) => msTopH1 && `margin-top: ${msTopH1}`};
  }
`;

export const H2 = styled.h2`
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 3.6rem;
  line-height: 4.4rem;
  ${({ lgMarginTop }) => lgMarginTop && `margin-top: ${lgMarginTop}`};
  ${({ lgmarginbottom }) => lgmarginbottom && `margin-bottom: ${lgmarginbottom}`};
  ${({ align }) => align && `text-align: ${align}`};

  @media (max-width: 1199px) {
    font-size: 3.6rem;
    line-height: 1.19;
    ${({ talign }) => talign && `text-align: ${talign}`};
    ${({ mdMarginTop }) => mdMarginTop && `margin-top: ${mdMarginTop}`};
    ${({ mdMarginBottom }) => mdMarginBottom && `margin-bottom: ${mdMarginBottom}`};
  }
  @media (max-width: 767px) {
    text-align: center;
    font-size: 3.6rem;
    line-height: 1.19;

    ${({ malign }) => malign && `text-align: ${malign}`};
    ${({ smMarginTop }) => smMarginTop && `margin-top: ${smMarginTop}`};
    ${({ smMarginBottom }) => smMarginBottom && `margin-bottom: ${smMarginBottom}`};
  }
`;

export const H3 = styled.h3`
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 2.8rem;
  line-height: 4rem;
  ${({ align }) => align && `text-align: ${align}`};
  @media (max-width: 1199px) {
    font-size: 2.8rem;
    ${({ talign }) => talign && `text-align: ${talign}`};
  }
  @media (max-width: 767px) {
    font-size: 2.8rem;
    ${({ malign }) => malign && `text-align: ${malign}`};
  }
`;

export const H4 = styled.h4`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 2rem;
  line-height: 3.2rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
  ${({ lgMarginTop }) => lgMarginTop && `margin-top: ${lgMarginTop}`}
  ${({ align }) => align && `text-align: ${align}`};

  @media (max-width: 1199px) {
    font-size: 2rem;
    line-height: 3.2rem;
    ${({ mdMarginTop }) => mdMarginTop && `margin-top: ${mdMarginTop}`}
    ${({ talign }) => talign && `text-align: ${talign}`};
  }

  @media (max-width: 767px) {
    font-size: 2rem;
    line-height: 3.2rem;
    ${({ smMarginTop }) => smMarginTop && `margin-top: ${smMarginTop}`}
    ${({ malign }) => malign && `text-align: ${malign}`};
  }
`;

export const H5 = styled.h5`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 1.6rem;
  line-height: 2.4rem;

  ${({ align }) => align && `text-align: ${align}`};
  @media (max-width: 1199px) {
    ${({ talign }) => talign && `text-align: ${talign}`};
  }
  @media (max-width: 767px) {
    ${({ malign }) => malign && `text-align: ${malign}`};
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
`;

export const ParagraphBody = styled.p`
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: 'Raleway', sans-serif;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 1.6rem;
  line-height: 2.4rem;
  ${({ lgTop }) => lgTop && `margin-top: ${lgTop}`};
  ${({ lgBottom }) => lgBottom && `margin-bottom: ${lgBottom}`};
  ${({ align }) => align && `text-align: ${align}`};

  @media (max-width: 1199px) {
    ${({ talign }) => talign && `text-align: ${talign}`};
    ${({ mdBottom }) => mdBottom && `margin-bottom: ${mdBottom}`};
    ${({ mdTop }) => mdTop && `margin-top: ${mdTop}`};
  }

  @media (max-width: 767px) {
    ${({ malign }) => malign && `text-align: ${malign}`};
    ${({ msTop }) => msTop && `margin-top: ${msTop}`};
    ${({ msBottom }) => msBottom && `margin-bottom: ${msBottom}`};
  }
`;
export const ParagraphCaption = styled.p`
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: 'Montserrat', sans-serif;
  color: ${({ color }) => (color ? color : colors.textcaption)};
  font-size: 1.6rem;
  line-height: 2.4rem;
  ${({ lgTop }) => lgTop && `margin-top: ${lgTop}`};
  ${({ lgBottom }) => lgBottom && `margin-bottom: ${lgBottom}`};
  ${({ align }) => align && `text-align: ${align}`};

  @media (max-width: 1199px) {
    ${({ talign }) => talign && `text-align: ${talign}`};
    ${({ mdBottom }) => mdBottom && `margin-bottom: ${mdBottom}`};
    ${({ mdTop }) => mdTop && `margin-top: ${mdTop}`};
  }

  @media (max-width: 767px) {
    ${({ malign }) => malign && `text-align: ${malign}`};
    ${({ msTop }) => msTop && `margin-top: ${msTop}`};
    ${({ msBottom }) => msBottom && `margin-bottom: ${msBottom}`};
  }
`;
export const Caption = styled.p`
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 1.2rem;
  line-height: 2rem;
  ${({ lgTop }) => lgTop && `margin-top: ${lgTop}`};
  ${({ lgBottom }) => lgBottom && `margin-bottom: ${lgBottom}`};
  ${({ align }) => align && `text-align: ${align}`};

  @media (max-width: 1199px) {
    ${({ talign }) => talign && `text-align: ${talign}`};
    ${({ mdBottom }) => mdBottom && `margin-bottom: ${mdBottom}`};
    ${({ mdTop }) => mdTop && `margin-top: ${mdTop}`};
  }

  @media (max-width: 767px) {
    ${({ malign }) => malign && `text-align: ${malign}`};
    ${({ msTop }) => msTop && `margin-top: ${msTop}`};
    ${({ msBottom }) => msBottom && `margin-bottom: ${msBottom}`};
  }
`;

export const Hint = styled.p`
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  color: ${({ color }) => (color ? color : colors.textcaption)};
  font-size: 1rem;
  line-height: 1.6rem;
  margin-top: 0.2rem;
  margin-bottom: 0;
  ${({ lgTop }) => lgTop && `margin-top: ${lgTop}`};
  ${({ lgBottom }) => lgBottom && `margin-bottom: ${lgBottom}`};
  ${({ align }) => align && `text-align: ${align}`};

  @media (max-width: 1199px) {
    ${({ talign }) => talign && `text-align: ${talign}`};
    ${({ mdBottom }) => mdBottom && `margin-bottom: ${mdBottom}`};
    ${({ mdTop }) => mdTop && `margin-top: ${mdTop}`};
  }

  @media (max-width: 767px) {
    ${({ malign }) => malign && `text-align: ${malign}`};
    ${({ msTop }) => msTop && `margin-top: ${msTop}`};
    ${({ msBottom }) => msBottom && `margin-bottom: ${msBottom}`};
  }
`;
export const A = styled.a`
  display: inline;
  font-size: 1.8rem;
  line-height: 2.8rem;
`;

export const colors = {
  jetblack: '#292929',
  silver: '#757575',
  darkbluegrey: '#1d1a3f',
  purpleypink: '#dc31b3',
  tealish: '#29cdcb',
  verylightpink: '#f3f3f3',
  ///------>Starting from here
  primarypink: '#ED3694',
  primaryyellow: '#FAA31B',
  textmain: ' #09233C',
  textcaption: '#647894',
  bgdisabled: '#f0f3f7',
  primarypurple: '#772671',
  placeholdertext: '#AFBCCE',
  white: '#ffffff',
  error: '#ED4337',
  lightGreen: '#D4F3E2',
  success: '#2cc470',
};
