import styled from 'styled-components';
import { SIZE } from '../../../consts';
import { images } from '../../../public';
import { colors, GridWrapper } from '../../UI';
import { ParagraphBody } from '../../UI';

export const BtnStartups = styled.div`
  cursor: pointer;
  display: inline-block;
  width: 100%;
  min-width: 3.8rem;
  background-color: transparent;
  border: 2px solid ${colors.textmain};
  padding: 1.4rem 0rem;
  height: auto;
  font-size: 2rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  line-height: 3.2rem;
  text-align: center;
  border-radius: 0.8rem;
  & a {
    color: ${colors.textmain};
  }
  @media (max-width: 1199px) {
    padding: 1rem 4.95rem;
    width: 100%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const SubtitleParagraph = styled(ParagraphBody)`
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const DisplayFlex = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 2.4rem;
`;
export const Icon = styled.img`
  width: 16rem;
  @media (max-width: ${SIZE.SMALL}) {
    width: 8rem;
  }
`;

export const Wrapper = styled(GridWrapper)`
  /* background-image: ${`url(${images.newCurvedLine})`};
  background-position: 90vw 50%;
  background-size: 1200px, 220px;
  background-repeat: no-repeat;
  @media (max-width: ${SIZE.MEDIUM}) {
    background-image: none;
  } */
`;
