import styled from 'styled-components';
import { A, Caption, colors, H4, ParagraphBody } from '../UI';

export const Card = styled.div``;
export const ImgContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 20.4rem;
  border-radius: 1.6rem;
  overflow: hidden;
  box-shadow: 0 16px 24px 8px rgba(9, 35, 60, 0.07);
`;
export const Menu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.4rem;
  border-radius: 1.6rem;
  inset: 0;
  z-index: 1;
  transition: background-color 0.3s ease-in;
  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
    & > p {
      opacity: 1;
      z-index: 1;
    }
  }
  & > p {
    opacity: 0;
    z-index: -1;
  }
`;
export const MenuItem = styled.p`
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 600;
  text-align: center;
  user-select: none;
  ${({ color }) =>
    color === colors.white
      ? `
    color: ${colors.textmain};
    border: 1px solid ${colors.textmain};
    `
      : `color: ${colors.bgdisabled};`}
  width: 24rem;
  padding: 0.8rem;
  border-radius: 0.8rem;
  ${({ color }) => color && `background-color: ${color};`}
  transition: opacity 0.3s ease-in;
`;

export const Tag = styled.p`
  margin: 0;
  position: absolute;
  top: 2.4rem;
  right: 2.4rem;
  font-family: 'Raleway', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: ${colors.bgdisabled};
  padding: 0.4rem 1.6rem;
  border-radius: 0.4rem;
  ${({ color }) => color && `background-color: ${color};`}
`;
export const CardImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const CardContainer = styled.div``;
export const CardTitle = styled(H4)`
  font-weight: 600;
`;
export const CardCategory = styled(Caption)`
  width: fit-content;
  padding: 0.2rem 1.6rem;
  border-radius: 0.4rem;
  color: ${colors.textcaption};
  border: 1px solid ${colors.placeholdertext};
  background-color: ${colors.bgdisabled};
`;
export const CardSubtitle = styled(Caption)`
  color: ${colors.textcaption};
  font-weight: normal;
`;
export const CardText = styled(ParagraphBody)``;
export const CardLink = styled(A)`
  color: ${colors.primaryyellow};
  font-weight: 600;
`;
