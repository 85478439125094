import logo from "./logo.svg";
import background from "./background.svg";
import clear from "./clear.svg";
import down from "./down.svg";
import downArrow from "./downArrow.svg";
import facebook from "./facebook.svg";
import fbFooter from "./fb-footer.svg";
import graphic from "./graphic.svg";
import inFooter from "./in-footer.svg";
import instagramFooter from "./instagram-footer.svg";
import instagram from "./instagram.svg";
import linkedin from "./linkedin.svg";
import loader from "./loader.svg";
import logoCowork from "./logo_cowork.svg";
import logoEnovatika from "./logo_enovatika.svg";
import logoFooter from "./logo_footer.svg";
import logoFooterTwo from "./logoFooter.svg";
import search from "./search.svg";
import shapeWithShadow from "./shape-with-shadow.svg";
import socialMedia3 from "./social-media-3.svg";
import socialMediaFb from "./social-media-fb.svg";
import socialMediaIn from "./social-media-in.svg";
import topRightShapeWithShadow from "./top-right-shape-with-shadow.svg";
import topleftBlurredShape from "./top-left_blured_shape.svg";
import bottomRightBlurredShape from "./bottom-right-blurred-shape.svg";
import upload from "./upload.svg";
import bannerbackground from "./banner-background.svg";
import aboutUsGraphic from "./about-us-graphic.svg";
import logoAdmin from "./logo-admin.svg";
import logoAdminWhite from "./logo-admin-white.svg";
import graphicAdmin from "./graphic-admin.svg";
import newBlur from "./new-blur.svg";
import newCircles from "./new-circles.svg";
import newGrayShape from "./new-gray-shape.svg";
import newPurpleShape from "./new-purple-shape.svg";
import newOrangeShape from "./new-orange-shape.svg";
import newSign from "./new-sign.svg";
import newCurvedLine from "./new-curved-line.svg";
import newBannerCommunity from "./new-banner-community.svg";
import newFooterBg from "./new-footer-bg.svg";
import newTwoShapes from "./new-two-shapes.svg";
import newRocket from "./new-rocket.png";
import newPeople from "./new-people.png";
import newRocketBg from "./new-rocket-bg.svg";
import newStartupsDots from "./new-startups-dots.svg";
import newStartupsOverlay from "./new-startups-overlay.svg";
import newPeopleBg from "./new-people-bg.svg";
import newStakeholdersOverlay from "./new-stakeholders-overlay.svg";
import newAboutGraphic from "./new-about-graphic.svg";
import newAboutBlurs from "./new-about-blurs.svg";
import newLines from "./new-lines.svg";
import newDots from "./new-dots.svg";
import newBannerBg from "./new-banner-background.png";
import newBg from "./new-background.png";
//new backgrounds
import v2LandingShapes from "./v2-shapes-landing.png";
import v2LandingColors from "./v2-colors-landing.svg";
import v2StartupsShapes from "./v2-shapes-startups.png";
import v2StartupsColors from "./v2-colors-startups.png";
import v2StakeholdersShapes from "./v2-shapes-stakeholders.png";
import v2StakeholdersColors from "./v2-colors-stakeholders.png";
import v2AboutShapes from "./v2-shapes-about.png";
import v2AboutColors from "./v2-colors-about.png";

import v2GetInTouchShapes from "./v2-colors-get-in-touch.png";
import v2GetInTouchColors from "./v2-shapes-get-in-touch.png";
import v2StakeholderColor from "./v2-colors-stakeholder.png";
import v2StakeholderShapes from "./v2-shapes-stakeholder.png";
import v2StartupColors from "./v2-colors-startup.png";
import v2StartupShapes from "./v2-shapes-startup.png";
import v2NewStartupColor from "./v2-colors-new-startup.png";
import v2NewStakeholderColor from "./v2-color-stakeholder-new.png";
//new shapes
import v2AboutGraphic from "./v2-about-graphic.svg";
import v2HeroGraphic from "./v2-hero-graphic.svg";
import graphicShapePink from "./graphic-shape-pink.svg";
import graphicShapeOrange from "./graphic-shape-orange.svg";
import graphicShapeSigns from "./graphic-shape-signs.svg";
import graphicBlur from "./graphic-blur-v2.svg";

import graphicAboutBlur from "./graphic-about-blur.svg";
import graphicAboutDots from "./graphic-about-dots.svg";
import graphicAboutOrange from "./graphic-about-orange.svg";
import graphicAboutRed from "./graphic-about-red.svg";
import graphicAboutSigns from "./graphic-about-signs.svg";

import sectionLandingShapes from "./section-landing-shapes.png";
import sectionLandingOverlay from "./section-landing-overlay.svg";
import sectionStartupShapes from "./section-startup-shapes.png";
import sectionStartupBg from "./section-startup-bg.png";
import userPlaceholder from "./user-placeholder.svg";

export const images = {
  logo,
  background,
  clear,
  down,
  downArrow,
  facebook,
  fbFooter,
  graphic,
  inFooter,
  instagramFooter,
  instagram,
  linkedin,
  loader,
  logoCowork,
  logoEnovatika,
  logoFooter,
  logoFooterTwo,
  search,
  shapeWithShadow,
  socialMedia3,
  socialMediaFb,
  socialMediaIn,
  topRightShapeWithShadow,
  topleftBlurredShape,
  bottomRightBlurredShape,
  upload,
  bannerbackground,
  aboutUsGraphic,
  logoAdmin,
  logoAdminWhite,
  graphicAdmin,
  newBlur,
  newCircles,
  newGrayShape,
  newPurpleShape,
  newOrangeShape,
  newSign,
  newCurvedLine,
  newBannerCommunity,
  newFooterBg,
  newTwoShapes,
  newRocket,
  newPeople,
  newRocketBg,
  newStartupsDots,
  newStartupsOverlay,
  newPeopleBg,
  newStakeholdersOverlay,
  newAboutGraphic,
  newAboutBlurs,
  newLines,
  newDots,
  newBannerBg,
  newBg,
  //new backgrounds
  v2LandingShapes,
  v2LandingColors,
  v2StartupsShapes,
  v2StartupsColors,
  v2StakeholdersShapes,
  v2StakeholdersColors,

  v2GetInTouchShapes,
  v2GetInTouchColors,
  v2StakeholderColor,
  v2StakeholderShapes,
  v2StartupColors,
  v2StartupShapes,
  v2NewStartupColor,
  v2NewStakeholderColor,
  //new shapes
  v2AboutShapes,
  v2AboutColors,
  v2AboutGraphic,
  v2HeroGraphic,
  graphicShapePink,
  graphicShapeOrange,
  graphicShapeSigns,
  graphicBlur,
  graphicAboutBlur,
  graphicAboutDots,
  graphicAboutOrange,
  graphicAboutRed,
  graphicAboutSigns,
  sectionLandingShapes,
  sectionLandingOverlay,
  sectionStartupShapes,
  sectionStartupBg,
  userPlaceholder,
};
