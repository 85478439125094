import React from 'react';
import { Container, Ring } from './Spinner.style';

export const Spinner = () => {
  return (
    <Container>
      <Ring>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </Ring>
    </Container>
  );
};
