import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { FormInp, FormInputLabel, SendBtn, ErrorMsg } from './Forms.style';
import { api } from '../../api/api';
import { Cell, Spacer, H5, colors, GridWrapper, Grid, Dropdown } from '../UI';
import { ImageUpload } from './ImageUpload/ImageUpload';
import { postSchema } from '../validation';
import { POSTERRORS, CATEGORIES, STATUSES, RESOURCE } from '../../consts';
import { Content } from '../ResourceDetails/ResourceDetails.style';
// editor
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';

const mdParser = new MarkdownIt(/* Markdown-it options */);

export const AdminFormResource = ({ data }) => {
  const history = useHistory();
  const [post, setPost] = useState(data ? data : RESOURCE);
  const [errors, setErrors] = useState({ ...POSTERRORS });
  const [disabled, setDisabled] = useState(false);
  const [sent, setSent] = useState(false);

  //SCROLL INTO VIEW
  const scrollToErrors = () => {
    for (let key of Object.keys(errors)) {
      if (key !== 'status' && errors[key] !== '') {
        document
          .querySelector(`[name="${key}"]`)
          ?.parentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        break;
      }
    }
  };
  useEffect(() => {
    if (!disabled) scrollToErrors();
    // eslint-disable-next-line
  }, [disabled]);

  const handleChangeForm = (key, value) => {
    setPost(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleEditorChange = ({ text }, evt) => {
    setPost(prevState => ({
      ...prevState,
      content: text,
    }));
  };

  //errors
  const setError = (key, value) => {
    setErrors(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };
  const clearErrors = () => {
    setErrors({
      ...POSTERRORS,
    });
  };

  const onSubmit = async () => {
    setDisabled(true);
    clearErrors();
    //form validation messages
    Object.keys(post).forEach(async key => {
      if (postSchema[key] !== undefined) {
        postSchema[key].validate(post[key]).catch(err => {
          setError(key, err.message);
        });
      }
    });
    //form validation
    let isFormValid = true;
    for (let key of Object.keys(post))
      if (isFormValid && postSchema[key] !== undefined) {
        isFormValid = await postSchema[key].isValid(post[key]);
      }
    if (!isFormValid) {
      setDisabled(false);
      return;
    }

    //add/edit post
    if (data) await api.updatePost({ ...post, publishStatus: post.publishStatus === STATUSES[1] });
    else await api.createPost({ ...post, publishStatus: post.publishStatus === STATUSES[1] });
    setSent(true);
    setTimeout(() => {
      setDisabled(false);
      setSent(false);
      if (!data) setPost({ ...RESOURCE });
      else history.replace(`/admin/resources/${post.title.replace(/\d|\W/gm, '').toLowerCase()}`);
      window.scrollTo(0, 1);
    }, 2000);
  };

  return (
    <>
      <GridWrapper>
        <Grid>
          <Cell lg={{ colSpan: 3, offset: 1 }} md={{ colSpan: 6 }}>
            <FormInputLabel For="title">Article title*</FormInputLabel>
            <FormInp
              id="title"
              placeholder={'Type here'}
              name="title"
              type="text"
              value={post.title}
              onChange={e => handleChangeForm(e.target.name, e.target.value)}
            />
            <ErrorMsg>{errors.title}</ErrorMsg>
            <Spacer lgheight={'1.6rem'} />
          </Cell>
          <Cell lg={{ colSpan: 3 }} md={{ colSpan: 6 }}>
            <FormInputLabel For="author">Author*</FormInputLabel>
            <FormInp
              id="author"
              placeholder={'Type here'}
              name="author"
              type="text"
              value={post.author}
              onChange={e => handleChangeForm(e.target.name, e.target.value)}
            />
            <ErrorMsg>{errors.author}</ErrorMsg>
            <Spacer lgheight={'1.6rem'} />
          </Cell>

          <Cell lg={{ colSpan: 5, offset: 8, rowSpan: 1 }} md={{ colSpan: 12 }}>
            <ImageUpload
              image={post.featuredImage}
              onSetImage={image => handleChangeForm('featuredImage', image)}
            />
            <Spacer lgheight={'0.8rem'} />
            <ErrorMsg>{errors.featuredImage}</ErrorMsg>
          </Cell>
          {/* PUBLISH STATE */}
          <Cell lg={{ colSpan: 3, offset: 1 }} md={{ colSpan: 6, offset: 1 }} style={{ zIndex: 5 }}>
            <Spacer lgheight={'1.6rem'} />
            <Dropdown
              label="Publish status"
              name="publishStatus"
              selected={post.publishStatus}
              values={STATUSES}
              handleOnChange={handleChangeForm}
            />
            <ErrorMsg>{errors.publishStatus}</ErrorMsg>
          </Cell>
          {/* CATEGORY */}
          <Cell lg={{ colSpan: 3 }} md={{ colSpan: 6 }} style={{ zIndex: 4 }}>
            <Spacer lgheight={'1.6rem'} />
            <Dropdown
              label="Category"
              name="category"
              selected={post.category}
              values={CATEGORIES}
              handleOnChange={handleChangeForm}
            />
            <ErrorMsg>{errors.category}</ErrorMsg>
            <Spacer lgheight={'12.6rem'} />
          </Cell>
        </Grid>
      </GridWrapper>
      <GridWrapper style={{ zIndex: 100 }}>
        <Grid>
          {/* EDITOR */}
          <Cell lg={{ colSpan: 12 }} md={{ colSpan: 12 }} style={{ zIndex: 7 }}>
            <H5>Article Content</H5>
            <Spacer lgheight={'1.2rem'} />
            <Content>
              <MdEditor
                htmlClass="Editor"
                value={post.content}
                style={{ height: '500px' }}
                renderHTML={text => mdParser.render(text)}
                onChange={handleEditorChange}
              />
            </Content>
          </Cell>
          {/* SUBMIT BUTTON */}
          <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 6, offset: 1 }}>
            <Spacer lgheight={'2.4rem'} />
            <SendBtn onClick={onSubmit} disabled={disabled}>
              {data ? 'Update article' : 'Create new article'}
            </SendBtn>
            <Spacer lgheight={'2.4rem'} />
            {sent && (
              <H5 color={colors.success}>
                {data ? 'Article information updated!' : 'Article created!'}
              </H5>
            )}
            <Spacer lgheight={'9.6rem'} mdheight={'4.8rem'} />
          </Cell>
        </Grid>
      </GridWrapper>
    </>
  );
};
