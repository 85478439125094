import styled from 'styled-components';
import { colors } from '../../../consts/colors';

export const Label = styled.label`
  display: block;
  font-size: 1.2rem;
  line-height: 2rem;
  color: ${colors.jetblack};
  width: 100%;
  margin-bottom: 4px;
  font-weight: 600;
`;

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const SelectedWrapper = styled.div`
  position: relative;
`;

export const ImgElement = styled.img`
  cursor: pointer;
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  height: 2.4rem;
  width: 2.4rem;
`;

export const SelectedOption = styled.div`
  cursor: pointer;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${colors.textmain};
  background-color: #ffffff;
  border: 1px solid ${({ isDefault }) => (isDefault ? '#b0bfc7' : colors.primaryyellow)};
  padding: 8px 16px;
  border-radius: 8px;
  overflow: hidden;
`;

export const List = styled.ul`
  position: absolute;
  background-color: #ffffff;
  border-radius: 8px;
  top: 100%;
  left: 0;
  right: 0;
  padding: 8px 0;
  box-shadow: 0 16px 8px rgba(9, 35, 60, 0.07);
  overflow: hidden;
  display: ${({ open }) => (open ? 'block' : 'none')};
  z-index: 10;
  transition: max-height 0.6s;
`;

export const ListItem = styled.li`
  padding: 8px 16px;
  cursor: pointer;
  font-size: 1.6rem;
  line-height: 2.4rem;
  background-color: #ffffff;
  &:hover {
    background-color: ${colors.primaryyellow};
  }
`;
