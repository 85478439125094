import styled from 'styled-components';
import { ParagraphBody } from '../Typography';
import { colors } from '../../../consts/colors';

export const Container = styled.div`
  display: inline-flex;
  font-size: ${({ size }) => (size === 'large' ? 20 : 16)}px;
  padding: ${({ size }) => (size === 'large' ? '16px 48px' : '8px 24px')};
  color: ${colors.jetblack};
  border: 1px solid ${({ type }) => (type === 'primary' ? colors.primaryyellow : colors.jetblack)};
  background-color: ${({ type }) => (type === 'primary' ? colors.primaryyellow : 'transparent')};
  border-radius: 8px;
  color: ${colors.jetblack};
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Label = styled.span`
  display: block;
  text-align: center;
  margin: 0;
  color: ${colors.jetblack};
  font-weight: 600;
`;

export const BtnSecondary = styled.div`
  cursor: pointer;
  display: inline-block;
  width: 100%;
  min-width: 3.8rem;
  background-color: transparent;
  border: 2px solid ${colors.textmain};
  padding: 1.4rem 0rem;
  height: auto;
  font-size: 2rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  line-height: 3.2rem;
  text-align: center;
  border-radius: 0.8rem;
  & a {
    color: ${colors.textmain};
  }
`;

export const BtnPrimary = styled.div`
  display: inline-block;
  min-width: 3.8rem;
  width: 100%;
  max-width: 50rem;
  padding: 1.6rem 0rem;
  height: auto;
  font-weight: 600;
  font-size: 2rem;
  font-family: 'Montserrat', sans-serif;
  line-height: 3.2rem;
  text-align: center;
  border-radius: 0.8rem;
  ${({ disabled }) =>
    disabled
      ? `background-color: ${colors.placeholdertext};
      color: ${colors.bgdisabled};
      cursor: not-allowed;`
      : `background-color: ${colors.primaryyellow};
      color: ${colors.textmain};
      cursor: pointer;`};
  @media (max-width: 1199px) {
    padding: 1.6rem 2.75rem;
  }
  @media (max-width: 767px) {
    padding: 1.6rem 2.75rem;
  }
`;

export const BtnLogin = styled(ParagraphBody)`
  min-width: 3.8rem;
  width: 100%;
  margin: 0;
  line-height: 4rem;
  font-weight: 600;
  text-align: center;
  border-radius: 0.8rem;
  ${({ disabled }) =>
    disabled
      ? `background-color: ${colors.placeholdertext};
      color: ${colors.bgdisabled};
      cursor: not-allowed;`
      : `background-color: ${colors.primaryyellow};
      color: ${colors.textmain};
      cursor: pointer;`};
`;
