import styled, { css } from 'styled-components';
import { SIZE } from '../../../consts';
import { colors, H4, ParagraphBody } from '../../UI';

export const Categories = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  @media (max-width: ${SIZE.MEDIUM}) {
    gap: 1rem;
    justify-content: space-between;
  }
  @media (max-width: ${SIZE.SMALL}) {
    flex-direction: column;
  }
`;
export const Category = styled(ParagraphBody)`
  cursor: pointer;
  width: fit-content;
  user-select: none;
  padding: 0.8rem 2.4rem;
  border: 1px solid ${colors.textcaption};
  border-radius: 2rem;
  background-color: ${colors.bgdisabled};
  ${({ active }) =>
    active &&
    css`
      cursor: default;
      color: ${colors.bgdisabled};
      background-color: ${colors.textmain};
    `};
  @media (max-width: ${SIZE.SMALL}) {
    width: 100%;
    text-align: center;
  }
`;

export const Button = styled(H4)`
  cursor: pointer;
  padding: 1.6rem;
  border-radius: 0.8rem;
  background-color: ${colors.primaryyellow};
  text-align: center;
  font-weight: 600;
`;
