import React, { useState, useEffect } from 'react';
import { Layout, NotFound } from '../components';
import { Helmet } from 'react-helmet-async';
import { Loading } from '../components/UI';

export const Page404 = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <Layout>
      <Helmet>
        <title>404 | Timisoara Startups</title>
      </Helmet>
      <Loading isLoading={loading} />
      <NotFound />
    </Layout>
  );
};
