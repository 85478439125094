import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { api } from '../api';
import { Hero, NewLayout } from '../components';
import { Stakeholders, Startups, GetInTouch } from '../components';
import { Loading } from '../components/UI';

export const Home = () => {
  const [data, setData] = useState({
    startups: [],
    stakeholders: [],
  });
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    let tempData = {};
    try {
      const startups = await api.getAllStartups();
      tempData = {
        ...tempData,
        startups: startups.filter(startup => startup.publish_status === true),
      };
      const stakeholders = await api.getAllStakeholders();
      tempData = {
        ...tempData,
        stakeholders: stakeholders.filter(stakeholder => stakeholder.publish_status === true),
      };
    } catch (error) {
      console.log(error);
    }
    setData({ ...tempData });
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <NewLayout bg="landing">
      <Helmet>
        <title>Homepage | Timisoara Startups</title>
      </Helmet>
      <Loading isLoading={loading} />
      <Hero />
      <Startups isHomepage startupsList={data.startups} />
      <GetInTouch />
      <Stakeholders isHomepage stakeholdersList={data.stakeholders} />
    </NewLayout>
  );
};
