import styled from 'styled-components';
import { colors } from '../../../consts/colors';

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const Label = styled.label`
  display: block;
  font-size: 1.2rem;
  line-height: 2rem;
  color: ${colors.textmain};
  width: 100%;
  margin-bottom: 4px;
  font-weight: 600;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const ImgElement = styled.img`
  position: absolute;
  top: 1.2rem;
  right: 0.8rem;
  left: 0.8rem;
  height: 1.6rem;
  width: 1.6rem;
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  font-size: 1.6rem;
  line-height: 2.4rem;
  border: 1px solid ${colors.placeholdertext};
  color: ${colors.textmain};
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 2px;
  ${({ image }) => (image ? 'padding-left: 3rem;' : 'padding-left: 8px;')}
  &::placeholder {
    color: ${colors.placeholdertext};
  }
  &:focus {
    border: 1px solid ${colors.primaryyellow};
  }
`;

export const Error = styled.span`
  display: block;
  width: 100%;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${colors.error};
`;
