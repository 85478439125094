import styled from "styled-components";
import { colors } from "../../../consts/colors";

export const UploadFile = styled.label`
  width: 100%;
  max-width: 32rem;
  border: 3px dotted ${colors.placeholdertext};
  border-radius: 0.8rem;
  padding: 2.4rem 2.4rem;
  cursor: pointer;
  font-family: "Raleway", sans-serif;
  color: ${colors.textmain};
  font-size: 1.6rem;
  line-height: 2.4rem;
  display: inline-block;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: space-between;
  transition: all 0.3s;
  :hover {
    /* background-color: ${colors.lightGreen}; */
    background-color: rgba(44, 196, 112, 0.1);
  }

  @media (max-width: 1199px) {
    margin-bottom: 5rem;
  }
`;

export const UploadPhoto = styled.input`
  display: none;
`;

export const ContainerDragFile = styled.div`
  align-items: center;
  margin-top: 0.8rem;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  margin: auto;
  width: 16rem;
  line-height: 2.4rem;
  display: block;
  margin-top: 0.2rem;
`;

export const BrowseBtn = styled.span`
  padding: 0.7rem 4rem;
  color: ${colors.textmain};
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 2.4rem;
  text-align: center;
  border: 1px solid #09233c;
  border-radius: 0.8rem;
  display: block;
`;

export const ContainerBtn = styled.div`
  align-items: center;
  margin-top: 3.2rem;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
`;

export const ColumnDisplay = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
`;

export const ImgTop = styled.img`
  position: contain;
  margin: auto;
  height: 6.4rem;
  width: 6.4rem;
  padding: 0.9rem;
`;
