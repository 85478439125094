import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SIZE } from '../consts';
import { colors } from '../consts/colors';
import { images } from '../public';
import { ParagraphBody } from './common/common.style';

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  ${({ fluid }) =>
    `${fluid ? `max-width: 100%` : `max-width: 120rem`};
        padding-left: 0rem;
        padding-right: 0rem;
        @media (max-width: 1199px) {
          ${fluid ? `max-width: 100%` : `max-width: 64rem`};
        }
        @media (max-width: 767px) {
          ${fluid ? `max-width: 100%` : `max-width: 33.5rem`};
        }
        @media (min-width: 1200) {
          max-width: 100%;
        }
    `};
`;

export const CardImg = styled.img`
  cursor: pointer;
  display: inline;
  object-fit: contain;
  display: inline-block;
  @media (max-width: 1199px) {
    display: inline;
    object-fit: contain;
  }
  @media (max-width: 767px) {
    display: inline;
    object-fit: contain;
  }
`;
export const FooterLinks = styled(Link)`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 2rem;
  line-height: 3.2rem;
  margin-top: 0rem;
  display: block;
  padding: 0rem 0rem;
  min-width: 10rem;
  ${({ lgmarginbottom }) => lgmarginbottom && `margin-bottom: ${lgmarginbottom}`};
  transition: color 0.3s;
  &:hover {
    color: ${colors.primaryyellow};
  }
  @media (max-width: 1199px) {
    display: block;
  }
  @media (max-width: 767px) {
    display: block;
    text-align: center;
  }
`;

export const FooterContact = styled(Link)`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: ${({ color }) => (color ? color : colors.primaryyellow)};
  font-size: 2rem;
  line-height: 3.2rem;
  margin-top: 0rem;
  display: inline-block;
  padding: 0rem 0rem;
  margin-bottom: 1.6rem;
  min-width: 10rem;
  @media (max-width: 1199px) {
    display: block;
    margin-bottom: 1.6rem;
  }
  @media (max-width: 767px) {
    display: block;
    margin-bottom: 1.6rem;
    text-align: center;
  }
`;

export const FooterImg = styled.img`
  object-fit: contain;
  display: inline-block;
  cursor: pointer;
`;

export const FooterSMlogo = styled.img`
  padding-right: 1.8rem;
  display: inline-block;
  @media (max-width: 1199px) {
    margin-bottom: 1.6rem;
  }
`;
export const SMlogo = styled.img`
  object-fit: contain;
  display: inline-block;
  cursor: pointer;
  @media (max-width: 1199px) {
    display: inline-block;
    margin-bottom: 1.6rem;
  }
  @media (max-width: 767px) {
  }
`;
export const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
`;

export const FooterTitleList = styled.p`
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  margin-top: 0rem;
  margin-bottom: 1.7rem;
  color: ${({ color }) => (color ? color : colors.textcaption)};
  font-size: 1.2rem;
  line-height: 2rem;
  display: block;

  ${({ align }) => align && `text-align: ${align}`};
  @media (max-width: 1199px) {
    display: block;
    margin-bottom: 1.6rem;
  }
  @media (max-width: 767px) {
    display: block;
    margin-bottom: 1.6rem;
    text-align: center;
  }
`;

export const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
`;

export const FooterLogoDisplay = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const TextFooter = styled(ParagraphBody)`
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: 'Raleway', sans-serif;
  font-weight: normal;
  padding-left: 3rem;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 1.6rem;
  line-height: 2.4rem;
  ${({ lgTop }) => lgTop && `margin-top: ${lgTop}`};
  ${({ lgBottom }) => lgBottom && `margin-bottom: ${lgBottom}`};
  ${({ align }) => align && `text-align: ${align}`};

  @media (max-width: 1199px) {
  }

  @media (max-width: 767px) {
  }
`;
export const CopyrightFooter = styled(ParagraphBody)`
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: 'Raleway', sans-serif;
  font-weight: normal;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 1.6rem;
  line-height: 2.4rem;
  ${({ lgTop }) => lgTop && `margin-top: ${lgTop}`};
  ${({ lgBottom }) => lgBottom && `margin-bottom: ${lgBottom}`};
  ${({ align }) => align && `text-align: ${align}`};

  @media (max-width: 1199px) {
  }

  @media (max-width: 767px) {
  }
`;

export const CardImgSM = styled.div`
  display: inline-block;
  border-radius: 50%;
  width: 100%;
  height: 4.8rem;
  background-color: #ffffff;
`;

export const DisplayIpadIphone = styled.div`
  display: none;
  @media (max-width: 1199px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;
    align-content: space-between;
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: space-between;
  }
`;
export const Display = styled.div`
  @media (max-width: 1199px) {
    display: none;
  }
  @media (max-width: 767px) {
  }
`;
export const BluredShape = styled.div`
  position: absolute;
  width: 100%;
  height: 1271px;
  bottom: 10.4rem;
  top: -85rem;
  right: -60rem;
  overflow-x: hidden;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: ${`url(${images.newFooterBg})`};
  /* background-image: ${`url(${require('../public/top-left_blured_shape.svg')})`}; */

  @media (max-width: 1199px) {
    top: 3%;
    right: 0rem;
    overflow-x: hidden;
    background-repeat: no-repeat;
    background-size: contain;
    width: 80%;
  }
  @media (max-width: 767px) {
    top: 5rem;
    right: 0rem;
    height: 60%;
    width: 60%;
  }
`;

export const BackgroundShape = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  top: -50%;
  left: 0;
  right: 0;
  z-index: -1;
  background-position: bottom;
  background-size: 100vw;
  background-repeat: no-repeat;
  background-image: ${`url(${images.newFooterBg})`};
  @media (max-width: ${SIZE.SMALL}) {
    background-image: none;
  }
`;
