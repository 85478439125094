import React from 'react';
import { Link } from 'react-router-dom';
import { Spacer } from '../UI';
import {
  Card,
  ImgContainer,
  CardImg,
  CardContainer,
  TitleCard,
  Subtitle,
  SmLogoDisplay,
  SmLogo,
} from './CardStakeholder.style';
import { images } from '../../public';

export const CardStakeholder = ({ link, image, name, role, linkedin, facebook, instagram }) => {
  return (
    <Link to={link}>
      <Card>
        <ImgContainer>
          <CardImg src={image} alt="stakeholder avatar" />
        </ImgContainer>
        <CardContainer>
          <Spacer lgheight={'2.4rem'} />
          <TitleCard>{name}</TitleCard>
          <Spacer lgheight={'0.8rem'} />
          <Subtitle>
            {role.map(role => (
              <span key={role} style={{ display: 'block' }}>
                {role}
              </span>
            ))}
          </Subtitle>
          <Spacer lgheight={'2.4rem'} />
          <SmLogoDisplay>
            {linkedin && <SmLogo src={images.linkedin} alt="linkedin logo" />}
            {facebook && <SmLogo src={images.facebook} alt="facebook logo" />}
            {instagram && <SmLogo src={images.instagram} alt="instagram logo" />}
          </SmLogoDisplay>
        </CardContainer>
      </Card>
    </Link>
  );
};
