import styled from 'styled-components/macro';
import { SIZE } from '../consts';
import { images } from '../public';

export const PageWrapper = styled.div`
  min-height: 100vh;
  position: relative;
`;

export const PageContent = styled.div`
  margin-top: 8rem;
  min-height: calc(100vh - 578px);
  overflow-x: hidden;
`;

export const BluredShapeTopLeft = styled.div`
  position: absolute;
  width: 100%;
  top: -35%;
  right: 15%;
  overflow-x: hidden;
  background-repeat: no-repeat;
  background-size: contain;
  height: 1500px;
  background-image: ${`url(${images.topleftBlurredShape})`};
  @media (max-width: ${SIZE.MEDIUM}) {
    background-image: none;
  }
`;

export const Background = styled.div`
  position: absolute;
  inset: 0;
  top: 0;
  z-index: -1;
  overflow: hidden;
  width: 100vw;
  margin: auto;
  & img {
    position: absolute;
    inset: 0;
    width: 100vw;
    @media (max-width: ${SIZE.SMALL}) {
      &#shapes,
      &.mobile-hide {
        display: none;
      }
    }
  }
  @media (max-width: ${SIZE.SMALL}) {
    top: 1.2rem;
    overflow: visible;
    /* display: none; */
  }
`;

export const ShapesLanding = styled.div`
  position: absolute;
  width: 100%;
  background-image: ${`url(${images.v2LandingShapes})`}, ${`url(${images.v2LandingColors})`};
  background-position: 0px 0px, 0px 0px;
  background-size: 800px, 1500px;
  background-repeat: repeat-y;
  @media (max-width: ${SIZE.SMALL}) {
    background-image: none;
  }
`;

export const ColorsLanding = styled.div``;

//### DELETE
export const LandingImages = styled.div`
  z-index: 10;
  height: 1600px;
  background-image: ${`url(${images.newBlur})`}, ${`url(${images.newCircles})`};
  background-position: 0px 10px, -750px 50px;
  background-size: 800px, 1500px;
  background-repeat: no-repeat;
  @media (max-width: ${SIZE.SMALL}) {
    background-image: none;
  }
`;
export const LandingDots = styled.div`
  z-index: 5;
  margin-top: -1400px;
  height: 100vw;
  background-image: ${`url(${images.newGrayShape})`};
  background-position: top;
  background-size: 140vw;
  opacity: 2.5%;
  background-repeat: no-repeat;
  @media (max-width: ${SIZE.MEDIUM}) {
    background-image: none;
  }
`;

export const StartupsImages = styled.div`
  z-index: 10;
  height: 1600px;
  background-image: ${`url(${images.newRocketBg})`}, ${`url(${images.newStartupsOverlay})`};
  background-position: 0px 0px, 950px 600px;
  background-size: 800px, 700px;
  background-repeat: no-repeat;
  @media (max-width: ${SIZE.SMALL}) {
    background-image: none;
  }
`;

export const StartupsDots = styled.div`
  z-index: 5;
  margin-top: 200px;
  height: 130vw;
  background-image: ${`url(${images.newStartupsDots})`};
  background-position: center;
  background-size: auto 150vw;
  background-repeat: no-repeat;
  @media (max-width: ${SIZE.MEDIUM}) {
    background-image: none;
  }
`;

export const StakeholdersImages = styled.div`
  z-index: 10;
  height: 1600px;
  background-image: ${`url(${images.newPeopleBg})`}, ${`url(${images.newStakeholdersOverlay})`};
  background-position: 50vw 0px, bottom;
  background-size: 800px, 1600px;
  background-repeat: no-repeat;
  @media (max-width: ${SIZE.SMALL}) {
    background-image: none;
  }
`;
export const AboutImages = styled.div`
  z-index: 10;
  height: 1000px;
  background-image: ${`url(${images.newAboutGraphic})`}, ${`url(${images.newDots})`},
    ${`url(${images.newAboutBlurs})`};
  background-position: 200px 200px, -500px 600px, -320px -150px;
  background-size: 600px, auto, 1374px;
  background-repeat: no-repeat;
  @media (max-width: ${SIZE.LARGE}) {
    background-image: none;
  }
`;
