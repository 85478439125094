import React from 'react';
import { Spacer } from '../UI';
import {
  Card,
  CardContainer,
  CardImg,
  CardLink,
  CardSubtitle,
  CardText,
  CardTitle,
  ImgContainer,
} from './CardBlogPost.style';

export const CardBlogPost = ({ img, title, subtitle, text, link }) => {
  return (
    <Card>
      <ImgContainer as="a" href={link}>
        <CardImg src={img} />
      </ImgContainer>
      <CardContainer>
        <Spacer lgheight="1.6rem" />
        <CardSubtitle>{subtitle}</CardSubtitle>
        <Spacer lgheight="0.8rem" />
        <CardTitle>{title}</CardTitle>
        <Spacer lgheight="0.8rem" />
        <CardText>{text}</CardText>
        <Spacer lgheight="1.6rem" />
        <CardLink href={link}>Read more</CardLink>
      </CardContainer>
    </Card>
  );
};
