import React from 'react';
import { Container, Label } from './Button.style';

export const Button = ({ type, label, size, handleButtonClicked }) => {
  return (
    <Container type={type} size={size} onClick={handleButtonClicked}>
      <Label>{label}</Label>
    </Container>
  );
};
