import { useHistory } from 'react-router-dom';
import { StakeholderDetail, NewLayout } from '../components';
import { useState, useEffect } from 'react';
import { api } from '../api/api';
import { Helmet } from 'react-helmet-async';
import { Loading } from '../components/UI';

export const StakeholderDetailPage = ({ match }) => {
  const history = useHistory();
  const [pageData, setPageData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const { id } = match.params;

  useEffect(() => {
    async function fetchData() {
      const data = await api.getSingleStakeholder(id);
      if (data) {
        setPageData(data);
        setLoading(false);
      } else history.replace('/not-found');
    }
    fetchData();
    // eslint-disable-next-line
  }, [id]);

  return (
    <NewLayout bg="stakeholder">
      <Helmet>
        <title>Community | Timisoara Startups</title>
      </Helmet>
      <Loading isLoading={loading} />
      {pageData && <StakeholderDetail pageData={pageData} />}
    </NewLayout>
  );
};
