import React, { useState, useRef } from 'react';
import { Container, SelectedOption, List, ListItem, Label, SelectedWrapper, ImgElement } from './Dropdown.style';
import { useClickedOutsideObserver } from '../../../hooks';
import { images } from '../../../public';

export const Dropdown = ({ values, handleOnChange, label, selected, name }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(values[0]);
  const ref = useRef(null);

  const handleSelectOption = value => {
    setIsOpen(false);
    setValue(value);
    handleOnChange(name, value);
  };

  const handleOpenDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCloseDropdown = () => {
    setIsOpen(false);
  };

  useClickedOutsideObserver(ref, handleCloseDropdown);

  return (
    <Container ref={ref}>
      {label && <Label>{label}</Label>}
      <SelectedWrapper>
        <ImgElement onClick={handleOpenDropdown} src={images.down} />
        <SelectedOption onClick={handleOpenDropdown} isDefault={() => values[0] === value}>
          {selected}
        </SelectedOption>
      </SelectedWrapper>
      <List open={isOpen}>
        {values.map(value => (
          <ListItem onClick={() => handleSelectOption(value)} key={value}>
            {value}
          </ListItem>
        ))}
      </List>
    </Container>
  );
};
