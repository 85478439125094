import styled from 'styled-components';
import { colors } from '../../consts/colors';
import { images } from '../../public';
import { H1, H3, ParagraphBody, ParagraphCaption } from '../common/common.style';

export const BtnConnect = styled.div`
  min-width: 38rem;
  width: 100%;
  cursor: pointer;
  display: inline-block;
  background-color: ${colors.textmain};
  padding: 1.6rem 5.5rem;
  font-weight: 600;
  font-size: 2rem;
  font-family: 'Montserrat', sans-serif;
  line-height: 3.2rem;
  text-align: center;
  border-radius: 0.8rem;
  color: white;
  & a {
    color: white;
  }
  @media (max-width: 1199px) {
  }
  @media (max-width: 767px) {
    min-width: 32rem;
  }
`;

export const BannerSection = styled.div`
  width: 100%;
  background-image: ${`url(${images.newBg})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-left: 0;
  padding-right: 0;
`;

export const H1Banner = styled(H1)`
  color: ${colors.bgdisabled};
`;
export const TitleBanner = styled(H3)`
  color: ${colors.bgdisabled};
`;
export const ParagraphBodyBanner = styled(ParagraphBody)`
  color: ${colors.bgdisabled};
  font-family: 'Raleway';
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
`;

export const BackgroundImgRight = styled.div`
  position: absolute;
  width: 100%;
  top: -10%;
  right: -18%;
  overflow-x: hidden;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  background-size: contain;
  height: 40rem;
  background-image: ${`url(${require('../../public/graphic.svg')})`};

  @media (max-width: 1199px) {
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100%;
  }
  @media (max-width: 767px) {
    min-height: 100%;
    background-position: initial;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

export const CardStartup = styled.div`
  cursor: pointer;
  width: 100%;
  min-height: 38rem;
  border-radius: 1.6rem;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 2px rgba(9, 35, 60, 0.05);
  transition: all 0.3s;
  :hover {
    box-shadow: 0 16px 24px 8px rgba(9, 35, 60, 0.07);
  }
`;

export const ImgContainer = styled.div`
  height: 14.8rem;
  width: 14.8rem;
  display: inline-block;
  margin: 4.8rem 0rem 2.4rem 1.6rem;
`;
export const CardImg = styled.img`
  height: auto;
  width: 100%;
  border-radius: 0.8rem;
  display: block;
`;

export const CardContainer = styled.div`
  padding: 0.2rem 1.6rem;
`;

export const SubtitleParagraph = styled(ParagraphBody)`
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const TextCard = styled(ParagraphCaption)`
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: 'Raleway', sans-serif;
  color: ${({ color }) => (color ? color : colors.textcaption)};
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const Background = styled.div`
  width: 100%;
  padding-left: 0rem;
  padding-right: 0rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  background-image: ${`url(${images.sectionStartupBg})`};
`;
