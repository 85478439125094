import styled from 'styled-components';
import { images } from '../../../public';
import { H1, ParagraphBody, colors } from '../../UI';

export const BtnConnect = styled.div`
  cursor: pointer;
  display: inline-block;
  background-color: ${colors.primaryyellow};
  padding: 1.6rem 5.5rem;
  width: 100%;
  max-width: 50rem;
  height: auto;
  font-weight: 600;
  font-size: 2rem;
  font-family: 'Montserrat', sans-serif;
  line-height: 3.2rem;
  text-align: center;
  border-radius: 0.8rem;
  color: ${colors.textmain};
  & a {
    color: ${colors.textmain};
  }
  @media (max-width: 1199px) {
  }
  @media (max-width: 767px) {
  }
`;

export const BannerSection = styled.div`
  width: 100%;
  padding-left: 0rem;
  padding-right: 0rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${`url(${images.newBannerBg})`};
  @media (max-width: 1249px) {
    background-position: 0% 0%;
  }
`;

export const H1Banner = styled(H1)`
  font-family: 'Montserrat', sans-serif;
  font-size: 4.2rem;
  line-height: 5rem;
  font-weight: bold;
  color: ${colors.bgdisabled};
  ${({ half }) => half && `width: 50%`};
  ${({ align }) => align && `text-align: ${align}`};
`;

export const ParagraphBodyBanner = styled(ParagraphBody)`
  color: ${colors.bgdisabled};
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  position: relative;
`;

export const Background = styled.div`
  width: 100%;
  padding-left: 0rem;
  padding-right: 0rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  background-image: ${`url(${images.sectionStartupShapes})`};
`;
