import { useHistory } from 'react-router-dom';
import { StartupDetail, BannerStartup, NewLayout } from '../components';
import { useState, useEffect } from 'react';
import { api } from '../api/api';
import { Helmet } from 'react-helmet-async';
import { Loading } from '../components/UI';

export const StartupDetailPage = ({ match }) => {
  const history = useHistory();
  const [pageData, setPageData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const { id } = match.params;

  const fetchData = async () => {
    const startup = await api.getSingleStartup(id);
    if (startup) {
      setPageData(startup);
      setLoading(false);
    } else history.replace('/not-found');
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <NewLayout bg="startup">
      <Helmet>
        <title>Startups | Timisoara Startups</title>
      </Helmet>
      <Loading isLoading={loading} />
      {pageData && (
        <>
          <StartupDetail pageData={pageData} />
          <BannerStartup pageData={pageData} />
        </>
      )}
    </NewLayout>
  );
};
