import React from 'react';
import { Navigation } from '../Navigation/Navigation';
import { PageContent } from '../../Layout.style';

export const LayoutAdmin = ({ children }) => {
  return (
    <>
      <Navigation />
      <PageContent>{children}</PageContent>
    </>
  );
};
