import React, { useState, useEffect } from 'react';
import { colors } from '../../consts/colors';
import { Spacer, Spinner } from '../UI';
import {
  Card,
  ImgContainer,
  CardImg,
  CardContainer,
  TitleCard,
  Subtitle,
  TextCard,
  Tag,
  Menu,
  MenuItem,
} from './CardStartup.style';

export const AdminCardStartup = ({
  image,
  title,
  funding,
  industry,
  published,
  onPublish,
  onEdit,
  onDelete,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [image, title, funding, industry, published, onPublish, onEdit, onDelete]);
  return (
    <Card>
      <Menu>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {published ? (
              <MenuItem
                color={colors.white}
                onClick={() => {
                  setLoading(true);
                  onPublish();
                }}
              >
                UNPUBLISH
              </MenuItem>
            ) : (
              <MenuItem
                color={colors.success}
                onClick={() => {
                  setLoading(true);
                  onPublish();
                }}
              >
                PUBLISH
              </MenuItem>
            )}
            <MenuItem color={colors.error} onClick={onEdit}>
              EDIT
            </MenuItem>
            <MenuItem
              color={colors.white}
              onClick={() => {
                setLoading(true);
                onDelete();
              }}
            >
              DELETE
            </MenuItem>
          </>
        )}
      </Menu>
      <Tag color={published ? colors.success : colors.error}>
        {published ? 'PUBLISHED' : 'PENDING'}
      </Tag>
      <ImgContainer>
        <CardImg src={image} alt="avatar" />
      </ImgContainer>
      <CardContainer>
        <Spacer lgheight={'1.6rem'} />
        <TitleCard>{title}</TitleCard>
        <Spacer lgheight={'0.8rem'} />
        <Subtitle>{funding}</Subtitle>
        <Spacer lgheight={'2.4rem'} />
        <TextCard>{industry}</TextCard>
      </CardContainer>
    </Card>
  );
};
