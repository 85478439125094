import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Layout, ResourceDetails } from "../components";
import { Loading } from "../components/UI";
import { api } from "../api";

export const ResourceDetailPage = ({ match }) => {
  const history = useHistory();
  const [pageData, setPageData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const { id } = match.params;

  const fetchData = async () => {
    const data = await api.getSingleResource(id);
    if (data) {
      setPageData(data);
      setLoading(false);
    } else history.replace("/not-found");
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);
  return (
    <Layout>
      <Helmet
        script={[
          {
            type: "text/javascript",
            innerHTML: window.location.href.includes("climathontimioara")
              ? `!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '427290156909176');
          fbq('track', 'PageView');`
              : "",
          },
        ]}
      >
        <title>Resources | Timisoara Startups</title>
        <noscript>
          {
            '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=427290156909176&ev=PageView&noscript=1" />'
          }
        </noscript>
      </Helmet>
      <Loading isLoading={loading} />
      {pageData && <ResourceDetails pageData={pageData} />}
    </Layout>
  );
};
