import React, { useState } from "react";
import { H3, Grid, Cell, Spacer } from "../../UI";
import {
  BtnStartups,
  DisplayFlex,
  Icon,
  SubtitleParagraph,
  Wrapper,
} from "./StartUps.style";
import { Link } from "react-router-dom";
import { CardStartup } from "../..";
import { images } from "../../../public";

export const Startups = ({ isHomepage, startupsList }) => {
  const [visibleStartups, setVisibleStartups] = useState(9);

  const handleShowMoreStartups = () => {
    setVisibleStartups(visibleStartups + 9);
  };

  return (
    <Wrapper>
      <Grid>
        {isHomepage && (
          <Cell md={{ colSpan: 12 }} id={"FavouriteStartUp"}>
            <DisplayFlex>
              <Icon src={images.newRocket} alt="startup-icon" />
              <div>
                <H3 align={"left"}>Startups</H3>
                <Spacer lgheight={"0.8rem"} />
                <SubtitleParagraph>
                  Names we love, names you haven't heard of (yet!), and names to
                  keep a watch on because they're up to doing great things!
                </SubtitleParagraph>
              </div>
            </DisplayFlex>
            <Spacer lgheight={"2.8rem"} />
          </Cell>
        )}
        {startupsList &&
          startupsList.slice(0, visibleStartups).map((startup, index) => (
            <Cell key={index}>
              <CardStartup
                title={startup.name}
                link={`/startups/${startup.url}`}
                image={startup.logo}
                industry={startup.industry}
                funding={startup.funding}
              />
            </Cell>
          ))}
        <Cell />
        {isHomepage ? (
          <Cell lg={{ colSpan: 4, offset: 5 }} md={{ colSpan: 6, offset: 4 }}>
            <Spacer lgheight={"0.8rem"} />
            <Link to="/startups">
              <BtnStartups>Meet the Startups</BtnStartups>
            </Link>
            <Spacer lgheight={"13.8rem"} />
          </Cell>
        ) : (
          startupsList.length > visibleStartups && (
            <Cell lg={{ colSpan: 4, offset: 5 }} md={{ colSpan: 6, offset: 4 }}>
              <Spacer lgheight={"0.8rem"} />
              <BtnStartups onClick={handleShowMoreStartups}>
                <span>View more</span>
              </BtnStartups>
              <Spacer lgheight={"9.6rem"} />
            </Cell>
          )
        )}
      </Grid>
    </Wrapper>
  );
};
