import styled from 'styled-components';
import { colors } from '../../consts/colors';
import { ParagraphBody, ParagraphCaption, H4 } from '../common/common.style';
import { Button } from '../UI';

export const BtnStakeholders = styled.div`
  display: inline-block;
  width: 3.8rem;
  height: 38.8rem;
  background-color: transparent;
  border: 2px solid ${colors.textmain};
  padding: 1rem 7.95rem;
  height: auto;
  font-size: 2rem;
  font-family: 'Montserrat', sans-serif;
  line-height: 3.2rem;
  text-align: center;
  border-radius: 0.8rem;
  & a {
    color: ${colors.textmain};
  }
  @media (max-width: 1199px) {
    display: inline-block;
    width: 100%;
    height: auto;
  }
  @media (max-width: 767px) {
    width: 100%;
    height: auto;
  }
`;

export const CardStartup = styled.div`
  cursor: pointer;
  width: 100%;
  height: 38rem;
  border-radius: 2.4rem;
  box-shadow: 0 2px 4px 2px rgba(9, 35, 60, 0.05);
  transition: all 0.3s;

  &:hover {
    box-shadow: 0 16px 24px 8px rgba(9, 35, 60, 0.07);
  }
  @media (max-width: 1199px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const ImgContainer = styled.div`
  display: inline-block;
  margin: 4.8rem 0rem 2.4rem 1.6rem;
  width: 14.8rem;
  height: 14.8rem;
  border-radius: 0.8rem;
`;

export const CardImg = styled.div`
  display: inline-block;
  height: 38rem;
  border-radius: 2.4rem;
  box-shadow: 0 16px 24px 8px rgba(9, 35, 60, 0.07);
`;

export const Img = styled.img`
  width: 100%;
  max-width: 38rem;
  height: 100%;
  display: block;
  object-fit: cover;
  object-position: center;
  border-radius: 2.4rem;
`;

export const CardContainer = styled.div`
  padding: 0.2rem 1.6rem;
`;

export const SubtitleParagraph = styled(ParagraphBody)`
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const Subtitle = styled(ParagraphCaption)`
  color: ${colors.textcaption};
  font-family: 'Raleway', sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2rem;
  padding: 0.1rem 1rem;
  width: 15.9rem;
  text-align: center;
  border: 1px solid ${colors.placeholdertext};
  border-radius: 0.4rem;
  background-color: ${colors.bgdisabled};
`;

export const TextCard = styled(ParagraphCaption)`
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  color: ${({ color }) => (color ? color : colors.textmain)};
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const TitleSmall = styled.p`
  font-family: 'Raleway' sans-serif;
  margin-top: 0rem;
  color: ${({ color }) => (color ? color : colors.textcaption)};
  font-size: 1.2rem;
  line-height: 2rem;
  display: block;
  ${({ align }) => align && `text-align: ${align}`};
`;

export const SmLogo = styled.img`
  object-fit: contain;
  margin-right: 2.4rem;
  display: inline-block;
  cursor: pointer;
`;

export const SmLogoDisplay = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  @media (max-width: 1199px) {
    justify-content: start;
    align-items: start;
    align-content: start;
  }
  @media (max-width: 767px) {
  }
`;

export const Website = styled.a`
  font-family: 'Raleway';
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.8rem;
  color: ${colors.primaryyellow};
`;

export const CardTeam = styled.div`
  width: 100%;
`;

export const TeamImgWrapper = styled.div`
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
`;

export const TeamImg = styled.img`
  min-width: 100%;
  min-height: 100%;
  max-width: 150%;
  max-height: 150%;
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1199px) {
    align-items: center;
    widht: 100%;
    border-radius: 50%;
  }
  @media (max-width: 767px) {
    align-items: center;
  }
`;

export const CardTeamContainer = styled.div`
  padding: 0.2rem 1.6rem;
`;

export const TextCardMember = styled(ParagraphCaption)`
  text-align: center;
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: 'Raleway', sans-serif;
  color: ${({ color }) => (color ? color : colors.textcaption)};
  font-size: 1.2rem;
  line-height: 2rem;
`;

export const DisplayFlex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: stretch;
`;

export const Funding = styled(H4)`
  font-family: 'Montserrat', sans-serif;
  color: ${colors.textmain};
  font-size: 2rem;
  line-height: 3.2rem;
  font-weight: 600;
`;
export const Address = styled(ParagraphBody)`
  color: ${colors.textmain};
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
`;

// Editable fields

const BaseInput = styled.input`
  display: block;
  width: 100%;
  border: unset;
  background-color: unset;
  box-shadow: unset;
  outline: none;
  &:focus {
    border: unset;
    box-shadow: unset;
    outline: none;
  }
`;

const BaseTextarea = styled.textarea`
  display: block;
  width: 100%;
  border: unset;
  background-color: unset;
  box-shadow: unset;
  outline: none;
  &:focus {
    border: unset;
    box-shadow: unset;
    outline: none;
  }
`;

export const EditableH1 = styled(BaseInput)`
  font-family: Montserrat, sans-serif;
  font-size: 4.2rem;
  font-weight: 700;
  color: rgb(9, 35, 60);
  line-height: 5rem;
`;
export const EditableFunding = styled(BaseInput)`
  font-family: Montserrat, sans-serif;
  color: rgb(9, 35, 60);
  font-size: 2rem;
  line-height: 3.2rem;
  font-weight: 600;
`;
export const EditableIndustry = styled(BaseInput)`
  color: rgb(100, 120, 148);
  font-family: Raleway, sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2rem;
  padding: 0.1rem 1rem;
  width: 15.9rem;
  text-align: center;
  border: 1px solid rgb(175, 188, 206);
  border-radius: 0.4rem;
  background-color: rgb(240, 243, 247);
`;
export const EditableDescription = styled(BaseTextarea)`
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: Raleway, sans-serif;
  font-weight: 400;
  color: rgb(9, 35, 60);
  font-size: 1.6rem;
  line-height: 2.4rem;
`;
export const EditableAddress = styled(BaseInput)`
  color: rgb(9, 35, 60);
  font-family: Raleway, sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
`;
export const EditableWebsite = styled(BaseInput)`
  font-family: Raleway;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.8rem;
  color: rgb(250, 163, 27);
`;

export const ActionWrapper = styled.div`
  display: flex;
  gap: 2.4rem;
`;

export const BaseButton = styled(Button)``;
