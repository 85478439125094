import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import MarkdownIt from 'markdown-it';
import { api } from '../../api/api';
import { CardBlogPost } from '..';
import { Cell, Grid, GridWrapper, H3, Spacer } from '../UI';

import {
  Title,
  Subtitle,
  Category,
  Image,
  Wrapper,
  Content,
  Button,
} from './ResourceDetails.style';

const mdParser = new MarkdownIt(/* Markdown-it options */);

export const ResourceDetails = ({ pageData }) => {
  const [posts, setPosts] = useState([]);
  const visiblePosts = 3;
  const htmlChars = /[\\~#%&*{}/:<>?|"-]+/g;
  const regEx = new RegExp(htmlChars);

  const fetchData = async () => {
    const data = await api.getResources();
    if (data) setPosts(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <GridWrapper>
      <Grid>
        <Cell lg={{ colSpan: 8, offset: 3 }} md={{ colSpan: 12 }}>
          <Spacer lgheight="7.6rem" mdheight="4.8rem" />
          <Title>{pageData.title}</Title>
          <Spacer lgheight="0.4rem" />
        </Cell>
        <Cell lg={{ colSpan: 8, offset: 3 }} md={{ colSpan: 12 }}>
          <Wrapper>
            <Subtitle>{format(new Date(pageData.date), 'MMMM dd, yyyy')}</Subtitle>
            <Category>{pageData.category}</Category>
          </Wrapper>
        </Cell>
        <Cell lg={{ colSpan: 8, offset: 3 }} md={{ colSpan: 12, offset: 1 }}>
          <Spacer lgheight="0.4rem" />
          <Image src={pageData.featuredImage} />
        </Cell>
        {/* BLOG CONTENT */}
        <Cell lg={{ colSpan: 8, offset: 3 }} md={{ colSpan: 12, offset: 1 }}>
          <Content
            dangerouslySetInnerHTML={{
              __html: mdParser.render(pageData.content),
            }}
          />
          <Spacer lgheight="7.6rem" />
        </Cell>
        {/* SIMILAR ARTICLES */}
        {posts.filter(post => post.category === pageData.category && post.url !== pageData.url)
          .length > 0 && (
          <Cell lg={{ colSpan: 12 }} md={{ colSpan: 12 }}>
            <Grid>
              <Cell lg={{ colSpan: 12 }} md={{ colSpan: 12 }}>
                <H3>Similar articles</H3>
                <Spacer lgheight="2.8rem" />
              </Cell>
              {posts
                .filter(post => post.category === pageData.category && post.url !== pageData.url)
                .map(
                  (post, index) =>
                    index < visiblePosts && (
                      <Cell key={index}>
                        <CardBlogPost
                          img={post.featuredImage}
                          title={post.title}
                          subtitle={format(new Date(post.date), 'MMMM dd, yyyy')}
                          text={post.content.replace(regEx, '').substring(0, 150) + '...'}
                          link={`/resources/${post.url}`}
                        />
                      </Cell>
                    )
                )}
              <Cell lg={{ colSpan: 12 }} md={{ colSpan: 12 }} />
              <Cell lg={{ colSpan: 4, offset: 5 }} md={{ colSpan: 6, offset: 4 }}>
                <Spacer lgheight="0.8rem" />
                <Button as="a" href="/resources">
                  View all articles
                </Button>
                <Spacer lgheight="9.6rem" />
              </Cell>
            </Grid>
          </Cell>
        )}
      </Grid>
    </GridWrapper>
  );
};
