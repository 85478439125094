import styled from "styled-components";
import { colors } from "../../../consts/colors";

export const Label = styled.span`
  color: ${colors.jetblack};
  font-size: 1.6rem;
  line-height: 2.4rem;
  display: block;
  text-align: center;
  transition: color 0.6s;
`;

export const Container = styled.div`
  display: block;
  min-width: 16rem;
  padding: 2.8rem 3rem;
  position: relative;
  margin-right: 2rem;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }

  &:hover {
    ${Label} {
      color: ${colors.primaryyellow};
    }
  }

  &::after {
    content: "";
    display: block;
    width: 8.8rem;
    height: 4px;
    border-radius: 2px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${colors.primaryyellow};
  }
`;
