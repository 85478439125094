import React, { useState, useEffect } from "react";
import { images } from "../../public";
import { Grid, GridWrapper, Cell, Input, H1, Spacer, Dropdown } from "../UI";

//CONSTS
const positionCategories = [
  "All",
  "Founder/Co-Founder",
  "Investor",
  "Community Leader",
  "Coworking space manager",
  "Universities representative",
  "Public sector representative",
];

export const FilterStakeholders = ({ onFilterChange }) => {
  const [filter, setFilter] = useState({
    search: "",
    position: positionCategories[0],
  });

  const handleFilterChange = (key, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  //UPDATE FORM ON FLITER CHANGE
  useEffect(() => {
    onFilterChange(filter);
    // eslint-disable-next-line
  }, [filter]);

  return (
    <GridWrapper style={{ zIndex: 2 }}>
      <Grid>
        <Cell md={{ colSpan: 12 }}>
          <H1>Find community members</H1>
          <Spacer lgheight={"2.9rem"} mdheight={"0"} />
        </Cell>
        <Cell>
          <Input
            label="Name"
            name="search"
            type="text"
            placeholder="Search"
            image={images.search}
            value={filter.search}
            handleOnChange={handleFilterChange}
          />
        </Cell>
        <Cell>
          <Dropdown
            label="Job title"
            name="position"
            selected={filter.position}
            values={positionCategories}
            handleOnChange={handleFilterChange}
          />
        </Cell>
      </Grid>
    </GridWrapper>
  );
};
