import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { api } from '../../api';
import { AdminFormStartup, LayoutAdmin } from '../../components';
import { H1, H3, GridWrapper, Grid, Cell, Spacer, Loading } from '../../components/UI';
import { Helmet } from 'react-helmet-async';

const initialFormValues = {
  name: '',
  description: '',
  website: '',
  email: '',
  phone: '',
  city: '',
  postal_code: '',
  address: '',
  funding: '',
  industry: '',
  instagram: '',
  linkedin: '',
  facebook: '',
  logo: '',
  founders: [],
};

export const AdminStartUp = ({ match }) => {
  const history = useHistory();
  const { id } = match.params;
  const [startup, setStartup] = useState({ ...initialFormValues });
  const [loading, setLoading] = useState(true);

  async function fetchData() {
    const data = await api.getSingleStartup(id);
    if (data) {
      setStartup({ ...data, postal_code: data.postal_code !== null ? data.postal_code : '' });
      setLoading(false);
    } else history.push('/not-found');
  }
  //FETCH DATA
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <LayoutAdmin>
      <Helmet>
        <title>Edit startup | Timisoara Startups</title>
      </Helmet>
      <Loading isLoading={loading} />
      <Spacer lgheight={'9.6rem'} mdheight={'4.8rem'} />
      <GridWrapper>
        <Grid>
          <Cell lg={{ colSpan: 6 }} md={{ colSpan: 12 }}>
            <H1 align={'left'}>Edit Startup</H1>
            <Spacer lgheight={'4.8rem'} />
            <H3>Startup information</H3>
          </Cell>
        </Grid>
      </GridWrapper>
      <Spacer lgheight={'2.4rem'} />
      <AdminFormStartup data={startup} />
    </LayoutAdmin>
  );
};
