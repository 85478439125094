import { Cell, Spacer, Hint, H3, H5, colors, GridWrapper, Grid, Textarea } from '../UI';
import React, { useState, useEffect } from 'react';
import {
  FormInp,
  FormInputLabel,
  SendBtn,
  TextCheckmark,
  CheckboxContainer,
  Icon,
  StyledCheckbox,
  TextLabel,
  TitleCheckBox,
  ErrorMsg,
} from './Forms.style';
import { api } from '../../api/api';
import { ImageUpload } from './ImageUpload/ImageUpload';
import { stakeholderSchema } from '../validation';
import { ROLES, ERRORS, STAKEHOLDER } from '../../consts';

export const AdminFormStakeholder = ({ data }) => {
  const [stakeholder, setStakeholder] = useState(data ? data : STAKEHOLDER);
  const [errors, setErrors] = useState({ ...ERRORS });
  const [terms, setTerms] = useState(false);
  const [termsError, setTermsError] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [sent, setSent] = useState(false);

  //SCROLL INTO VIEW
  const scrollToErrors = () => {
    for (let key of Object.keys(errors)) {
      if (key !== 'status' && errors[key] !== '') {
        document
          .querySelector(`[name="${key}"]`)
          ?.parentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        break;
      }
    }
  };
  useEffect(() => {
    if (!disabled) scrollToErrors();
    // eslint-disable-next-line
  }, [disabled]);

  const handleChangeForm = (key, value) => {
    if (key === 'role') {
      const newRole = [...stakeholder.role];
      const index = newRole.indexOf(value);
      if (index === -1) newRole.push(value);
      else newRole.splice(index, 1);
      setStakeholder(prevState => ({
        ...prevState,
        role: newRole,
      }));
    } else
      setStakeholder(prevState => ({
        ...prevState,
        [key]: value,
      }));
  };

  //errors
  const setError = (key, value) => {
    setErrors(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };
  const clearErrors = () => {
    setErrors({
      ...ERRORS,
    });
    setTermsError('');
  };

  const onSubmit = async () => {
    setDisabled(true);
    clearErrors();
    //form validation messages
    Object.keys(stakeholder).forEach(async key => {
      if (stakeholderSchema[key] !== undefined) {
        if (key !== 'email' && key !== 'phone')
          stakeholderSchema[key].validate(stakeholder[key]).catch(err => {
            setError(key, err.message);
          });
        else
          stakeholderSchema[key].validate(stakeholder[key].value).catch(err => {
            setError(key, err.message);
          });
      }
    });
    //terms validation message
    if (!terms) setTermsError('You must agree with the terms and conditions.');
    //form validation
    let isFormValid = true;

    for (let key of Object.keys(stakeholder))
      if (isFormValid && stakeholderSchema[key] !== undefined) {
        if (key !== 'email' && key !== 'phone')
          isFormValid = await stakeholderSchema[key].isValid(stakeholder[key]);
        else isFormValid = await stakeholderSchema[key].isValid(stakeholder[key].value);
      }
    if (!isFormValid || (!data && !terms)) {
      setDisabled(false);
      return;
    }

    //add/edit stakeholder
    // console.log('stakeholder', stakeholder);
    if (data) await api.updateStakeholder(stakeholder);
    else await api.addStakeholder(stakeholder);
    setSent(true);
    setTimeout(() => {
      setDisabled(false);
      setSent(false);
      if (!data) {
        setStakeholder({ ...STAKEHOLDER });
        window.scrollTo(0, 1);
      }
    }, 2000);
  };

  return (
    <GridWrapper>
      <Grid>
        <Cell lg={{ colSpan: 3, offset: 1 }} md={{ colSpan: 6 }}>
          <FormInputLabel For="firstName">First Name*</FormInputLabel>
          <FormInp
            id="firstName"
            placeholder={'Type here'}
            name="firstName"
            type="text"
            value={stakeholder.firstName}
            onChange={e => handleChangeForm(e.target.name, e.target.value)}
          />
          <ErrorMsg>{errors.firstName}</ErrorMsg>

          <Spacer lgheight={'1.6rem'} />
        </Cell>
        <Cell lg={{ colSpan: 3 }} md={{ colSpan: 6 }}>
          <FormInputLabel For="lastName">Last Name*</FormInputLabel>
          <FormInp
            id="lastName"
            placeholder={'Type here'}
            name="lastName"
            type="text"
            value={stakeholder.lastName}
            onChange={e => handleChangeForm(e.target.name, e.target.value)}
          />
          <ErrorMsg>{errors.lastName}</ErrorMsg>
          <Spacer lgheight={'1.6rem'} />
        </Cell>

        <Cell lg={{ colSpan: 5, offset: 8, rowSpan: 2 }} md={{ colSpan: 12 }}>
          <ImageUpload
            image={stakeholder.avatar}
            onSetImage={image => handleChangeForm('avatar', image)}
          />
          <Spacer lgheight={'0.8rem'} />
          <ErrorMsg>{errors.avatar}</ErrorMsg>
        </Cell>
        <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
          <Spacer lgheight={'2.4rem'} />
          <FormInputLabel>Position*</FormInputLabel>
          <FormInp
            placeholder={'Type here'}
            type="text"
            name="position"
            value={stakeholder.position}
            onChange={e => handleChangeForm(e.target.name, e.target.value)}
          />
          <ErrorMsg>{errors.position}</ErrorMsg>
        </Cell>
        <Cell lg={{ colSpan: 6 }} md={{ colSpan: 12 }}>
          <Spacer mdheight={'2.4rem'} />
          <FormInputLabel For="description">Short description*</FormInputLabel>
          <Textarea
            name="description"
            placeholder="Type here"
            value={stakeholder.description}
            onChange={handleChangeForm}
          />
          <ErrorMsg>{errors.description}</ErrorMsg>
        </Cell>

        <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
          <Spacer lgheight={'1.6rem'} />
          <FormInputLabel For="email">Email address*</FormInputLabel>
          <FormInp
            id="email"
            placeholder={'Type here'}
            name="email"
            type="text"
            value={stakeholder.email.value}
            onChange={e =>
              handleChangeForm(e.target.name, { ...stakeholder.email, value: e.target.value })
            }
          />
          <ErrorMsg>{errors.email}</ErrorMsg>
          <Spacer lgheight={'1.6rem'} />
          <label
            htmlFor="emailCheck"
            onClick={() =>
              handleChangeForm('email', {
                ...stakeholder.email,
                visible: !stakeholder.email.visible,
              })
            }
          >
            <CheckboxContainer>
              <StyledCheckbox
                type="checkbox"
                name="emailCheck"
                defaultChecked={!stakeholder.email.visible}
              >
                <Icon viewBox="0 0 24 24">
                  <polyline points="20 6 9 17 4 12" />
                </Icon>
              </StyledCheckbox>
            </CheckboxContainer>
            <TextCheckmark>Do not display my email address</TextCheckmark>
          </label>
          <Spacer lgheight={'2.4rem'} />
        </Cell>
        <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
          <FormInputLabel For="phone">Phone Number*</FormInputLabel>
          <FormInp
            id="phone"
            placeholder={'Type here'}
            name="phone"
            type="text"
            value={stakeholder.phone.value}
            onChange={e =>
              handleChangeForm(e.target.name, { ...stakeholder.phone, value: e.target.value })
            }
          />
          <Spacer lgheight={'0.8rem'} />
          <Hint>Please provide a valid phone number. This is our primary registration check.</Hint>
          <ErrorMsg>{errors.phone}</ErrorMsg>
          <Spacer lgheight={'1.6rem'} />
          <label
            htmlFor="phoneCheck"
            onClick={() =>
              handleChangeForm('phone', {
                ...stakeholder.phone,
                visible: !stakeholder.phone.visible,
              })
            }
          >
            <CheckboxContainer>
              <StyledCheckbox
                type="checkbox"
                name="phoneCheck"
                defaultChecked={!stakeholder.phone.visible}
              >
                <Icon viewBox="0 0 24 24">
                  <polyline points="20 6 9 17 4 12" />
                </Icon>
              </StyledCheckbox>
            </CheckboxContainer>
            <TextCheckmark>Do not display my phone number</TextCheckmark>
          </label>
          <Spacer lgheight={'2.4rem'} />
        </Cell>
        <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
          <TitleCheckBox>Role in the community*</TitleCheckBox>
          <Spacer lgheight={'1.6rem'} />
          <ul>
            {ROLES.map((role, index) => {
              return (
                <li
                  key={'roles_' + index}
                  onClick={() => {
                    handleChangeForm('role', role);
                  }}
                >
                  <CheckboxContainer>
                    <StyledCheckbox
                      name="checkbox"
                      type="checkbox"
                      id={`custom-checkbox-${index}`}
                      defaultChecked={stakeholder.role.includes(role) ? true : false}
                    >
                      <Icon viewBox="0 0 24 24">
                        <polyline points="20 6 9 17 4 12" />
                      </Icon>
                    </StyledCheckbox>
                  </CheckboxContainer>
                  <TextLabel htmlFor={`custom-checkbox-${index}`}>{role}</TextLabel>
                </li>
              );
            })}
          </ul>
        </Cell>
        <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
          <Spacer lgheight={'4.8rem'} />
          <H3>Social media</H3>
          <Spacer lgheight={'2.4rem'} />
        </Cell>

        <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
          <FormInputLabel For="linkedin">LinkedIn profile URL</FormInputLabel>
          <Spacer lgheight={'0.4rem'} />
          <FormInp
            id="linkedin"
            placeholder={'Type here'}
            name="linkedin"
            type="text"
            value={stakeholder.linkedin}
            onChange={e => handleChangeForm(e.target.name, e.target.value)}
          />
          <ErrorMsg>{errors.linkedin}</ErrorMsg>
        </Cell>
        <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
          <Spacer lgheight={'1.6rem'} />
          <FormInputLabel For="facebook">Facebook profile URL</FormInputLabel>
          <Spacer lgheight={'0.4rem'} />
          <FormInp
            id="facebook"
            placeholder={'Type here'}
            name="facebook"
            type="text"
            value={stakeholder.facebook}
            onChange={e => handleChangeForm(e.target.name, e.target.value)}
          />
          <ErrorMsg>{errors.facebook}</ErrorMsg>
        </Cell>

        <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
          <Spacer lgheight={'1.6rem'} />
          <FormInputLabel For="instagram">Instagram profile URL</FormInputLabel>
          <Spacer lgheight={'0.4rem'} />
          <FormInp
            id="instagram"
            placeholder={'Type here'}
            name="instagram"
            type="text"
            value={stakeholder.instagram}
            onChange={e => handleChangeForm(e.target.name, e.target.value)}
          />
          <ErrorMsg>{errors.instagram}</ErrorMsg>
        </Cell>

        {!data && (
          <Cell lg={{ colSpan: 7, offset: 1 }} md={{ colSpan: 12 }}>
            <Spacer lgheight={'2.4rem'} />
            <label htmlFor="privatePolicy" onClick={() => setTerms(!terms)}>
              <CheckboxContainer>
                <StyledCheckbox type="checkbox" name="privatePolicy" defaultChecked={terms}>
                  <Icon viewBox="0 0 24 24">
                    <polyline points="20 6 9 17 4 12" />
                  </Icon>
                </StyledCheckbox>
              </CheckboxContainer>
              <TextCheckmark>
                I have read and agree to the Privacy Policy and Terms of Use
              </TextCheckmark>
            </label>
            <ErrorMsg>{termsError}</ErrorMsg>
            <Spacer lgheight={'1.6rem'} />
          </Cell>
        )}
        <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
          <Spacer lgheight={'2.4rem'} />
          <SendBtn onClick={onSubmit} disabled={disabled}>
            {data ? 'Update information' : 'Send for review'}
          </SendBtn>
          <Spacer lgheight={'2.4rem'} />
          {sent && (
            <H5 color={colors.success}>
              {data ? 'Stakeholder information updated!' : 'Stakeholder sent for review!'}
            </H5>
          )}
          <Spacer lgheight={'9.6rem'} />
        </Cell>
      </Grid>
    </GridWrapper>
  );
};
