import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { AdminCardBlogPost, Input } from '../..';
import { api } from '../../../api';
import { images } from '../../../public';
import { CATEGORIES } from '../../../consts';
import { Grid, GridWrapper, Cell, Spacer, Dropdown, H4 } from '../../UI';
import { Button } from './ResourcesList.style';

export const AdminResourcesList = ({ postsList, refresh, onFilter }) => {
  const history = useHistory();
  const [filter, setFilter] = useState({ search: '', category: 'All' });

  const handleFilterChange = (key, value) => {
    setFilter(prevState => ({
      ...prevState,
      [key]: value,
    }));
    onFilter({
      ...filter,
      [key]: value,
    });
  };

  const handlePublishPost = async post => {
    const data = await api.updatePost({
      ...post,
      publishStatus: !post.publishStatus,
    });
    if (data) refresh();
    else history.replace('/login');
  };
  const handleEditPost = id => {
    history.push(`/admin/resources/${id}`);
  };
  const handleDeletePost = async id => {
    const data = await api.deletePost(id);
    if (data) refresh();
    else history.replace('/login');
  };

  return (
    <GridWrapper>
      <Grid>
        <Cell lg={{ colSpan: 12 }} md={{ colSpan: 12 }}>
          <Spacer lgheight="9.6rem" mdheight="4.8rem" />
        </Cell>
        <Cell lg={{ colSpan: 4 }} md={{ colSpan: 6 }}>
          <Link to="/admin/resources/new-post">
            <Button>New article</Button>
          </Link>
        </Cell>
        <Cell lg={{ colSpan: 1 }} md={{ colSpan: 12 }}></Cell>
        <Cell lg={{ colSpan: 4 }} md={{ colSpan: 6 }}>
          <Input
            label="Search for article"
            name="search"
            type="search"
            value={filter.search}
            image={images.search}
            placeholder="Search"
            handleOnChange={handleFilterChange}
          />
        </Cell>
        <Cell lg={{ colSpan: 3 }} md={{ colSpan: 6 }} style={{ zIndex: 4 }}>
          <Dropdown
            label="Categories"
            name="category"
            selected={filter.category}
            values={['All', ...CATEGORIES]}
            handleOnChange={handleFilterChange}
          />
        </Cell>
        <Cell lg={{ colSpan: 12 }} md={{ colSpan: 12 }}>
          <Spacer lgheight="2.8rem" mdheight="0.4rem" />
        </Cell>
        {postsList.map((post, index) => (
          <Cell key={index}>
            <AdminCardBlogPost
              img={post.featuredImage}
              title={post.title}
              subtitle={format(new Date(post.date), 'MMMM dd, yyyy')}
              published={post.publishStatus}
              onPublish={() => handlePublishPost(post)}
              onEdit={() => handleEditPost(post.url)}
              onDelete={() => handleDeletePost(post.url)}
            />
          </Cell>
        ))}
        {postsList.length === 0 && (
          <Cell md={{ colSpan: 12 }}>
            <Spacer lgheight="2.4rem" mdheight="1.2rem" />
            <H4>No results</H4>
          </Cell>
        )}
        <Cell>
          <Spacer lgheight="9.6rem" mdheight="4.8rem" />
        </Cell>
      </Grid>
    </GridWrapper>
  );
};
