import React from 'react';

import { Spacer } from '../UI';
import { Container, ImgContainer, Image, Title, Subtitle } from './CardFounder.style';

export const CardFounder = ({ image, name, position }) => {
  return (
    <Container>
      <ImgContainer>
        <Image src={image} alt="Profile photo" />
      </ImgContainer>
      <Spacer lgheight={'1.6rem'} />
      <Title>{name} </Title>
      <Spacer lgheight={'0.8rem'} />
      <Subtitle>{position}</Subtitle>
    </Container>
  );
};
