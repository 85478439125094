import React, { useState, useEffect } from 'react';
import { AdminResourcesList, LayoutAdmin } from '../../components';
import { Helmet } from 'react-helmet-async';
import { Loading } from '../../components/UI';
import { api } from '../../api';

export const ResourcesListPage = () => {
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [filter, setFilter] = useState({ search: '', category: 'All' });
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const posts = await api.getResources();
    if (posts) {
      setPosts(posts);
      setFilteredPosts(posts);
      setLoading(false);
    }
  };

  //APPLY FILTER
  useEffect(() => {
    let filteredList = posts;
    if (filter.search !== '')
      filteredList = filteredList.filter(item =>
        item.title.toLowerCase().includes(filter.search.toLowerCase())
      );
    if (filter.category !== 'All')
      filteredList = filteredList.filter(item =>
        item.category.toLowerCase().includes(filter.category.toLowerCase())
      );
    setFilteredPosts(filteredList);
    // eslint-disable-next-line
  }, [filter, posts]);

  //FETCH DATA
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <LayoutAdmin>
      <Helmet>
        <title>Resources | Timisoara Startups</title>
      </Helmet>
      <Loading isLoading={loading} />
      <AdminResourcesList onFilter={setFilter} postsList={filteredPosts} refresh={fetchData} />
    </LayoutAdmin>
  );
};
