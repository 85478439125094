import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { CardBlogPost, LatestCardBlogPost, Input } from "../..";
import { images } from "../../../public";
import { Grid, GridWrapper, Cell, H1, Spacer, H4, H3 } from "../../UI";
import { Categories, Category } from "./ResourcesList.style";
import { CATEGORIES } from "../../../consts";

export const ResourcesList = ({ posts }) => {
  const [filter, setFilter] = useState({
    search: "",
    category: "All Categories",
  });
  const [hasFilter, setHasFilter] = useState(false);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const latest = posts.sort((a, b) => new Date(b.date) - new Date(a.date))[0];

  const htmlChars = /[\\~#%&*{}/:<>?|"-]+/g;
  const regEx = new RegExp(htmlChars);

  //APPLY FILTER
  useEffect(() => {
    let filteredList = posts;
    setHasFilter(false);
    if (filter.search !== "") {
      filteredList = filteredList.filter((item) =>
        item.title.toLowerCase().includes(filter.search.toLowerCase())
      );
      setHasFilter(true);
    }
    if (filter.category !== "All Categories") {
      filteredList = filteredList.filter((item) =>
        item.category.toLowerCase().includes(filter.category.toLowerCase())
      );
      setHasFilter(true);
    }
    setFilteredPosts(filteredList);
    // eslint-disable-next-line
  }, [filter, posts]);
  return (
    <GridWrapper>
      <Grid>
        <Cell lg={{ colSpan: 7 }} md={{ colSpan: 12 }}>
          <Spacer lgheight="9.6rem" mdheight="4.8rem" />
          <H1>Learn and grow</H1>
          <Spacer lgheight="2.4rem" />
          <H4>
            Here you can find information about upcoming events, interviews with
            founders and opportunities for startups and their teams.
          </H4>
          <Spacer lgheight="4.8rem" mdheight="2.4rem" />
        </Cell>
        <Cell lg={{ colSpan: 9 }} md={{ colSpan: 12 }}>
          <Categories>
            {["All Categories", ...CATEGORIES].map((category) => (
              <Category
                key={category}
                active={category === filter.category}
                onClick={() => setFilter({ ...filter, category })}
              >
                {category}
              </Category>
            ))}
          </Categories>
        </Cell>
        <Cell lg={{ colSpan: 3 }} md={{ colSpan: 12 }}>
          <Spacer lgheight="0" mdheight="2.4rem" />
          <Input
            name="search"
            type="search"
            value={filter.search}
            image={images.search}
            placeholder="Search"
            handleOnChange={(name, search) => setFilter({ ...filter, search })}
          />
        </Cell>
        {filteredPosts.length > 0 && (
          <Cell lg={{ colSpan: 12 }} md={{ colSpan: 12 }}>
            <Spacer lgheight="4.8rem" mdheight="2.4rem" />
            <H3>{latest && !hasFilter ? "Latest Article" : "Articles"}</H3>
            <Spacer lgheight="4.8rem" mdheight="2.4rem" />
          </Cell>
        )}
        {latest && !hasFilter && (
          <Cell lg={{ colSpan: 12 }} md={{ colSpan: 12 }} sm={{ colSpan: 12 }}>
            <LatestCardBlogPost
              img={latest.featuredImage}
              title={latest.title}
              subtitle={format(new Date(latest.date), "MMMM dd, yyyy")}
              text={latest.content.replace(regEx, "").substring(0, 150) + "..."}
              link={`/resources/${latest.url}`}
            />
            <Spacer lgheight="4.8rem" mdheight="3.6rem" />
            <H3>Other articles</H3>
            <Spacer lgheight="4.8rem" mdheight="2.4rem" />
          </Cell>
        )}
        {filteredPosts.map(
          (post, index) =>
            (post.url !== latest.url || hasFilter) && (
              <Cell key={index}>
                <CardBlogPost
                  img={post.featuredImage}
                  title={post.title}
                  subtitle={format(new Date(post.date), "MMMM dd, yyyy")}
                  text={
                    post.content.replace(regEx, "").substring(0, 150) + "..."
                  }
                  link={`/resources/${post.url}`}
                />
                <Spacer mdheight="2.4rem" />
              </Cell>
            )
        )}
        {filteredPosts.length === 0 && (
          <Cell md={{ colSpan: 12 }}>
            <Spacer lgheight="4.8rem" mdheight="2.4rem" />
            <H4>No results</H4>
            <Spacer lgheight="9.6rem" mdheight="4.8rem" />
          </Cell>
        )}
        <Cell lg={{ colSpan: 12 }} md={{ colSpan: 12 }}>
          <Spacer lgheight="9.6rem" mdheight="4.8rem" />
        </Cell>
      </Grid>
    </GridWrapper>
  );
};
