import React, { useEffect } from "react";

import Footer from "./Footer";
import Navbar from "./Navbar";
import {
  PageContent,
  PageWrapper,
  BluredShapeTopLeft,
  Background,
} from "./Layout.style";
import { images } from "../public";

export const Layout = ({ noBackground, children }) => {
  return (
    <PageWrapper>
      {!noBackground && <BluredShapeTopLeft />}
      <Navbar />
      <PageContent>{children}</PageContent>
      <Footer />
    </PageWrapper>
  );
};
export const NewLayout = ({ bg, children }) => {
  //SCROLL EFFECT
  const handleScroll = (e) => {
    const shapesPos = `-${document.documentElement.scrollTop * 0.2}px`;
    const colorsPos = `-${document.documentElement.scrollTop * 0.5}px`;
    const shapesElement = document.querySelector("#shapes");
    const colorsElement = document.querySelector("#colors");
    if (shapesElement) shapesElement.style.top = shapesPos;
    if (colorsElement) colorsElement.style.top = colorsPos;
  };

  //ADD LISTENER
  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <PageWrapper>
      {bg === "landing" && (
        <Background>
          <img
            id="shapes"
            src={images.v2LandingShapes}
            alt="background shapes"
          />
          <img
            id="colors"
            src={images.v2LandingColors}
            alt="background colors"
          />
        </Background>
      )}
      {bg === "startups" && (
        <Background>
          <img
            id="shapes"
            src={images.v2StartupsShapes}
            alt="background shapes"
          />
          <img
            id="colors"
            src={images.v2StartupsColors}
            alt="background colors"
          />
        </Background>
      )}
      {bg === "startup" && (
        <Background style={{ top: "-100px" }}>
          <img
            id="shapes"
            src={images.v2StartupShapes}
            alt="background shapes"
          />
          <img
            id="colors"
            src={images.v2StartupColors}
            alt="background colors"
          />
        </Background>
      )}
      {bg === "new-startup" && (
        <Background style={{ top: "-100px", left: "-100px" }}>
          <img
            id="colors"
            src={images.v2NewStartupColor}
            alt="background colors"
          />
        </Background>
      )}
      {bg === "stakeholders" && (
        <Background>
          <img
            id="shapes"
            src={images.v2StakeholdersShapes}
            alt="background shapes"
          />
          <img
            id="colors"
            src={images.v2StakeholdersColors}
            alt="background colors"
          />
        </Background>
      )}
      {bg === "stakeholder" && (
        <Background>
          <img
            style={{ left: "-300px" }}
            id="shapes"
            src={images.v2StakeholderShapes}
            alt="background shapes"
          />
          <img
            id="colors"
            src={images.v2StakeholderColor}
            alt="background colors"
          />
        </Background>
      )}
      {bg === "new-stakeholder" && (
        <Background>
          <img
            id="colors"
            src={images.v2NewStakeholderColor}
            alt="background colors"
          />
        </Background>
      )}
      {bg === "about" && (
        <Background style={{ top: "100px" }}>
          <img id="shapes" src={images.v2AboutShapes} alt="background shapes" />
          <img
            id="colors"
            className="mobile-hide"
            src={images.v2AboutColors}
            alt="background colors"
          />
        </Background>
      )}
      {bg === "get-in-touch" && (
        <Background style={{ top: "-100px" }}>
          <img
            id="shapes"
            src={images.v2GetInTouchShapes}
            alt="background shapes"
          />
          <img
            id="colors"
            src={images.v2GetInTouchColors}
            alt="background colors"
          />
        </Background>
      )}

      <Navbar />
      <PageContent>{children}</PageContent>
      <Footer />
    </PageWrapper>
  );
};
