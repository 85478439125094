import styled from 'styled-components';
import { colors } from '../../UI';
import { images } from '../../../public';
import { SIZE } from '../../../consts';

export const BtnStartup = styled.div`
  cursor: pointer;
  display: inline-block;
  max-width: 42rem;
  min-width: 3.8rem;
  width: 100%;
  background-color: ${colors.primaryyellow};
  padding: 1.6rem 0rem;
  height: auto;
  font-weight: 600;
  font-size: 2rem;
  font-family: 'Montserrat', sans-serif;
  line-height: 3.2rem;
  text-align: center;
  border-radius: 0.8rem;
  color: ${colors.textmain};

  @media (max-width: ${SIZE.MEDIUM}) {
    padding: 1.6rem 2.75rem;
  }
  @media (max-width: ${SIZE.SMALL}) {
    padding: 1.6rem 2.75rem;
  }
`;

export const ArrowBtn = styled.div`
  cursor: pointer;
  width: 4.8rem;
  height: 4.8rem;
  margin: auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: ${`url(${images.downArrow})`};
  transition: all 0.3s;
  svg:hover {
    fill: ${colors.primaryyellow};
  }
`;

export const ArrowDown = () => {
  return (
    <svg
      width="48px"
      height="48px"
      viewBox="0 0 48 48"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd"></g>
      <g transform="translate(936.000000, 912.000000)">
        <rect id="BOUNDS" x="0" y="0" width="48" height="48"></rect>
        <path
          d="M24,3 C35.5979797,3 45,12.4020203 45,24 C45,35.5979797 35.5979797,45 24,45 C12.4020203,45 3,35.5979797 3,24 C3,12.4020203 12.4020203,3 24,3 Z M24,5 C13.5065898,5 5,13.5065898 5,24 C5,34.4934102 13.5065898,43 24,43 C34.4934102,43 43,34.4934102 43,24 C43,13.5065898 34.4934102,5 24,5 Z M24,15 C24.4909198,15 24.8992167,15.3537503 24.9838887,15.8202487 L25,16 L25,29.586 L31.2928932,23.2928932 C31.6400259,22.9457605 32.1788747,22.9071902 32.568611,23.1771823 L32.7071068,23.2928932 C33.0542395,23.6400259 33.0928098,24.1788747 32.8228177,24.568611 L32.7071068,24.7071068 L24.7071068,32.7071068 L24.6233245,32.782078 L24.6233245,32.782078 L24.4995303,32.8665051 L24.4995303,32.8665051 L24.3811902,32.9247789 L24.3811902,32.9247789 L24.2562783,32.9667784 L24.2562783,32.9667784 L24.1441585,32.989686 L24.1441585,32.989686 L24,33 L24,33 L23.8931448,32.9943581 L23.8931448,32.9943581 L23.7437085,32.9667835 L23.7437085,32.9667835 L23.6179269,32.9244155 L23.6179269,32.9244155 L23.431389,32.8228177 L23.431389,32.8228177 L23.3174271,32.7308225 L23.3174271,32.7308225 L15.2928932,24.7071068 C14.9023689,24.3165825 14.9023689,23.6834175 15.2928932,23.2928932 C15.6400259,22.9457605 16.1788747,22.9071902 16.568611,23.1771823 L16.7071068,23.2928932 L23,29.586 L23,16 C23,15.4477153 23.4477153,15 24,15 Z"
          id="shape"
        />
      </g>
    </svg>
  );
};
