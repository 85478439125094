import React from 'react';
import {
  Container,
  Label,
  ImgElement,
  Input as InputElement,
  InputWrapper,
  Error,
} from './Input.style';

export const Input = ({
  type,
  value,
  placeholder,
  label,
  image,
  name,
  error,
  readonly,
  handleOnChange,
  disabled,
}) => {
  const inputID = Math.floor(Math.random() * 99999);

  return (
    <Container>
      {label && <Label htmlFor={`input_${inputID}`}>{label}</Label>}
      <InputWrapper>
        {image && <ImgElement src={image} />}
        <InputElement
          readonly={readonly}
          disabled={disabled}
          type={type}
          value={value}
          placeholder={placeholder}
          id={`input_${inputID}`}
          name={name}
          image={image}
          onChange={e => handleOnChange(e.target.name, e.target.value)}
        />
      </InputWrapper>
      {error && <Error>{error}</Error>}
    </Container>
  );
};
