import {
  ImgContainer,
  CardImg,
  TextCard,
  TitleSmall,
  SmLogo,
  SmLogoDisplay,
  Role,
  Number,
  Email,
} from './StakeholderDetail.style';
import { images } from '../../public';
import { H1, Spacer, Cell, Grid, GridWrapper } from '../UI';

export const StakeholderDetail = ({ pageData }) => {
  return (
    <>
      <Spacer lgheight={'9.6rem'} mdheight={'4.8rem'} />
      <GridWrapper>
        <Grid>
          <Cell lg={{ colSpan: 4, rowSpan: 2 }} md={{ colSpan: 6, offset: 4 }}>
            <ImgContainer>
              <CardImg src={pageData.avatar} alt="avatar" />
            </ImgContainer>
          </Cell>
          <Cell lg={{ colSpan: 7, offset: 6, offsetRow: 1 }} md={{ colSpan: 12 }}>
            <H1>{pageData.firstName + ' ' + pageData.lastName}</H1>
            <Spacer lgheight={'1.6rem'} />
            <Role>
              {pageData.role.map((singleRole, index) => (
                <span key={`role_${index}`}>{singleRole}</span>
              ))}
            </Role>
            <Spacer lgheight={'4.8rem'} />
            {pageData.position.length !== 0 && (
              <>
                <TitleSmall>Position</TitleSmall>
                <Spacer lgheight={'1.6rem'} />
                <Role>{pageData.position}</Role>
                <Spacer lgheight={'2.4rem'} />
              </>
            )}
            <TitleSmall>Short description</TitleSmall>
            <Spacer lgheight={'1.6rem'} />
            <TextCard>{pageData.description}</TextCard>
            {pageData.phone.visible && (
              <>
                <Spacer lgheight={'2.4rem'} />
                <TitleSmall>Phone number</TitleSmall>
                <Spacer lgheight={'1.6rem'} />
                <a href={`tel:${pageData.phone.value}`}>
                  <Number>{pageData.phone.value}</Number>
                </a>
              </>
            )}
            {pageData.email.visible && (
              <>
                <Spacer lgheight={'2.4rem'} />
                <TitleSmall>E-mail</TitleSmall>
                <Spacer lgheight={'1.6rem'} />
                <Email href={`mailto:${pageData.email.value}`}>{pageData.email.value}</Email>
              </>
            )}
            <Spacer lgheight={'2.4rem'} />
            {(pageData.linkedin.length !== 0 ||
              pageData.facebook.length !== 0 ||
              pageData.instagram.length !== 0) && (
              <>
                <TitleSmall>Social media</TitleSmall>
                <Spacer lgheight={'1.6rem'} />
                <SmLogoDisplay>
                  {pageData.linkedin !== '' && (
                    <a href={pageData.linkedin} target="_blank" rel="noreferrer">
                      <SmLogo src={images.linkedin} alt="logo"></SmLogo>
                    </a>
                  )}
                  {pageData.facebook !== '' && (
                    <a href={pageData.facebook} target="_blank" rel="noreferrer">
                      <SmLogo src={images.facebook} alt="logo"></SmLogo>
                    </a>
                  )}
                  {pageData.instagram !== '' && (
                    <a href={pageData.instagram} target="_blank" rel="noreferrer">
                      <SmLogo src={images.instagram} alt="logo"></SmLogo>
                    </a>
                  )}
                </SmLogoDisplay>
              </>
            )}
          </Cell>
        </Grid>
      </GridWrapper>
      <Spacer lgheight={'19.2rem'} mdheight={'0'} />
    </>
  );
};
