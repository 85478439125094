import React from 'react';
import { GridWrapper, Grid, Cell, H2, Spacer } from '../../UI';

export const NoResults = () => {
  return (
    <GridWrapper>
      <Grid>
        <Cell md={{ colSpan: 12 }}>
          <H2>No results</H2>
          <Spacer lgheight={'33.6rem'} />
        </Cell>
      </Grid>
    </GridWrapper>
  );
};
