import styled from 'styled-components';
import { SIZE } from '../../../consts';

export const GridWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-left: ${SIZE.MARGIN};
  margin-right: ${SIZE.MARGIN};
  position: relative;
  z-index: 1;
`;

export const Grid = styled.div`
  display: grid;
  gap: 20px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
  max-width: 1200px;
`;

export const Cell = styled.div`
  position: relative;
  z-index: 3;
  grid-column: span 12; //default full
  width: 100%;
  //SMALL
  @media (max-width: ${SIZE.SMALL}) {
    max-width: 500px;
    margin: auto;
  }
  //MEDIUM
  @media (min-width: ${SIZE.SMALL}) {
    grid-column: span 6; //default
    ${({ md }) =>
      md &&
      `grid-column: ${md.offset ? md.offset + '/' : ''} span ${md.colSpan}!important;
    grid-row: span ${md.rowSpan ? md.rowSpan : 1};`}
  }
  //LARGE
  @media (min-width: ${SIZE.MEDIUM}) {
    grid-column: span 4; //default
    ${({ lg }) =>
      lg &&
      `grid-column: ${lg.offset ? lg.offset + ' /' : ''} span ${lg.colSpan}!important;
      grid-row: ${lg.offsetRow ? lg.offsetRow + ' /' : ''} span ${lg.rowSpan};
    `}
  }
`;

export const Spacer = styled.div`
  ${({ lgheight }) => lgheight && `height: ${lgheight}`};
  @media (max-width: ${SIZE.MEDIUM}) {
    ${({ mdheight }) => mdheight && `height: ${mdheight}`};
  }

  @media (max-width: ${SIZE.SMALL}) {
    ${({ smheight }) => smheight && `height: ${smheight}`};
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  ${({ fluid }) =>
    `${fluid ? `max-width: 100%` : `max-width: 120rem`};
        padding-left: 0rem;
        padding-right: 0rem;
        @media (max-width: 1199px) {
          ${fluid ? `max-width: 100%` : `max-width: 64rem`};
        }
        @media (max-width: 767px) {
          ${fluid ? `max-width: 100%` : `max-width: 33.5rem`};
        }
        @media (min-width: 1200) {
          max-width: 100%;
        }
    `};
`;

export const Row = styled.div`
  width: calc(100% + 2rem);
  margin-left: -1rem;
  margin-right: -1rem;
  display: flex;
  justify-content: flex-start;
  align-content: stretch;
  position: relative;
  flex-wrap: wrap;
  @media (max-width: 1199px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    ${({ reverse }) => reverse && `flex-dircetion: column-reverse`};
  }
  ${({ spaceBetwen }) => spaceBetwen && `justify-content: space-between`};
  ${({ header }) =>
    header &&
    `
    flex-direction: row;
    @media (max-width: 1199px) {
      flex-direction: row;
    }
    @media (max-width: 767px) {
      flex-direction: row;
    }
  `};
  ${({ tablet }) =>
    tablet && `@media (max-width: 1199px) {flex-wrap: nowrap; flex-direction: column}`};
`;

export const Col = styled.div`
  max-width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  ${({ lg }) =>
    lg &&
    `
        position: relative;
        @media (min-width: 1201px) {
            width: ${lg.size * 8 + lg.size * 2}rem;
            ${lg.offset ? `margin-left: ${lg.offset * 8 + lg.offset * 2}rem` : 'margin-left: 0'};
        }
      `}
  ${({ md }) =>
    md &&
    `
        position: relative;
        @media (max-width: 1200px) {
            width: ${md.size * 14 + md.size * 2}rem;
            ${md.offset ? `margin-left: ${md.offset * 14 + md.offset * 2}rem` : 'margin-left: 0'};
        }
      `}
  ${({ sm }) =>
    sm &&
    `
        position: relative;
        @media (max-width: 767px) {
            width: 100%;
            margin: 0;
        }
      `}
  ${({ half }) => half && `width: 50%`};
  ${({ align }) => align && `text-align: ${align}`};
  ${({ thidden }) => thidden && `@media(max-width: 1199px){display: none;}`};
  ${({ mhidden }) => mhidden && `@media(max-width: 767px){display: none;}`};
`;
