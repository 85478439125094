import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { contactSchema } from '../components/validation';
import { NewLayout } from '../components';
import { Helmet } from 'react-helmet-async';
import {
  Cell,
  Grid,
  GridWrapper,
  H1,
  Loading,
  ParagraphBody,
  Spacer,
  Input,
  Textarea,
  BtnPrimary as Button,
  Checkbox,
  colors,
  H5,
} from '../components/UI';
import { H4 } from '../components/common/common.style';

const initialFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  subject: '',
  message: '',
};

export const GetInTouch = () => {
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [contact, setContact] = useState(initialFormValues);
  const [errors, setErrors] = useState(initialFormValues);
  const [terms, setTerms] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [messageSent, setMessageSent] = useState(false);

  const handleInputChange = (key, value) => {
    setContact(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };
  const setError = (key, value) => {
    setErrors(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };
  const clearErrors = () => {
    setErrors(initialFormValues);
    setTermsError(false);
  };
  const handleSubmit = async () => {
    //validation
    clearErrors();
    Object.keys(contact).forEach(async key => {
      await contactSchema[key].validate(contact[key]).catch(err => setError(key, err.message));
    });
    if (!terms) setTermsError(true);
    const valid = await yup.object().shape(contactSchema).isValid(contact);
    if (!valid || !terms) return;
    //submit form
    setDisabled(true);
    console.log(contact);

    //reset form
    setTimeout(() => {
      setMessageSent(true);
      setTimeout(() => setMessageSent(false), 2500);
      setContact(initialFormValues);
      setErrors(initialFormValues);
      setTerms(false);
      setTermsError(false);
      setDisabled(false);
    }, 2500);
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <NewLayout bg="get-in-touch">
      <Helmet>
        <title>About Us | Timisoara Startups</title>
      </Helmet>
      <Loading isLoading={loading} />
      <GridWrapper>
        <Grid>
          <Cell md={{ colSpan: 12 }}>
            <Spacer lgheight={'9.6rem'} mdheight={'4.8rem'} />
            <H1>Let’s Get in Touch!</H1>
            <Spacer lgheight={'9.6rem'} mdheight={'2.4rem'} />
          </Cell>
          {/* CONTACT FORM */}
          <Cell lg={{ colSpan: 3 }} md={{ colSpan: 6 }}>
            <Input
              label="First name*"
              name="firstName"
              type="text"
              placeholder="Type here"
              value={contact.firstName}
              handleOnChange={handleInputChange}
              error={errors.firstName}
              disabled={disabled}
            />
          </Cell>
          <Cell lg={{ colSpan: 3 }} md={{ colSpan: 6 }}>
            <Input
              label="Last name*"
              name="lastName"
              type="text"
              placeholder="Type here"
              value={contact.lastName}
              handleOnChange={handleInputChange}
              error={errors.lastName}
              disabled={disabled}
            />
          </Cell>
          <Cell lg={{ colSpan: 3, offset: 1 }} md={{ colSpan: 6 }}>
            <Input
              label="Email address*"
              name="email"
              type="email"
              placeholder="Type here"
              value={contact.email}
              handleOnChange={handleInputChange}
              error={errors.email}
              disabled={disabled}
            />
          </Cell>
          <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 6 }}>
            <Input
              label="Subject*"
              name="subject"
              type="text"
              placeholder="Type here"
              value={contact.subject}
              handleOnChange={handleInputChange}
              error={errors.subject}
              disabled={disabled}
            />
          </Cell>
          <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
            <Textarea
              label="Message*"
              name="message"
              placeholder="Type here"
              value={contact.message}
              onChange={handleInputChange}
              error={errors.message}
              disabled={disabled}
            />
          </Cell>
          <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
            <Spacer lgheight={'1.8rem'} />
            <Checkbox name="terms" value={terms} onClick={setTerms}>
              I have read and agree to the Privacy Policy and Terms of Use
            </Checkbox>
            <Spacer lgheight={'1.8rem'} />
            {termsError && (
              <ParagraphBody style={{ color: colors.error }}>
                You must agree with the Privacy Policy and Terms of Use
              </ParagraphBody>
            )}
          </Cell>
          <Cell lg={{ colSpan: 4, offset: 1 }} md={{ colSpan: 12 }}>
            <Spacer lgheight={'0.4rem'} />
            <Button disabled={disabled} onClick={handleSubmit}>
              Send Message
            </Button>
            <Spacer lgheight={'2.4rem'} />
            {messageSent && <H5 style={{ color: colors.success }}>Message sent.</H5>}
            <Spacer lgheight={'19.2rem'} mdheight={'4.8rem'} />
          </Cell>
          {/* INFO */}
          <Cell lg={{ colSpan: 5, offset: 8, rowSpan: 4, offsetRow: 2 }}>
            <ParagraphBody>VISIT US</ParagraphBody>
            <Spacer lgheight={'1.6rem'} />
            <H4>Cowork The Garden </H4>
            <H4>No.5, Virgil Madgearu Street</H4>
            <H4>300192, Timisoara, Romania</H4>
            <Spacer lgheight={'2.4rem'} />
            <ParagraphBody>EMAIL</ParagraphBody>
            <Spacer lgheight={'1.6rem'} />
            <a href="mailto:hello@timisoarastartups.com">
              <H4>hello@timisoarastartups.com</H4>
            </a>
            <Spacer lgheight={'2.4rem'} />
          </Cell>
        </Grid>
      </GridWrapper>
    </NewLayout>
  );
};
