import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { api } from '../api';
import { Loading, Spacer } from '../components/UI';
import { FilterStartups, Startups, RecommendAStartup, NoResults, NewLayout } from '../components';

export const StartupsPage = () => {
  const [startups, setStartups] = useState([]);
  const [filter, setFilter] = useState({
    search: '',
    industry: 'All',
    funding: 'All',
  });
  const [filteredStartups, setFilteredStartups] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const startups = await api.getAllStartups();
    if (startups) {
      setStartups(startups.filter(startup => startup.publish_status === true));
      setFilteredStartups(startups.filter(startup => startup.publish_status === true));
      setLoading(false);
    }
  };

  //APPLY FILTER
  useEffect(() => {
    let filteredList = startups;
    if (filter.search !== '')
      filteredList = filteredList.filter(item =>
        item.name.toLowerCase().includes(filter.search.toLowerCase())
      );
    if (filter.industry !== 'All')
      filteredList = filteredList.filter(item =>
        item.industry.toLowerCase().includes(filter.industry.toLowerCase())
      );
    if (filter.funding !== 'All')
      filteredList = filteredList.filter(item =>
        item.funding.toLowerCase().includes(filter.funding.toLowerCase())
      );
    setFilteredStartups(filteredList);
    // eslint-disable-next-line
  }, [filter]);

  //FETCH DATA
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <NewLayout bg="startups">
      <Helmet>
        <title>Startups | Timisoara Startups</title>
      </Helmet>
      <Loading isLoading={loading} />
      <Spacer lgheight={'9.6rem'} mdheight={'4.8rem'} />
      <FilterStartups onFilterChange={setFilter} />
      <Spacer lgheight={'2.8rem'} />
      {filteredStartups.length === 0 ? <NoResults /> : <Startups startupsList={filteredStartups} />}
      <Spacer lgheight={'9.6rem'} />
      <RecommendAStartup />
      <Spacer lgheight={'9.6rem'} />
    </NewLayout>
  );
};
