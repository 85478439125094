import styled from "styled-components";
import { colors } from "../../../consts/colors";

export const Container = styled.div`
  padding: 4.8rem 2.4rem;
  border-radius: 1.6rem;
  box-shadow: 0 1.6rem 8px rgba(9, 35, 60, 0.07);
  margin-bottom: 4rem;
`;

export const FeaturedImageWrapper = styled.div`
  position: relative;
  width: 14.8rem;
  height: 14.8rem;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 1.6rem;
`;

export const FeaturedImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
`;

export const Title = styled.p`
  font-size: 2.8rem;
  line-height: 4rem;
  margin-bottom: 8px;
  min-height: 3em;
`;

export const Status = styled.span`
  display: block;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ status }) => (status ? colors.success : colors.error)};
  margin-bottom: 1.6rem;
`;

export const View = styled.span`
  display: block;
  font-size: 1.6rem;
  line-height: 2.4rem;
  cursor: pointer;
`;
