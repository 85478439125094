import styled from 'styled-components';
import { SIZE } from '../../consts';
import { Caption, colors, H1, H4 } from '../UI';

export const Title = styled(H1)``;
export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Subtitle = styled(Caption)`
  color: ${colors.textcaption};
  font-weight: normal;
`;
export const Category = styled(Caption)`
  width: fit-content;
  margin-left: auto;
  padding: 0.2rem 1.6rem;
  border-radius: 0.4rem;
  color: ${colors.textcaption};
  border: 1px solid ${colors.placeholdertext};
  background-color: ${colors.bgdisabled};
`;
export const Image = styled.img`
  display: block;
  width: 100%;
  height: 41.8rem;
  object-fit: cover;
  border-radius: 1.6rem;
  box-shadow: 0 16px 24px 8px rgba(9, 35, 60, 0.07);
  @media (max-width: ${SIZE.SMALL}) {
    height: 20rem;
  }
`;
export const Content = styled.div`
  margin-top: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  font-family: 'Raleway', sans-serif;
  color: ${colors.textmain};
  font-size: 1.6rem;
  line-height: 2.4rem;
  & div.Editor {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    font-family: 'Raleway', sans-serif;
    color: ${colors.textmain};
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  & h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 4.2rem;
    font-weight: 700;
    color: ${colors.textmain};
    line-height: 5rem;
  }
  & h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: ${colors.textmain};
    font-size: 3.6rem;
    line-height: 4.4rem;
  }
  & h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: ${colors.textmain};
    font-size: 2.8rem;
    line-height: 4rem;
  }
  & h4 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: ${colors.textmain};
    font-size: 2rem;
    line-height: 3.2rem;
  }
  & h5 {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: ${colors.textmain};
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  & p {
    font-family: 'Raleway', sans-serif;
    color: ${colors.textmain};
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  & a {
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    color: ${colors.primaryyellow};
    font-size: 1.6rem;
    line-height: 2.4rem;
    &:hover {
      text-decoration: underline;
    }
  }
  & img {
    display: block;
    width: 100%;
    max-height: 41.8rem;
    object-fit: cover;
    border-radius: 1.6rem;
    box-shadow: 0 16px 24px 8px rgba(9, 35, 60, 0.07);
    margin-top: 2.4rem;
    @media (max-width: ${SIZE.SMALL}) {
      max-height: 20rem;
    }
  }
`;

export const Button = styled(H4)`
  display: block;
  width: 100%;
  cursor: pointer;
  padding: 1.6rem;
  border-radius: 0.8rem;
  border: 2px solid ${colors.textmain};
  text-align: center;
`;
