import { H1, H4, Spacer, GridWrapper, Grid, Cell } from "../../UI";
import { BtnStartup, ArrowBtn, ArrowDown } from "./Hero.style";
import { Link } from "react-router-dom";
import { DiscoverShape } from "../..";

export const Hero = () => {
  const goToHref = (id) => {
    document.querySelector(id).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <GridWrapper>
      <Grid>
        <Cell lg={{ colSpan: 12 }} md={{ colSpan: 12 }}>
          <Spacer lgheight={"0rem"} smheight={"4.8rem"} />
        </Cell>
        <Cell lg={{ colSpan: 5 }} md={{ colSpan: 12 }}>
          <Spacer lgheight={"17.8rem"} mdheight={"9.6rem"} />
          <DiscoverShape />
          <Spacer lgheight={"0"} mdheight={"9.6rem"} />
        </Cell>
        <Cell lg={{ colSpan: 7 }} md={{ colSpan: 12 }}>
          <Spacer lgheight={"17.8rem"} mdheight={"0"} smheight={"0"} />
          <H1 align={"left"} malign={"left"}>
            Timisoara-born startups.
          </H1>
          <H1 align={"left"} malign={"left"}>
            Countless dreams.
          </H1>
          <H1 align={"left"} malign={"left"}>
            One community.
          </H1>
          <Spacer lgheight={"2.4rem"} />
          <H4>
            We're collecting and introducing all our local startups—and their
            leaders—to the world.{" "}
          </H4>
          <Spacer lgheight={"4.8rem"} />
          <Link to="/recommend-startup/">
            <BtnStartup>Come join us!</BtnStartup>
          </Link>
          <Spacer lgheight={"9.6rem"} mdheight={"4.8em"} />
        </Cell>
        <Cell md={{ colSpan: 12 }}>
          <ArrowBtn onClick={() => goToHref("#FavouriteStartUp")}>
            <ArrowDown />
          </ArrowBtn>
          <Spacer lgheight={"9.6rem"} />
        </Cell>
      </Grid>
    </GridWrapper>
  );
};
