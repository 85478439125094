import React, { useState, useEffect, useRef } from 'react';
import { startupSchema, membersSchema } from '../validation';
import {
  FormInp,
  FormInputLabel,
  SendBtn,
  AddMember,
  RemoveMember,
  NotePhone,
  DropDownContainer,
  DropDownHeader,
  DropDownListContainer,
  DropDownList,
  ListItem,
  Placeholder,
  ImgLeft,
  ErrorMsg,
  CheckboxContainer,
  StyledCheckbox,
  Icon,
  TextCheckmark,
  NoteCaption,
} from './Forms.style';
import { Cell, colors, Grid, GridWrapper, H5, Spacer, H3, Textarea } from '../UI';
import { api } from '../../api/api';
import { images } from '../../public';
import { ImageUpload } from './ImageUpload/ImageUpload';
import { useClickedOutsideObserver } from '../../hooks';
import { FUNDING, INDUSTRIES, CITIES, STARTUP, MEMBER } from '../../consts';

export const AdminFormStartup = ({ data }) => {
  const [startup, setStartup] = useState(data ? data : STARTUP);
  const [errors, setErrors] = useState({ ...STARTUP });
  const [terms, setTerms] = useState(false);
  const [termsError, setTermsError] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [sent, setSent] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const toggleCity = () => setIsOpen(!isOpen);

  const [isVisible, setIsVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const toggleFunding = () => setIsVisible(!isVisible);

  const [isActive, setIsActive] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const toggleIndustry = () => setIsActive(!isActive);

  const cityDropdownRef = useRef(null);
  const industryDropdownRef = useRef(null);
  const fundingDropdownRef = useRef(null);

  //SCROLL INTO VIEW
  const scrollToErrors = () => {
    for (let key of Object.keys(errors)) {
      if (key !== 'founders' && errors[key] !== '') {
        document
          .querySelector(`[name="${key}"]`)
          ?.parentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        break;
      }
    }
  };
  useEffect(() => {
    if (!disabled) scrollToErrors();
    // eslint-disable-next-line
  }, [disabled]);

  //CLOSE DROPDOWNS
  const closeDropdowns = () => {
    setIsOpen(false);
    setIsActive(false);
    setIsVisible(false);
  };
  useClickedOutsideObserver(cityDropdownRef, closeDropdowns);
  useClickedOutsideObserver(industryDropdownRef, closeDropdowns);
  useClickedOutsideObserver(fundingDropdownRef, closeDropdowns);

  const handleChangeForm = (key, value) => {
    setStartup(prevState => ({
      ...prevState,
      founders: [...prevState.founders],
      [key]: value,
    }));
  };

  const handleChangeTeamMember = (value, field, index) => {
    let founders = [...startup.founders];
    founders[index][field] = value;
    setStartup(prevStartup => ({
      ...prevStartup,
      founders,
    }));
  };

  const handleAddTeamMembers = () => {
    setStartup(prevStartup => ({
      ...prevStartup,
      founders: [...prevStartup.founders, { ...MEMBER }],
    }));
    setErrors(prevStartup => ({
      ...prevStartup,
      founders: [
        ...prevStartup.founders,
        {
          first_name: '',
          last_name: '',
          position: '',
          avatar: '',
        },
      ],
    }));
  };

  const handleRemoveTeamMembers = memberIndex => {
    const newFounders = [...startup.founders];
    newFounders.splice(memberIndex, 1);
    setStartup(prevStartup => ({
      ...prevStartup,
      founders: newFounders,
    }));
    setErrors(prevStartup => ({
      ...prevStartup,
      founders: [
        ...newFounders.map(founder => ({
          first_name: '',
          last_name: '',
          position: '',
          avatar: '',
        })),
      ],
    }));
  };

  //errors
  const setError = (key, value) => {
    setErrors(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };
  const setMemberError = (index, key, value) => {
    setErrors(prevState => {
      const newState = { ...prevState, founders: [...prevState.founders] };
      newState.founders[index][key] = value;
      return newState;
    });
  };
  const clearErrors = () => {
    setErrors({
      ...STARTUP,
      founders:
        errors.founders.constructor === Array
          ? [...startup.founders.map(member => ({ ...MEMBER }))]
          : [],
    });
    setTermsError('');
  };

  const onSubmit = async () => {
    setDisabled(true);
    clearErrors();
    //form validation messages
    Object.keys(startup).forEach(async key => {
      if (key !== 'founders' && startupSchema[key] !== undefined)
        startupSchema[key].validate(startup[key]).catch(err => {
          setError(key, err.message);
        });
    });
    //team members  validation messages
    startup.founders.forEach(async (member, index) => {
      Object.keys(member).forEach(async memberKey => {
        if (membersSchema[memberKey] !== undefined)
          membersSchema[memberKey].validate(member[memberKey]).catch(err => {
            setMemberError(index, memberKey, err.message);
          });
      });
    });
    //terms validation message
    if (!terms) setTermsError('You must agree with the terms and conditions.');
    //form validation
    let isFormValid = true;
    for (let key of Object.keys(startup))
      if (key !== 'founders' && isFormValid && startupSchema[key] !== undefined)
        isFormValid = await startupSchema[key].isValid(startup[key]);
    //members validation
    let isMemberValid = true;
    for (let index of Object.keys(startup.founders))
      for (let key of Object.keys(startup.founders[index]))
        if (isMemberValid && membersSchema[key] !== undefined)
          isMemberValid = await membersSchema[key].isValid(startup.founders[index][key]);

    if (!isFormValid || !isMemberValid || (!data && !terms)) {
      setDisabled(false);
      return;
    }
    //add/edit startup
    // console.log('startup', startup);
    if (data) await api.updateStartup(startup);
    else await api.addStartup(startup);
    //Reset form
    setSent(true);
    setTimeout(() => {
      setSent(false);
      setDisabled(false);
      if (!data) {
        setStartup({ ...STARTUP });
        setSelectedOption('');
        setSelectedValue('');
        setSelectedItem('');
        setTerms(false);
        window.scrollTo(0, 1);
      }
    }, 2000);
  };

  //update form values
  useEffect(() => {
    if (data) {
      setSelectedValue(data.funding);
      setSelectedItem(data.industry);
      setSelectedOption(data.city);
      setErrors(prevState => ({
        ...prevState,
        founders: [...data.founders.map(founder => ({ ...MEMBER }))],
      }));
      setStartup(data);
    }
  }, [data]);

  return (
    <GridWrapper>
      <Grid>
        <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
          <FormInputLabel>Startup Name*</FormInputLabel>
        </Cell>
        <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
          <FormInp
            placeholder={'Type here'}
            type="text"
            name="name"
            value={startup.name}
            onChange={e => handleChangeForm(e.target.name, e.target.value)}
          />
          <ErrorMsg>{errors.name}</ErrorMsg>
          <Spacer lgheight={'1.6rem'} />
        </Cell>
        <Cell lg={{ colSpan: 5, offset: 8, rowSpan: 2 }} md={{ colSpan: 12 }}>
          <ImageUpload
            name="logo"
            image={startup.logo}
            onSetImage={image => handleChangeForm('logo', image)}
          />
          <ErrorMsg>{errors.logo}</ErrorMsg>
        </Cell>
        <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
          <Spacer mdheight={'2.4rem'} />
          <FormInputLabel>Short description*</FormInputLabel>
          <Textarea
            name="description"
            placeholder="Type here"
            value={startup.description}
            onChange={handleChangeForm}
          />
          <ErrorMsg>{errors.description}</ErrorMsg>
        </Cell>

        <Cell lg={{ colSpan: 3, offset: 1 }} md={{ colSpan: 6 }}>
          <Spacer lgheight={'1.6rem'} mdheight={'1.6rem'} />
          <FormInputLabel>Startup URL*</FormInputLabel>
          <FormInp
            placeholder={'Type here'}
            type="text"
            name="website"
            value={startup.website}
            onChange={e => handleChangeForm(e.target.name, e.target.value)}
          />
          <ErrorMsg>{errors.website}</ErrorMsg>
        </Cell>
        <Cell lg={{ colSpan: 3 }} md={{ colSpan: 6 }}>
          <Spacer lgheight={'1.6rem'} mdheight={'1.6rem'} />
          <FormInputLabel>Official email address*</FormInputLabel>
          <FormInp
            placeholder={'Type here'}
            type="text"
            name="email"
            value={startup.email}
            onChange={e => handleChangeForm(e.target.name, e.target.value)}
          />
          <ErrorMsg>{errors.email}</ErrorMsg>
        </Cell>

        <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
          <Spacer lgheight={'1.6rem'} />
          <FormInputLabel>Official phone number</FormInputLabel>
          <FormInp
            placeholder={'Type here'}
            type="text"
            name="phone"
            value={startup.phone}
            onChange={e => handleChangeForm(e.target.name, e.target.value)}
          />
          <ErrorMsg>{errors.phone}</ErrorMsg>
          <NotePhone>
            Please provide a valid phone number. This is our primary registration check. Number will
            not be visible on the webite.
          </NotePhone>
        </Cell>

        <Cell lg={{ colSpan: 3, offset: 1 }} md={{ colSpan: 6, offset: 1 }} style={{ zIndex: 6 }}>
          <Spacer lgheight={'1.6rem'} />
          <FormInputLabel>City*</FormInputLabel>
          <DropDownContainer>
            <DropDownHeader onClick={toggleCity}>
              {selectedOption || <Placeholder>Please select</Placeholder>}
              <ImgLeft src={images.down}></ImgLeft>
            </DropDownHeader>
            {isOpen && (
              <DropDownListContainer ref={cityDropdownRef}>
                <DropDownList>
                  {CITIES.map(option => (
                    <ListItem
                      onClick={() => {
                        setSelectedOption(option);
                        setIsOpen(false);
                        handleChangeForm('city', option);
                      }}
                      key={option}
                    >
                      {option}
                    </ListItem>
                  ))}
                </DropDownList>
              </DropDownListContainer>
            )}
          </DropDownContainer>
          <ErrorMsg>{errors.city}</ErrorMsg>
        </Cell>
        <Cell lg={{ colSpan: 3 }} md={{ colSpan: 6 }}>
          <Spacer lgheight={'1.6rem'} />
          <FormInputLabel>Postal Code</FormInputLabel>
          <FormInp
            placeholder={'Type here'}
            type="text"
            name="postal_code"
            value={startup.postal_code}
            onChange={e => handleChangeForm(e.target.name, e.target.value)}
          />
          <ErrorMsg>{errors.postal_code}</ErrorMsg>
        </Cell>

        <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
          <Spacer lgheight={'1.6rem'} />
          <FormInputLabel>Physical address</FormInputLabel>
          <FormInp
            placeholder={'Type here'}
            type="text"
            name="address"
            value={startup.address}
            onChange={e => handleChangeForm(e.target.name, e.target.value)}
          />
          <ErrorMsg>{errors.address}</ErrorMsg>
        </Cell>

        <Cell lg={{ colSpan: 3, offset: 1 }} md={{ colSpan: 6, offset: 1 }} style={{ zIndex: 5 }}>
          <Spacer lgheight={'1.6rem'} />
          <FormInputLabel>Funding*</FormInputLabel>
          <Spacer lgheight={'0.4rem'} />
          <DropDownContainer>
            <DropDownHeader onClick={toggleFunding}>
              {selectedValue || <Placeholder>Select</Placeholder>}
              <ImgLeft src={images.down}></ImgLeft>
            </DropDownHeader>
            {isVisible && (
              <DropDownListContainer ref={fundingDropdownRef}>
                <DropDownList>
                  {FUNDING.map(value => (
                    <ListItem
                      value=""
                      name="funding"
                      onClick={() => {
                        setSelectedValue(value);
                        setIsVisible(false);
                        handleChangeForm('funding', value);
                      }}
                      key={value}
                    >
                      {value}
                    </ListItem>
                  ))}
                </DropDownList>
              </DropDownListContainer>
            )}
          </DropDownContainer>
          <ErrorMsg>{errors.funding}</ErrorMsg>
        </Cell>

        <Cell lg={{ colSpan: 3 }} md={{ colSpan: 6 }} style={{ zIndex: 4 }}>
          <Spacer lgheight={'1.6rem'} />
          <FormInputLabel>Industry*</FormInputLabel>
          <Spacer lgheight={'0.4rem'} />
          <DropDownContainer>
            <DropDownHeader onClick={toggleIndustry}>
              {selectedItem || <Placeholder>Select</Placeholder>}
              <ImgLeft src={images.down}></ImgLeft>
            </DropDownHeader>
            {isActive && (
              <DropDownListContainer ref={industryDropdownRef}>
                <DropDownList>
                  {INDUSTRIES.map(item => (
                    <ListItem
                      value=""
                      name="industry"
                      onClick={() => {
                        setSelectedItem(item);
                        setIsActive(false);
                        handleChangeForm('industry', item);
                      }}
                      key={item}
                    >
                      {item}
                    </ListItem>
                  ))}
                </DropDownList>
              </DropDownListContainer>
            )}
          </DropDownContainer>
          <ErrorMsg>{errors.industry}</ErrorMsg>
        </Cell>

        <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
          <Spacer lgheight={'4.8rem'} />
          <H3>Social media</H3>
          <Spacer lgheight={'2.4rem'} />
        </Cell>

        <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
          <FormInputLabel>{'Linkedin page URL'}</FormInputLabel>
          <FormInp
            placeholder={'Type here'}
            name="linkedin"
            type="text"
            value={startup.linkedin}
            onChange={e => handleChangeForm(e.target.name, e.target.value)}
          />
          <Spacer lgheight={'1.6rem'} />
        </Cell>
        <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
          <FormInputLabel>{'Facebook page URL'}</FormInputLabel>
          <FormInp
            placeholder={'Type here'}
            name="facebook"
            type="text"
            value={startup.facebook}
            onChange={e => handleChangeForm(e.target.name, e.target.value)}
          />
          <Spacer lgheight={'1.6rem'} />
        </Cell>
        <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
          <FormInputLabel>{'Instagram page URL'}</FormInputLabel>
          <FormInp
            placeholder={'Type here'}
            name="instagram"
            type="text"
            value={startup.instagram}
            onChange={e => handleChangeForm(e.target.name, e.target.value)}
          />
        </Cell>
        <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
          <Spacer lgheight={'4.8rem'} />
          <H3>Team members</H3>
          <Spacer lgheight={'0.4rem'} />
        </Cell>

        {/* START TEAM MEMBERS LOGIC */}
        {startup.founders?.map((member, index) => (
          <Cell key={index} lg={{ colSpan: 12, offset: 1 }} md={{ colSpan: 12 }}>
            <Grid>
              <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
                <FormInputLabel>{'First name*'}</FormInputLabel>
                <FormInp
                  placeholder="Type here"
                  type="text"
                  name={`first_name_${index}`}
                  value={startup.founders[index] ? startup.founders[index].first_name : ''}
                  onChange={e => handleChangeTeamMember(e.target.value, 'first_name', index)}
                />
                <ErrorMsg>{errors.founders[index].first_name}</ErrorMsg>
              </Cell>
              <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
                <FormInputLabel>{'Last name*'}</FormInputLabel>
                <FormInp
                  placeholder={'Type here'}
                  type="text"
                  name={`last_name_${index}`}
                  value={startup.founders[index] ? startup.founders[index].last_name : ''}
                  onChange={e => handleChangeTeamMember(e.target.value, 'last_name', index)}
                />
                <ErrorMsg>{errors.founders[index].last_name}</ErrorMsg>
              </Cell>

              <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
                <Spacer lgheight={'2.4rem'} />
                <FormInputLabel>{'Position*'}</FormInputLabel>
                <FormInp
                  placeholder={'Type here'}
                  type="text"
                  name={`position_${index}`}
                  value={startup.founders[index] ? startup.founders[index].position : ''}
                  onChange={e => handleChangeTeamMember(e.target.value, 'position', index)}
                />
                <ErrorMsg>{errors.founders[index].position}</ErrorMsg>
              </Cell>

              <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
                <Spacer lgheight={'2.4rem'} />
                <ImageUpload
                  compact
                  image={startup.founders[index] ? startup.founders[index].avatar : ''}
                  onSetImage={image => handleChangeTeamMember(image, 'avatar', index)}
                />
                <Spacer lgheight={'1.2rem'} mdheight={'0.6rem'} />
                <ErrorMsg>{errors.founders[index].avatar}</ErrorMsg>
                <Spacer lgheight={'0.8rem'} mdheight={'0.8rem'} />
                <RemoveMember onClick={() => handleRemoveTeamMembers(index)}>
                  - Remove team member
                </RemoveMember>
                <Spacer lgheight={'2.4rem'} mdheight={'2.4rem'} />
              </Cell>
            </Grid>
          </Cell>
        ))}

        {/* END TEAM MEMBERS LOGIC */}

        {/* TERMS */}
        {!data && (
          <Cell md={{ colSpan: 12 }}>
            <label
              htmlFor="terms"
              onClick={() => {
                setTerms(!terms);
              }}
            >
              <CheckboxContainer>
                <StyledCheckbox type="checkbox" name="terms" defaultChecked={terms}>
                  <Icon viewBox="0 0 24 24">
                    <polyline points="20 6 9 17 4 12" />
                  </Icon>
                </StyledCheckbox>
              </CheckboxContainer>
              <TextCheckmark>
                I have read and agree to the Privacy Policy and Terms of Use
              </TextCheckmark>
            </label>
            {termsError !== '' && <ErrorMsg>{termsError}</ErrorMsg>}
            <Spacer lgheight={'2.4rem'} />
          </Cell>
        )}
        <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
          <AddMember onClick={() => handleAddTeamMembers()}>
            {startup.founders.length > 0 ? '+ Add another team member' : '+ Add team member'}
          </AddMember>
          <Spacer lgheight={'4.8rem'} />
        </Cell>
        <Cell lg={{ colSpan: 6, offset: 1 }} md={{ colSpan: 12 }}>
          <SendBtn onClick={onSubmit} type="button" visible={'0.8'} disabled={disabled}>
            {data ? 'Update Startup' : 'Send for review'}
          </SendBtn>
          {!data && (
            <>
              <Spacer lgheight={'1.6rem'} />
              <NoteCaption>
                Please allow between 24 and 72 hours for reviewing your application
              </NoteCaption>
            </>
          )}
          <Spacer lgheight={'2.4rem'} />
          {sent && (
            <H5 color={colors.success}>{data ? 'Changes saved!' : 'Startup sent for review!'}</H5>
          )}
          <Spacer lgheight={'9.6rem'} />
        </Cell>
      </Grid>
    </GridWrapper>
  );
};
