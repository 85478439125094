import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { api } from '../api';
import { Loading, Spacer } from '../components/UI';
import {
  FilterStakeholders,
  NewLayout,
  NoResults,
  RecommendAStakeholder,
  Stakeholders,
} from '../components';

export const StakeholdersPage = () => {
  const [stakeholders, setStakeholders] = useState([]);
  const [filter, setFilter] = useState({
    search: '',
    industry: 'All',
    funding: 'All',
  });
  const [filteredStakeholders, setFilteredStakeholders] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const stakeholdersList = await api.getAllStakeholders();
    if (stakeholdersList) {
      setStakeholders(stakeholdersList.filter(stakeholder => stakeholder.publish_status === true));
      setFilteredStakeholders(
        stakeholdersList.filter(stakeholder => stakeholder.publish_status === true)
      );
      setLoading(false);
    }
  };

  //APPLY FILTER
  useEffect(() => {
    let filteredList = stakeholders;
    if (filter.search !== '')
      filteredList = filteredList.filter(item =>
        (item.firstName + ' ' + item.lastName).toLowerCase().includes(filter.search.toLowerCase())
      );
    if (filter.position !== 'All')
      filteredList = filteredList.filter(item =>
        item.role
          .reduce((prev, curr) => prev + curr)
          .toLowerCase()
          .includes(filter.position.toLowerCase())
      );
    setFilteredStakeholders(filteredList);
    // eslint-disable-next-line
  }, [filter]);

  //FETCH DATA
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <NewLayout bg="stakeholders">
      <Helmet>
        <title>Community | Timisoara Startups</title>
      </Helmet>
      <Loading isLoading={loading} />
      <Spacer lgheight={'9.6rem'} mdheight={'4.8rem'} />
      <FilterStakeholders onFilterChange={setFilter} />
      <Spacer lgheight={'2.8rem'} />
      {filteredStakeholders.length === 0 ? (
        <NoResults />
      ) : (
        <Stakeholders stakeholdersList={filteredStakeholders} />
      )}
      <Spacer lgheight={'9.6rem'} />
      <RecommendAStakeholder />
      <Spacer lgheight={'9.6rem'} />
    </NewLayout>
  );
};
