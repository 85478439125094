import styled from 'styled-components';
import { colors } from '../Typography';

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const Label = styled.label`
  display: block;
  font-size: 1.2rem;
  line-height: 2rem;
  color: ${colors.textmain};
  width: 100%;
  margin-bottom: 4px;
  font-weight: 600;
`;

export const Wrapper = styled.div`
  border: 1px solid ${colors.placeholdertext};
  border-radius: 8px;
  overflow: hidden;
  &::placeholder {
    color: ${colors.placeholdertext};
  }
  &:focus-within {
    border: 1px solid ${colors.primaryyellow};
  }
`;

export const Text = styled.textarea`
  display: block;
  width: 100%;
  margin: 0;
  height: 9.2rem;
  font-family: 'Raleway', sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  border: none;
  resize: none;
  color: ${colors.textmain};
  padding: 8px;
`;

export const Error = styled.div`
  width: 100%;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${colors.error};
`;
