import styled from 'styled-components';
import { colors } from '../../consts/colors';
import { H1, ParagraphBody } from '../common/common.style';
import { images } from '../../public';

export const BtnConnect = styled.div`
  cursor: pointer;
  display: inline-block;
  background-color: white;
  padding: 1.6rem 5.5rem;
  width: 100%;
  height: auto;
  font-weight: 600;
  font-size: 2rem;
  font-family: 'Montserrat', sans-serif;
  line-height: 3.2rem;
  text-align: center;
  border-radius: 0.8rem;
  color: ${colors.textmain};
  & a {
    color: ${colors.textmain};
  }
`;

export const Background = styled.div`
  width: 100%;
  padding-left: 0rem;
  padding-right: 0rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  background-image: ${`url(${images.sectionLandingShapes})`};
`;

export const DisplayFlex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: space-between;
`;

export const H1Banner = styled(H1)`
  font-family: 'Montserrat', sans-serif;
  font-size: 4.2rem;
  line-height: 5rem;
  font-weight: bold;
  color: ${colors.bgdisabled};
  ${({ half }) => half && `width: 50%`};
  ${({ align }) => align && `text-align: ${align}`};
`;

export const ParagraphBodyBanner = styled(ParagraphBody)`
  color: ${colors.bgdisabled};
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  position: relative;
`;

export const Height = styled.div`
  height: 4.2rem;
`;
