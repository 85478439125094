import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Spacer, GridWrapper, Grid, Cell } from "../UI";
import {
  Background,
  BtnConnect,
  H1Banner,
  ParagraphBodyBanner,
} from "./Banner.style";

//SCROLL FUNCTION
const handleScroll = () => {
  const element = document.querySelector("#section-bg");
  if (element) {
    const top = element.getBoundingClientRect().top;
    const bottom = element.getBoundingClientRect().bottom;
    const width = window.innerWidth || document.documentElement.clientWidth;
    const height = window.innerHeight || document.documentElement.clientHeight;
    if (bottom > 0 && top <= height) {
      let newPos = 0;
      if (width > 1920) newPos = `0px ${-500 + (top - height) * 0.2}px`;
      else if (width > 720) newPos = `0px ${-200 + (top - height) * 0.2}px`;
      else newPos = `0px ${-150 - (top - height) * 0.2}px`;
      element.style.backgroundPosition = newPos;
    }
  }
};

export const GetInTouch = () => {
  //ADD LISTENERS
  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <Background id="section-bg">
      <GridWrapper>
        <Grid>
          <Cell
            lg={{ colSpan: 5, rowSpan: 2 }}
            md={{ colSpan: 6, rowSpan: 2 }}
          ></Cell>
          <Cell lg={{ colSpan: 7 }} md={{ colSpan: 12 }}>
            <Spacer lgheight={"9.6rem"} />
            <H1Banner align={"left"}>Contribute</H1Banner>
            <Spacer lgheight={"2.4rem"} />
            <ParagraphBodyBanner>
              We're always on the lookout for great people and startups to
              connect with and invite them to our platform. Feel free to join us
              or recommend someone who would be happy to be part of our
              community.
            </ParagraphBodyBanner>
          </Cell>
          <Cell lg={{ colSpan: 4 }} md={{ colSpan: 6 }}>
            <Spacer lgheight={"4.8rem"} mdheight={"1.6rem"} />
            <Link to="/recommend-startup">
              <BtnConnect>RECOMMEND A STARTUP</BtnConnect>
            </Link>
            <Spacer lgheight={"9.6rem"} />
          </Cell>
        </Grid>
      </GridWrapper>
    </Background>
  );
};
