import styled from 'styled-components';
import { colors } from '../../../consts/colors';
import { images } from '../../../public';

export const BtnStartup = styled.div`
  cursor: pointer;
  display: inline-block;
  min-width: 3.8rem;
  width: 100%;
  background-color: ${colors.primaryyellow};
  padding: 1.6rem 0rem;
  height: auto;
  font-weight: 600;
  font-size: 2rem;
  font-family: 'Montserrat', sans-serif;
  line-height: 3.2rem;
  text-align: center;
  border-radius: 0.8rem;
  color: ${colors.textmain};

  @media (max-width: 1199px) {
    padding: 1.6rem 2.75rem;
  }
  @media (max-width: 767px) {
    padding: 1.6rem 2.75rem;
  }
`;

export const BackgroundImg = styled.div`
  position: absolute;
  width: 100%;
  top: 10%;
  right: -51%;
  overflow-x: hidden;
  background-repeat: no-repeat;
  background-size: contain;
  height: 1088px;
  background-image: ${`url(${images.shapeWithShadow})`};

  @media (max-width: 1199px) {
    top: 3%;
    right: 0rem;
    overflow-x: hidden;
    background-repeat: no-repeat;
    background-size: contain;
    width: 80%;
  }
  @media (max-width: 767px) {
    top: 5rem;
    right: 0rem;
    height: 60%;
    width: 60%;
  }
`;

export const BluredShapeTop = styled.div`
  position: absolute;
  width: 100%;
  top: -60%;
  right: 40%;
  overflow-x: hidden;
  background-repeat: no-repeat;
  background-size: contain;
  height: 1088px;
  background-size: contain;
  height: 1088px;
  background-image: ${`url(${images.topleftBlurredShape})`};

  @media (max-width: 1199px) {
    top: 3%;
    right: 0rem;
    overflow-x: hidden;
    background-repeat: no-repeat;
    background-size: contain;
    width: 80%;
  }
  @media (max-width: 767px) {
    top: 5rem;
    right: 0rem;
    height: 60%;
    width: 60%;
  }
`;

export const Page = styled.div`
  min-height: 80vh;
`;
