import styled from "styled-components";
import { colors } from "../../../consts/colors";
import {
  ParagraphBody,
  ParagraphCaption,
  Caption,
  H3,
} from "../../common/common.style";

export const BtnStakeholders = styled.div`
  cursor: pointer;
  display: inline-block;
  width: 100%;
  font-weight: 600;
  background-color: transparent;
  border: 2px solid ${colors.textmain};
  border-radius: 0.8rem;
  padding: 1.4rem 0rem;
  height: auto;
  font-size: 2rem;
  font-family: "Montserrat", sans-serif;
  line-height: 3.2rem;

  a {
    color: ${colors.textmain};
  }
`;

export const CardStakeholder = styled.div`
  cursor: pointer;
  width: 100%;
  border-radius: 1.6rem;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 2px rgba(9, 35, 60, 0.05);
  transition: all 0.3s;
  :hover {
    box-shadow: 0 16px 24px 8px rgba(9, 35, 60, 0.07);
  }
  @media (max-width: 1199px) {
    display: inline-block;
    width: 100%;
    height: auto;
  }
  @media (max-width: 767px) {
    width: 100%;
    height: auto;
  }
`;
export const CardImg = styled.img`
  max-width: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const ImgContainer = styled.div`
  align-items: center;
  display: inline-block;
  margin: 2.4rem 9.6rem 2.4rem 9.6rem;
  border-radius: 50%;
  height: 18.8rem;
  width: 18.8rem;
  overflow: hidden;
  position: relative;
  @media (max-width: 1199px) {
    display: block;
    align-items: center;
    margin-top: 2.4rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2.4rem;
  }
`;

export const CardContainer = styled.div`
  padding: 0rem 1.6rem 2.4rem 1.6rem;
  @media (max-width: 1199px) {
  }
  @media (max-width: 767px) {
  }
`;
export const CardTitle = styled(H3)`
  color: ${colors.textmain};
  font-family: "Montserrat", sans-serif;
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 4rem;
  text-align: center;
`;

export const SubtitleParagraph = styled(ParagraphBody)`
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  color: ${({ color }) => (color ? color : colors.textcaption)};
  font-size: 1.6rem;
  line-height: 2.4rem;
  min-height: 5em;
  ${({ align }) => align && `text-align: ${align}`};
  & span {
    &::after {
      content: ", ";
    }
    &:last-child::after {
      content: "";
    }
  }
  @media (max-width: 1199px) {
    min-height: 5em;
    font-size: 1.6rem;
    ${({ talign }) => talign && `text-align: ${talign}`};
    ${({ mdTopH1 }) => mdTopH1 && `margin-top: ${mdTopH1}`};
  }
  @media (max-width: 767px) {
    margin: auto;
    max-width: 250px;
    ${({ malign }) => malign && `text-align: ${malign}`};
    ${({ msTopH1 }) => msTopH1 && `margin-top: ${msTopH1}`};
  }
`;

export const TextCard = styled(ParagraphCaption)`
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: "Raleway", sans-serif;
  color: ${({ color }) => (color ? color : colors.textcaption)};
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const SmLogo = styled.img`
  object-fit: contain;
  margin: auto;
  display: inline-block;
`;

export const SmLogoDisplay = styled.div`
  display: flex;
  width: 12rem;
  margin: auto;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const Subtitle = styled(Caption)`
  font-family: "Raleway", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2rem;
  color: ${colors.textmain};
`;

export const FormInp = styled.input`
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${colors.textmain};
  display: block;
  width: 100%;
  justify-content: flex-start;
  margin-right: 3.2rem;
  padding: 0.7rem 0.7rem 0.7rem 3.2rem;
  border: 0.1rem solid ${colors.placeholdertext};
  border-radius: 0.8rem;
  transition: border 0.3s;
  &:hover {
    border: 1px solid ${colors.primaryyellow};
  }

  &::placeholder {
    color: ${colors.placeholdertext};
  }
  &:not(:placeholder-shown) {
    border: 1px solid ${colors.primaryyellow};
  }
  @media (max-width: 1199px) {
  }
  @media (max-width: 767px) {
  }
`;

export const FormInputLabel = styled.label`
  font-family: "Raleway", sans-serif;
  display: block;
  font-size: 1.2rem;
  pointer-events: none;
  line-height: 2rem;
  font-weight: 600;
  color: ${colors.textmain};
`;

export const SelectPosition = styled.select`
  width: 100%;
  padding: 0.7rem 4rem 0.7rem 0.7rem;
  border: 0.1rem solid ${colors.placeholdertext};
  border-radius: 0.8rem;
  font-family: "Raleway", sans-serif;
  font-size: 1.2rem;
  line-height: 2.4rem;
  font-weight: 600;
  color: ${colors.textmain};
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
    no-repeat right ${colors.white};
  -webkit-appearance: none;
  background-position-x: 24.4rem;
  :hover {
    border: 1px solid ${colors.primaryyellow};
  }
`;

export const InputGruop = styled.div`
  position: relative;
`;

export const Div = styled.div`
  position: relative;
`;
export const SearchInp = styled.div`
  font-family: "Raleway", sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${colors.textmain};
  display: block;
  width: 100%;
  letter-spacing: 0;
  justify-content: flex-start;
  padding: 0.7rem 3.2rem;
  border: 0.1rem solid ${colors.placeholdertext};
  border-radius: 0.8rem;
  transition: border 0.3s;
  :hover {
    border: 1px solid ${colors.primaryyellow};
  }
  &::placeholder {
    color: ${colors.placeholdertext};
  }
  @media (max-width: 1199px) {
  }
  @media (max-width: 767px) {
  }
`;
export const ImgRight = styled.img`
  position: absolute;
  top: 1.1rem;
  right: 0.8rem;
  height: 1.6rem;
  width: 1.6rem;
`;
export const ImgLeft = styled.img`
  position: absolute;
  top: 1.1rem;
  left: 0.8rem;
  right: 0.8rem;
  height: 1.6rem;
  width: 1.6rem;
`;
export const DropdownContent = styled.div`
  position: absolute;
  width: 100%;
  border-radius: 0.8rem;
  background-color: #ffffff;
  box-shadow: 0 16px 24px 8px rgba(9, 35, 60, 0.07);
  z-index: 1;
  & a {
    line-height: 2.4rem;
    font-size: 1.6rem;
    font-weight: 500;
    font-family: "Raleway" sans-serif;
    padding: 0.4rem 1.6rem;
    text-decoration: none;
    display: block;
    text-align: left;
    color: ${colors.textmain};
    :focus {
      background-color: ${colors.primaryyellow};
    }
  }
`;

export const DropDownContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

export const DropDownHeader = styled.div`
  position: relativ;
  width: 100%;
  padding: 0.7rem 0.7rem 0.7rem 0.7rem;
  border: 0.1rem solid ${colors.placeholdertext};
  background-color: ${colors.white};
  border-radius: 0.8rem;
  font-family: "Raleway", sans-serif;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  color: ${colors.textmain};
  :hover {
    border: 1px solid ${colors.primaryyellow};
  }
`;

export const DropDownListContainer = styled.div`
  position: absolute;
  z-index: 9999;
  width: 100%;
`;

export const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  background: #ffffff;
  width: 100%;
  border-radius: 0.8rem;
  font-family: "Raleway", sans-serif;
  color: ${colors.textmain};
  font-size: 1.6rem;
  line-height: 2.4rem;
  box-shadow: 0 16px 24px 8px rgba(9, 35, 60, 0.07);
  &:first-child {
    padding-top: 0.8em;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  line-height: 2.4rem;
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "Raleway" sans-serif;
  padding: 0.4rem 1.6rem;
  text-decoration: none;
  display: block;
  text-align: left;
  color: ${colors.textmain};
  :hover {
    background-color: ${colors.primaryyellow};
    border-radius: 0.8rem;
  }
`;

export const ListValue = styled.li`
  list-style: none;
  line-height: 2.4rem;
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "Raleway" sans-serif;
  padding: 0.4rem 1.6rem;
  text-decoration: none;
  display: block;
  text-align: left;
  color: ${colors.textmain};
  :hover {
    background-color: #faa31b;
  }
`;

export const Img = styled.img`
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  height: 2.4rem;
  width: 2.4rem;
`;

export const Placeholder = styled.p`
  color: ${colors.placeholdertext};
  font-family: "Raleway";
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const PlaceholderSearch = styled.p`
  color: ${colors.placeholdertext};
  font-family: "Raleway";
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding-left: 2.4rem;
`;
export const RecomandStakeholder = styled.div`
  cursor: pointer;
  display: inline-block;
  min-width: 3.8rem;
  width: 100%;
  background-color: ${colors.primaryyellow};
  padding: 1.6rem 0rem;
  height: auto;
  font-weight: 600;
  font-size: 2rem;
  font-family: "Montserrat", sans-serif;
  line-height: 3.2rem;
  text-align: center;
  border-radius: 0.8rem;
  & a {
    color: ${colors.textmain};
  }

  @media (max-width: 1199px) {
    padding: 1.6rem 1.75rem;
  }
  @media (max-width: 767px) {
    padding: 1.6rem 1.75rem;
  }
`;
