import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { AdminFormStakeholder, NewLayout } from '../components';
import { H1, H3, ParagraphBody, GridWrapper, Grid, Cell, Spacer, Loading } from '../components/UI';

export const SuggestStakeholder = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <NewLayout bg="new-stakeholder">
      <Helmet>
        <title>Add new member | Timisoara Startups</title>
      </Helmet>
      <Loading isLoading={loading} />
      <Spacer lgheight={'9.6rem'} mdheight={'4.8rem'} />
      <GridWrapper>
        <Grid>
          <Cell lg={{ colSpan: 6 }} md={{ colSpan: 12 }}>
            <H1 align={'left'}>Recommend a new stakeholder</H1>
            <Spacer lgheight={'2.4rem'} />
            <ParagraphBody>
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
              laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
              architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas
              sit aspernatur aut odit aut fugit.
            </ParagraphBody>
            <Spacer lgheight={'4.8rem'} />
            <H3>General information</H3>
          </Cell>
        </Grid>
      </GridWrapper>
      <Spacer lgheight={'2.4rem'} />
      <AdminFormStakeholder />
    </NewLayout>
  );
};
