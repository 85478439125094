import { H1, H4, Spacer, Row, Container, Col } from "../../common/common.style";
import {
  BtnStartup,
  BackgroundImg,
  BluredShapeTop,
  Page,
} from "./NotFound.style";

import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <Page>
      <BackgroundImg></BackgroundImg>
      <Container>
        <Row>
          <BluredShapeTop></BluredShapeTop>
          <Col lg={{ size: 7, offset: 0 }} md={{ size: 4 }} sm>
            <Spacer lgheight={"30rem"} mdheight={"45vh"} smheight={"25vh"} />
            <H1 align={"left"} malign={"left"}>
              404 - How did you get here?
            </H1>
          </Col>
        </Row>
      </Container>
      <Spacer lgheight={"2.4rem"} />
      <Container>
        <Row>
          <Col lg={{ size: 7, offset: 0 }} md={{ size: 4 }} sm>
            <H4>It looks like the page you are looking for is not here.</H4>
            <Spacer lgheight={"4.8rem"} />
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 4 }} md={{ size: 2 }} sm>
            <Link to="/">
              <BtnStartup>TAKE ME HOME</BtnStartup>
            </Link>
          </Col>
        </Row>
      </Container>
      <Spacer lgheight={"23rem"} mdheight={"4.8em"} />
    </Page>
  );
};
