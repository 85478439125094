import React, { useState, useEffect } from "react";
import { FUNDING, INDUSTRIES } from "../../consts";
import { images } from "../../public";
import { Grid, GridWrapper, Cell, Input, H1, Spacer, Dropdown } from "../UI";

//CONSTS
const industryCategories = ["All", ...INDUSTRIES];
const fundingCategories = ["All", ...FUNDING];

export const FilterStartups = ({ onFilterChange }) => {
  const [filter, setFilter] = useState({
    search: "",
    industry: industryCategories[0],
    funding: fundingCategories[0],
  });

  const handleFilterChange = (key, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  //UPDATE FORM ON FLITER CHANGE
  useEffect(() => {
    onFilterChange(filter);
    // eslint-disable-next-line
  }, [filter]);

  return (
    <GridWrapper style={{ zIndex: 2 }}>
      <Grid>
        <Cell md={{ colSpan: 12 }}>
          <H1>Find a Startup</H1>
          <Spacer lgheight={"2.9rem"} mdheight={"0"} />
        </Cell>
        <Cell>
          <Input
            label="Name"
            name="search"
            type="text"
            placeholder="Search"
            image={images.search}
            value={filter.search}
            handleOnChange={handleFilterChange}
          />
        </Cell>
        <Cell style={{ zIndex: 5 }}>
          <Dropdown
            label="Industry"
            name="industry"
            selected={filter.industry}
            values={industryCategories}
            handleOnChange={handleFilterChange}
          />
        </Cell>
        <Cell>
          <Dropdown
            label="Funding status"
            name="funding"
            selected={filter.funding}
            values={fundingCategories}
            handleOnChange={handleFilterChange}
          />
        </Cell>
      </Grid>
    </GridWrapper>
  );
};
