import styled, { keyframes } from 'styled-components';
import { SIZE } from '../../consts';

const pink = keyframes`
  0% {
    transform: translate(0) scale(1);
  }
  100% {
    transform: translate(15px, -15px) scale(1.05);
  }
  `;

const orange = keyframes`
  0% {
    transform: translate(0) scale(0.95);
  }

  100% {
    transform: translate(-15px, 0px); scale(1.1);
  }

`;

export const Container = styled.div`
  position: relative;
  margin: auto;
  padding-bottom: 50%;
  width: 100%;
  @media (max-width: ${SIZE.MEDIUM}) {
    margin-top: -5rem;
    margin-bottom: -10rem;
  }
  @media (max-width: ${SIZE.SMALL}) {
    margin-top: 0;
    margin-bottom: 0;
  }
  & img {
    position: absolute;
    inset: calc(min(200px, 50vw) * -1);
    width: calc(100% + 2 * min(200px, 50vw));
    max-width: 400px;
    margin: auto;
    &.pink {
      animation: ${pink} 5s ease-in-out infinite alternate-reverse;
    }
    &.orange {
      animation: ${orange} 4s ease-in-out infinite alternate-reverse;
    }
    &.blur {
      max-width: 70rem;
      @media (max-width: ${SIZE.MEDIUM}) {
        width: 55rem;
        left: -10rem;
      }
    }
    //SMALL
    @media (max-width: ${SIZE.MEDIUM}) {
      inset: 0;
      width: 100%;
    }
  }
`;
