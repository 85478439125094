import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { api } from '../../api';
import { Helmet } from 'react-helmet-async';
import { images } from '../../public';
import {
  Cell,
  Grid,
  GridWrapper,
  Input,
  H4,
  BtnLogin,
  Spacer,
  colors,
  ParagraphBody,
  Loading,
} from '../../components/UI';
import { loginSchema } from '../../components/validation';

export const LoginPage = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({ email: '', password: '' });
  const [disabled, setDisabled] = useState(false);
  const [invalidAuth, setInvalidAuth] = useState(false);

  const handleInputValueChange = (input, value) => {
    setAuth(prevState => ({
      ...prevState,
      [input]: value,
    }));
  };

  const handleSetError = (input, value) => {
    setErrors(prevState => ({
      ...prevState,
      [input]: value,
    }));
  };
  const handleClearErrors = () => {
    setErrors({ email: '', password: '' });
    setInvalidAuth(false);
  };

  const handleSubmit = async () => {
    if (!disabled) {
      setDisabled(true);
      handleClearErrors();
      //form validation messages
      Object.keys(auth).forEach(async key => {
        loginSchema[key].validate(auth[key]).catch(err => {
          handleSetError(key, err.message);
        });
      });
      //form validation
      let isFormValid = true;
      for (let key of Object.keys(auth))
        if (isFormValid) isFormValid = await loginSchema[key].isValid(auth[key]);
      if (!isFormValid) {
        setDisabled(false);
        return;
      }
      try {
        setLoading(true);
        const { data } = await api.login(auth).catch(err => console.log(err));
        localStorage.clear();
        if (data.token) {
          localStorage.setItem('token', data.token);
          localStorage.setItem('account', JSON.stringify(data.account));
          localStorage.setItem('tmstartupsadmin', 'true');
          history.push('/admin/startups');
        } else {
          setInvalidAuth(true);
          setLoading(false);
          setDisabled(false);
        }
      } catch (err) {
        setInvalidAuth(true);
        setLoading(false);
        setDisabled(false);
      }
    }
  };

  //redirect
  useEffect(() => {
    setLoading(false);
    if (localStorage.getItem('token')) history.push('/admin/startups');
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <title>Admin login | Timisoara Startups</title>
      </Helmet>
      <Loading isLoading={loading} />
      <GridWrapper>
        <Grid>
          <Cell lg={{ size: 4 }} md={{ colSpan: 4 }}>
            <Spacer lgheight={'1rem'} />
            <Link to="/">
              <img src={images.logoAdmin} alt="logo" />
            </Link>
            <Spacer lgheight={'8rem'} />
          </Cell>
        </Grid>
      </GridWrapper>
      <GridWrapper>
        <Grid>
          <Cell lg={{ colSpan: 4, offset: 5 }} md={{ colSpan: 6, offset: 4 }}>
            <img src={images.graphicAdmin} alt="admin-logo" style={{ maxWidth: '100%' }} />
          </Cell>
          <Cell lg={{ colSpan: 4, offset: 5 }} md={{ colSpan: 6, offset: 4 }}>
            <Spacer lgheight={'2.8rem'} />
            <H4 align="center">Login to Website Administration</H4>
            <Spacer lgheight={'4.8rem'} />
            <Input
              type="email"
              value={auth.email}
              placeholder="Your email address"
              label="Email address"
              name="email"
              handleOnChange={handleInputValueChange}
              error={errors.email}
              disabled={disabled}
            />
            <Spacer lgheight={'2.4rem'} />
            <Input
              type="password"
              value={auth.password}
              placeholder="Password"
              label="Password"
              name="password"
              handleOnChange={handleInputValueChange}
              error={errors.password}
              disabled={disabled}
            />
            <Spacer lgheight={'2.4rem'} />
            <BtnLogin onClick={handleSubmit} disabled={disabled}>
              Login
            </BtnLogin>
            {invalidAuth && (
              <>
                <Spacer lgheight={'2.4rem'} />
                <ParagraphBody color={colors.error}>Invalid email or password</ParagraphBody>
              </>
            )}
          </Cell>
        </Grid>
      </GridWrapper>
    </>
  );
};
