import React from 'react';
import { Spacer } from '../UI';
import {
  Card,
  ImgContainer,
  CardImg,
  CardContainer,
  TitleCard,
  Subtitle,
  TextCard,
} from './CardStartup.style';

export const CardStartup = ({ link, image, title, funding, industry }) => {
  return (
    <a href={link}>
      <Card>
        <ImgContainer>
          <CardImg src={image} alt="avatar" />
        </ImgContainer>
        <CardContainer>
          <Spacer lgheight={'1.6rem'} />
          <TitleCard>{title}</TitleCard>
          <Spacer lgheight={'0.8rem'} />
          <Subtitle>{funding}</Subtitle>
          <Spacer lgheight={'2.4rem'} />
          <TextCard>{industry}</TextCard>
        </CardContainer>
      </Card>
    </a>
  );
};
