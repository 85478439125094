import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Spacer, GridWrapper, Grid, Cell } from '../../UI';
import {
  Background,
  BtnConnect,
  H1Banner,
  ParagraphBodyBanner,
} from './RecommendAStakeholder.style';

export const RecommendAStakeholder = () => {
  //SCROLL FUNCTION
  const handleScroll = () => {
    const element = document.querySelector('#section-bg');
    if (element) {
      const top = element.getBoundingClientRect().top;
      const bottom = element.getBoundingClientRect().bottom;
      const width = window.innerWidth || document.documentElement.cl;
      const height = window.innerHeight || document.documentElement.clientHeight;
      if (bottom > 0 && top <= height) {
        let newPos = 0;
        if (width > 1920) newPos = `0px ${-500 + (top - height) * 0.15}px`;
        else if (width > 720) newPos = `0px ${-200 + (top - height) * 0.15}px`;
        else newPos = `0px ${-150 - (top - height) * 0.15}px`;
        element.style.backgroundPosition = newPos;
      }
    }
  };

  //ADD LISTENER
  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <Background id="section-bg">
      <GridWrapper>
        <Grid>
          <Cell lg={{ colSpan: 8 }} md={{ colSpan: 12 }}>
            <Spacer lgheight={'9.6rem'} />
            <H1Banner align={'left'}>
              Didn't find the community member you were looking for?
            </H1Banner>
            <Spacer lgheight={'2.4rem'} />
            <ParagraphBodyBanner>
              Let us know about it and we will add it to the list.
            </ParagraphBodyBanner>
          </Cell>
          <Cell md={{ colSpan: 12 }}>
            <Spacer lgheight={'4.8rem'} mdheight={'1.6rem'} />
            <Link to="/recommend-stakeholder/">
              <BtnConnect>RECOMMEND COMMUNITY MEMBER</BtnConnect>
            </Link>
            <Spacer lgheight={'9.6rem'} />
          </Cell>
        </Grid>
      </GridWrapper>
    </Background>
  );
};
