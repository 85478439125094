import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { api } from '../../api';
import { Loading, Spacer } from '../../components/UI';
import { FilterStakeholders, LayoutAdmin, NoResults } from '../../components';
import { AdminStakeholders } from '../../components/sections';

export const AdminStakeholdersPage = () => {
  const [stakeholders, setStakeholders] = useState([]);
  const [filter, setFilter] = useState({
    search: '',
    position: 'All',
  });
  const [filteredStakeholders, setFilteredStakeholders] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const stakeholdersList = await api.getAllStakeholders();
    if (stakeholdersList) {
      setStakeholders(stakeholdersList);
      setLoading(false);
    }
  };

  //APPLY FILTER
  useEffect(() => {
    let filteredList = stakeholders;
    if (filter.search !== '')
      filteredList = filteredList.filter(item =>
        (item.firstName + ' ' + item.lastName).toLowerCase().includes(filter.search.toLowerCase())
      );
    if (filter.position !== 'All')
      filteredList = filteredList.filter(item =>
        item.role
          .reduce((prev, curr) => prev + curr)
          .toLowerCase()
          .includes(filter.position.toLowerCase())
      );
    setFilteredStakeholders(filteredList);
    // eslint-disable-next-line
  }, [filter, stakeholders]);

  //FETCH DATA
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <LayoutAdmin>
      <Helmet>
        <title>Stakeholders | Timisoara Startups</title>
      </Helmet>
      <Loading isLoading={loading} />
      {!loading && (
        <>
          <Spacer lgheight={'9.6rem'} />
          <FilterStakeholders onFilterChange={setFilter} />
          <Spacer lgheight={'2.8rem'} />
          {filteredStakeholders.length === 0 ? (
            <NoResults />
          ) : (
            <AdminStakeholders stakeholdersList={filteredStakeholders} refresh={fetchData} />
          )}
          <Spacer lgheight={'9.6rem'} />
        </>
      )}
    </LayoutAdmin>
  );
};
