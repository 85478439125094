import styled from 'styled-components';
import { colors, H3, ParagraphBody } from '../UI';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const ImgContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 18.8rem;
`;

export const Image = styled.img`
  width: 18rem;
  height: 100%;
  border-radius: 50%;
  display: block;
  object-fit: cover;
  object-position: center;
`;

export const Title = styled(H3)`
  color: ${colors.textmain};
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const Subtitle = styled(ParagraphBody)`
  font-family: 'Raleway', sans-serif;
  color: ${colors.textcaption};
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2rem;
`;
