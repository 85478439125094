import React, { useState } from "react";
import { H3, Grid, Cell, Spacer, BtnPrimary, GridWrapper } from "../../UI";
import {
  BtnStartups,
  DisplayFlex,
  Icon,
  SubtitleParagraph,
} from "./Stakeholders.style";
import { Link } from "react-router-dom";
import { CardStakeholder } from "../..";
import { images } from "../../../public";

export const Stakeholders = ({ isHomepage, stakeholdersList }) => {
  const [visibleStartups, setVisibleStartups] = useState(9);

  const handleShowMoreStartups = () => {
    setVisibleStartups(visibleStartups + 9);
  };

  return (
    <GridWrapper>
      <Grid>
        {isHomepage && (
          <>
            <Cell md={{ colSpan: 12 }}>
              <Spacer lgheight={"14.4rem"} />
              <DisplayFlex>
                <Icon src={images.newPeople} alt="people-icon" />
                <div>
                  <H3 align={"left"}>People</H3>
                  <Spacer lgheight={"0.8rem"} />
                  <SubtitleParagraph>
                    Entrepreneurs, freelancers, crazy passionate people. Your
                    future co-founder or that consultant who truly understands.
                    People to share ideas with.
                  </SubtitleParagraph>
                </div>
              </DisplayFlex>
              <Spacer lgheight={"2.8rem"} />
            </Cell>
            <Cell md={{ colSpan: 12 }}>
              <Link to="/recommend-stakeholder">
                <BtnPrimary>RECOMMEND COMMUNITY MEMBER</BtnPrimary>
              </Link>
              <Spacer lgheight={"4.8rem"} mdheight={"4.8rem"} smheight={"0"} />
            </Cell>
          </>
        )}
        {stakeholdersList &&
          stakeholdersList.slice(0, visibleStartups).map((user, index) => (
            <Cell key={index}>
              <CardStakeholder
                name={user.firstName + " " + user.lastName}
                link={`/community/${user.url}`}
                image={user.avatar}
                role={user.role}
                linkedin={user.linkedin}
                facebook={user.facebook}
                instagram={user.instagram}
              />
            </Cell>
          ))}
        <Cell />
        {isHomepage ? (
          <Cell lg={{ colSpan: 4, offset: 5 }} md={{ colSpan: 6, offset: 4 }}>
            <Spacer lgheight={"0.8rem"} />
            <Link to="/community">
              <BtnStartups>Meet the people</BtnStartups>
            </Link>
            <Spacer lgheight={"13.8rem"} />
          </Cell>
        ) : (
          stakeholdersList.length > visibleStartups && (
            <Cell lg={{ colSpan: 4, offset: 5 }} md={{ colSpan: 6, offset: 4 }}>
              <Spacer lgheight={"0.8rem"} />
              <BtnStartups onClick={handleShowMoreStartups}>
                <span>View more</span>
              </BtnStartups>
              <Spacer lgheight={"9.6rem"} />
            </Cell>
          )
        )}
      </Grid>
    </GridWrapper>
  );
};
