import React, { useState, useEffect } from 'react';
import { colors, Spacer, Spinner } from '../UI';
import {
  Card,
  ImgContainer,
  CardImg,
  CardContainer,
  TitleCard,
  Subtitle,
  SmLogoDisplay,
  SmLogo,
  Menu,
  MenuItem,
  Tag,
} from './CardStakeholder.style';
import { images } from '../../public';

export const AdminCardStakeholder = ({
  image,
  name,
  role,
  linkedin,
  facebook,
  instagram,
  published,
  onPublish,
  onEdit,
  onDelete,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [image, name, role, linkedin, facebook, instagram, published, onPublish, onEdit, onDelete]);

  return (
    <Card>
      <Menu>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {published ? (
              <MenuItem
                color={colors.white}
                onClick={() => {
                  setLoading(true);
                  onPublish();
                }}
              >
                UNPUBLISH
              </MenuItem>
            ) : (
              <MenuItem
                color={colors.success}
                onClick={() => {
                  setLoading(true);
                  onPublish();
                }}
              >
                PUBLISH
              </MenuItem>
            )}
            <MenuItem color={colors.error} onClick={onEdit}>
              EDIT
            </MenuItem>
            <MenuItem color={colors.white} onClick={onDelete}>
              DELETE
            </MenuItem>
          </>
        )}
      </Menu>
      <Tag color={published ? colors.success : colors.error}>
        {published ? 'PUBLISHED' : 'PENDING'}
      </Tag>
      <ImgContainer>
        <CardImg src={image} alt="stakeholder avatar" />
      </ImgContainer>
      <CardContainer>
        <Spacer lgheight={'2.4rem'} />
        <TitleCard>{name}</TitleCard>
        <Spacer lgheight={'0.8rem'} />
        <Subtitle>
          {role.map(role => (
            <span key={role} style={{ display: 'block' }}>
              {role}
            </span>
          ))}
        </Subtitle>
        <Spacer lgheight={'2.4rem'} />
        <SmLogoDisplay>
          {linkedin && <SmLogo src={images.linkedin} alt="linkedin logo" />}
          {facebook && <SmLogo src={images.facebook} alt="facebook logo" />}
          {instagram && <SmLogo src={images.instagram} alt="instagram logo" />}
        </SmLogoDisplay>
      </CardContainer>
    </Card>
  );
};
